import axiosInstance from '@/services/axios'
import type { User } from '@/types/user'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const user = ref<User | null>(null)
  const loading = ref(false)
  const error = ref<string | null>(null)

  const isActive = computed(() => user.value?.user_status === 'FREE')

  async function createUser(
    affiliateId: string,
    parentAffiliateId: string,
    address: string,
    recaptchaResponse: string
  ) {
    user.value = null
    loading.value = true
    error.value = null

    await axiosInstance
      .post(
        '/user_insert',
        {
          user_affiliate_id: affiliateId.trim().toLowerCase(),
          user_parent_affiliate_id: parentAffiliateId.trim().toLowerCase(),
          user_address: address,
          recaptcha_response: recaptchaResponse
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        user.value = response.data.user[0]
        user.value!.user_transactions = []
        user.value!.user_balance = {
          user_id: user.value!.user_id,
          amount_available: '0.00',
          amount_withdrawn: '0.00',
          amount_total: '0.00'
        }
      })
      .catch((err) => {
        error.value = err.response.data.translation_message
        // console.error(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  async function findUser(address: string) {
    loading.value = true
    error.value = null

    await axiosInstance
      .get(`/user_select/${address}`)
      .then((response) => {
        user.value = response.data.user[0]
        user.value!.user_transactions = response.data.transaction ?? []
        user.value!.user_balance = response.data.balance[0]
      })
      .catch((err) => {
        // error.value = err.response.data.translation_message
        user.value = null
        console.error(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  function clearState() {
    user.value = null
    loading.value = false
    error.value = null
  }

  return { user, loading, error, createUser, findUser, clearState }
})
