<script setup>
import { useCountdown } from '@/composables/countdown'
import { leftPad } from '@/utils/helpers'
import { computed } from 'vue'

const props = defineProps(['isLoading', 'endTime'])

const end = computed(() => {
  return Number(props.endTime)
})

const { days, hours, minutes, seconds } = useCountdown(end)
</script>

<template>
  <div class="d-flex w-100 align-items-center justify-content-center gap-2 my-2 px-3">
    <div
      v-if="days > 0"
      class="d-flex flex-column align-items-center justify-content-center px-3 py-1"
      :class="props.isLoading ? 'shimmer' : ''"
    >
      <div class="font-26 lh-1">{{ leftPad(days) }}</div>
      <div class="font-14">{{ $t('general.days', days) }}</div>
    </div>
    <div
      class="d-flex flex-column align-items-center justify-content-center px-3 py-1"
      :class="props.isLoading ? 'shimmer' : ''"
    >
      <div class="font-26 lh-1">{{ leftPad(hours) }}</div>
      <div class="font-14">{{ $t('general.hours', hours) }}</div>
    </div>
    <div
      class="d-flex flex-column align-items-center justify-content-center px-3 py-1"
      :class="props.isLoading ? 'shimmer' : ''"
    >
      <div class="font-26 lh-1">{{ leftPad(minutes) }}</div>
      <div class="font-14">{{ $t('general.minutes', minutes) }}</div>
    </div>
    <div
      class="d-flex flex-column align-items-center justify-content-center px-3 py-1"
      :class="props.isLoading ? 'shimmer' : ''"
    >
      <div class="font-26 lh-1">{{ leftPad(seconds) }}</div>
      <div class="font-14">{{ $t('general.seconds', seconds) }}</div>
    </div>
  </div>
</template>
