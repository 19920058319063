import {
  createPublicClient,
  http,
  type Chain,
  type ContractFunctionParameters,
  type Transport
} from 'viem'

var rpcBlacklist = new Map()

export async function runMulticall(
  chain: Chain,
  transports: ReturnType<Transport>[],
  contracts: ContractFunctionParameters[]
): Promise<{ chainId: number; result: unknown[] }> {
  for (var i = 0; i < transports.length; i++) {
    try {
      if (rpcBlacklist.has(transports[i].value?.url)) {
        if (rpcBlacklist.get(transports[i].value?.url) + 60 * 5 * 1000 > Date.now()) {
          continue
        }

        rpcBlacklist.delete(transports[i].value?.url)
      }

      const client = createPublicClient({
        chain,
        transport: http(transports[i].value?.url)
      })

      const result = await client.multicall({
        allowFailure: false,
        contracts
      })

      return { chainId: chain.id, result }
    } catch (error: any) {
      rpcBlacklist.set(transports[i].value?.url, Date.now())

      if (i === transports.length - 1) {
        throw new Error(`Error occurred on chainId ${chain.id}:`, error)
      }
    }
  }

  return { chainId: chain.id, result: [] }
}
