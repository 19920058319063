<script setup>
import Title from '../../components/base/Title.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
    <main>
        <section class="hero-3">
            <div class="hero" style="position: absolute !important;width: 100%;top: 0;left: 0;z-index: 0;">

            </div>
            <!-- <div class="grid-overlay"></div> -->
            <div class="d-flex" style="flex-direction: column;justify-content: center; min-height: 50vh;"
                data-aos="fade-up">
                <div class="container">
                    <div class="row  align-items-center justify-content-center text-center">
                        <div class="col-md-10 ">
                            <h1 class="display-2 text-white fw-bold my-4">
                                <Title>
                                    <span class="text-white me-2">{{ $t("userAgreement.title") }}</span>
                                    <br />
                                    <template #after>{{ $t("userAgreement.afterTitle") }}</template>
                                </Title>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container position-relative">
                <div class="fs-4 fw-bold text-white " data-aos="fade-up">
                    {{ $t("userAgreement.cookiePolicy") }}
                </div>

                <div class="text-white mt-2" data-aos="fade-up">
                    <p>
                        {{ $t("userAgreement.effectiveDate") }}: 18/10/2024
                    </p>
                    <p>
                        {{ $t("userAgreement.welcomeMessage") }}
                    </p>
                    <p>
                        {{ $t("userAgreement.agreementMessage") }}
                    </p>

                    <ol>
                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.acceptanceOfTerms") }}</p>
                            <p>{{ $t("userAgreement.acceptanceDescription") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.eligibility") }}</p>
                            <p>{{ $t("userAgreement.eligibilityRequirements") }}</p>
                            <ul>
                                <li>{{ $t("userAgreement.eligibilityAge") }}</li>
                                <li>{{ $t("userAgreement.eligibilityLegalCapacity") }}</li>
                                <li>{{ $t("userAgreement.eligibilityCompliance") }}</li>
                            </ul>
                            <p>{{ $t("userAgreement.mintweb3Right") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.accountRegistration") }}</p>
                            <p>{{ $t("userAgreement.accountRegistrationDescription") }}</p>
                            <ul>
                                <li>{{ $t("userAgreement.accountRegistrationAccurateInfo") }}</li>
                                <li>{{ $t("userAgreement.accountRegistrationSecurePassword") }}</li>
                                <li>{{ $t("userAgreement.accountRegistrationUpdateDetails") }}</li>
                            </ul>
                            <p>{{ $t("userAgreement.accountResponsibility") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.servicesProvided") }}</p>
                            <p>{{ $t("userAgreement.servicesDescription") }}</p>
                            <ul>
                                <li>{{ $t("userAgreement.service1") }}</li>
                                <li>{{ $t("userAgreement.service2") }}</li>
                                <li>{{ $t("userAgreement.service3") }}</li>
                                <li>{{ $t("userAgreement.service4") }}</li>
                                <li>{{ $t("userAgreement.service5") }}</li>
                            </ul>
                            <p>{{ $t("userAgreement.servicesModification") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.userResponsibilities") }}</p>
                            <p>{{ $t("userAgreement.userResponsibilitiesDescription") }}</p>
                            <ul>
                                <li>{{ $t("userAgreement.userResponsibility1") }}</li>
                                <li>{{ $t("userAgreement.userResponsibility2") }}</li>
                                <li>{{ $t("userAgreement.userResponsibility3") }}</li>
                                <li>{{ $t("userAgreement.userResponsibility4") }}</li>
                            </ul>
                            <p>{{ $t("userAgreement.userProhibitedActions") }}</p>
                            <ul>
                                <li>{{ $t("userAgreement.userProhibitedAction1") }}</li>
                                <li>{{ $t("userAgreement.userProhibitedAction2") }}</li>
                                <li>{{ $t("userAgreement.userProhibitedAction3") }}</li>
                            </ul>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.intellectualProperty") }}</p>
                            <p>{{ $t("userAgreement.intellectualPropertyDescription") }}</p>
                            <p>{{ $t("userAgreement.intellectualPropertyRights") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.paymentTerms") }}</p>
                            <p>{{ $t("userAgreement.paymentDescription") }}</p>
                            <p>{{ $t("userAgreement.paymentAgreement") }}</p>
                            <ul>
                                <li>{{ $t("userAgreement.paymentResponsibility1") }}</li>
                                <li>{{ $t("userAgreement.paymentResponsibility2") }}</li>
                                <li>{{ $t("userAgreement.paymentResponsibility3") }}</li>
                            </ul>
                            <p>{{ $t("userAgreement.paymentRefundPolicy") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.securityAndPrivacy") }}</p>
                            <p>{{ $t("userAgreement.securityDescription") }}</p>
                            <p>{{ $t("userAgreement.securityRiskAcknowledgment") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.limitationOfLiability") }}</p>
                            <p>{{ $t("userAgreement.limitationDescription") }}</p>
                            <ul>
                                <li>{{ $t("userAgreement.limitationLiability1") }}</li>
                                <li>{{ $t("userAgreement.limitationLiability2") }}</li>
                                <li>{{ $t("userAgreement.limitationLiability3") }}</li>
                            </ul>
                            <p>{{ $t("userAgreement.indemnityClause") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.thirdPartyLinks") }}</p>
                            <p>{{ $t("userAgreement.thirdPartyLinksDescription") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.termination") }}</p>
                            <p>{{ $t("userAgreement.terminationDescription") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.disputeResolution") }}</p>
                            <p>{{ $t("userAgreement.disputeResolutionDescription") }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t("userAgreement.modifications") }}</p>
                            <p>{{ $t("userAgreement.modificationsDescription") }}</p>
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    </main>
</template>


<style scoped>
.border-bottom {
    border-bottom: 1px solid white;
    opacity: 0.16;
}
</style>