import { hasClaimStarted } from '@/utils/helpers'
import { fallback, http } from 'viem'
import {
  arbitrum,
  arbitrumSepolia,
  avalanche,
  avalancheFuji,
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy,
  sepolia
} from 'viem/chains'

const isImported = import.meta.env.VITE_IS_IMPORTED === 'true'

export const transportsTest =
  hasClaimStarted() || isImported
    ? {
        [bscTestnet.id]: fallback([
          http('https://bsc-testnet-rpc.publicnode.com'),
          http('https://data-seed-prebsc-1-s1.bnbchain.org:8545'),
          http('https://endpoints.omniatech.io/v1/bsc/testnet/public'),
          http('https://rpc.ankr.com/bsc_testnet_chapel'),
          http('https://bsc-testnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ])
        // [arbitrumSepolia.id]: fallback([
        //   http('https://sepolia-rollup.arbitrum.io/rpc'),
        //   http('https://rpc.ankr.com/arbitrum_sepolia'),
        //   http('https://endpoints.omniatech.io/v1/arbitrum/sepolia/public'),
        //   http(
        //     'https://arbitrum-sepolia.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8'
        //   ),
        // ])
      }
    : {
        [bscTestnet.id]: fallback([
          http('https://bsc-testnet-rpc.publicnode.com'),
          http('https://data-seed-prebsc-1-s1.bnbchain.org:8545'),
          http('https://endpoints.omniatech.io/v1/bsc/testnet/public'),
          http('https://rpc.ankr.com/bsc_testnet_chapel'),
          http('https://bsc-testnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        // [polygonAmoy.id]: fallback([
        //   http('https://rpc-amoy.polygon.technology'),
        //   http('https://polygon-amoy.drpc.org'),
        //   http('https://rpc.ankr.com/polygon_amoy'),
        //   http('https://polygon-amoy.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8'),
        //   http('https://polygon-amoy-bor-rpc.publicnode.com')
        // ]),
        [arbitrumSepolia.id]: fallback([
          http('https://sepolia-rollup.arbitrum.io/rpc'),
          http('https://rpc.ankr.com/arbitrum_sepolia'),
          http('https://endpoints.omniatech.io/v1/arbitrum/sepolia/public'),
          http('https://arbitrum-sepolia.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        [sepolia.id]: fallback([
          http('https://ethereum-sepolia-rpc.publicnode.com'),
          http('https://ethereum-sepolia.rpc.subquery.network/public'),
          http('https://rpc.ankr.com/eth_sepolia'),
          http('https://endpoints.omniatech.io/v1/eth/sepolia/public'),
          http('https://eth-sepolia.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        [optimismSepolia.id]: fallback([
          http('https://sepolia.optimism.io'),
          http('https://endpoints.omniatech.io/v1/op/sepolia/public'),
          http('https://rpc.ankr.com/optimism_sepolia'),
          http('https://optimism-sepolia.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        [baseSepolia.id]: fallback([
          http('https://sepolia.base.org'),
          http('https://base-sepolia-rpc.publicnode.com'),
          http('https://rpc.ankr.com/base_sepolia'),
          http('https://base-sepolia.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        // [avalancheFuji.id]: fallback([
        //   http('https://avalanche-fuji-c-chain-rpc.publicnode.com'),
        //   http('https://rpc.ankr.com/avalanche_fuji-c'),
        //   http('https://endpoints.omniatech.io/v1/avax/fuji/public'),
        //   http('https://ava-testnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8/ext/bc/C/rpc')
        // ])
      }

export const transportsMain =
  hasClaimStarted() || isImported
    ? {
        [bsc.id]: fallback([
          http('https://binance.llamarpc.com'),
          http('https://bsc-rpc.publicnode.com'),
          http('https://bscrpc.com'),
          http('https://rpc.ankr.com/bsc'),
          http('https://bsc-mainnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ])
      }
    : {
        [bsc.id]: fallback([
          http('https://binance.llamarpc.com'),
          http('https://bsc-rpc.publicnode.com'),
          http('https://bscrpc.com'),
          http('https://rpc.ankr.com/bsc'),
          http('https://bsc-mainnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        [mainnet.id]: fallback([
          http('https://eth.llamarpc.com'),
          http('https://eth.drpc.org'),
          http('https://ethereum-rpc.publicnode.com'),
          http('https://rpc.ankr.com/eth'),
          http('https://eth-mainnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        [polygon.id]: fallback([
          http('https://polygon.llamarpc.com'),
          http('https://polygon.drpc.org'),
          http('https://polygon-bor-rpc.publicnode.com'),
          http('https://rpc.ankr.com/polygon'),
          http('https://polygon-mainnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        [arbitrum.id]: fallback([
          http('https://endpoints.omniatech.io/v1/arbitrum/one/public'),
          http('https://arbitrum.llamarpc.com'),
          http('https://arbitrum.drpc.org'),
          http('https://arbitrum-one-rpc.publicnode.com'),
          http('https://rpc.ankr.com/arbitrum'),
          http('https://arbitrum-one.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        [optimism.id]: fallback([
          http('https://optimism.llamarpc.com'),
          http('https://optimism-rpc.publicnode.com'),
          http('https://optimism.drpc.org'),
          http('https://rpc.ankr.com/optimism'),
          http('https://optimism-mainnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad')
        ]),
        [base.id]: fallback([
          http('https://base.llamarpc.com'),
          http('https://base.drpc.org'),
          http('https://base-rpc.publicnode.com'),
          http('https://mainnet.base.org'),
          http('https://base-mainnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8')
        ]),
        [avalanche.id]: fallback([
          http('https://endpoints.omniatech.io/v1/avax/mainnet/public'),
          http('https://avalanche.drpc.org'),
          http('https://avalanche-c-chain-rpc.publicnode.com'),
          http('https://avax.meowrpc.com'),
          http('https://rpc.ankr.com/avalanche'),
          http('https://ava-mainnet.blastapi.io/d5119f0f-9dc9-4828-8fab-a03892a9aad8/ext/bc/C/rpc')
        ])
      }
