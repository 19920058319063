import { useUserStore } from '@/stores/user'
import { computed } from 'vue'
import { ref } from 'vue'

export function usePagination() {
  const userStore = useUserStore()
  let page = ref(1)

  const perPage = Number(import.meta.env.VITE_PAGINATION_PER_PAGE)
  const maxVisibleButtons = Number(import.meta.env.VITE_PAGINATION_MAX_VISIBLE_BUTTONS)

  const paginatedData = computed(() =>
    userStore.user.user_transactions.slice((page.value - 1) * perPage, page.value * perPage)
  )

  const totalPages = computed(() => Math.ceil(userStore.user.user_transactions.length / perPage))

  const startPage = computed(() => {
    if (page.value - Math.floor(maxVisibleButtons / 2) <= 1) {
      return 1
    }

    if (page.value + Math.ceil(maxVisibleButtons / 2) > totalPages.value) {
      return totalPages.value - maxVisibleButtons + 1
    }

    return page.value - Math.floor(maxVisibleButtons / 2)
  })

  const endPage = computed(() => {
    return Math.min(startPage.value + maxVisibleButtons - 1, totalPages.value)
  })

  const pages = computed(() => {
    const range = []

    for (let i = startPage.value; i <= endPage.value; i++) {
      range.push(i)
    }

    return range
  })

  const nextPage = () => {
    if (page.value !== totalPages.value) {
      page.value++
    }
  }

  const backPage = () => {
    if (page.value !== 1) {
      page.value--
    }
  }

  const goToPage = (numPage) => {
    page.value = numPage
  }

  return { paginatedData, perPage, page, pages, totalPages, nextPage, backPage, goToPage }
}
