// import { useAppKit } from '@reown/appkit/vue'
import { useWeb3Modal } from '@web3modal/wagmi/vue'

export function cutAddress(address, beginLength, endLength) {
  return address.length > beginLength && address.length > endLength
    ? address.slice(0, beginLength) +
        '...' +
        address.slice(address.length - endLength, address.length)
    : address
}

export function formatCurrencyUSD(amount, minDecimals, maxDecimals) {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  })

  return formatter.format(amount)
}

export function formatNumber(amount, minDecimals, maxDecimals) {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  })

  return formatter.format(Number(amount))
}

export function timestampToDate(timestamp) {
  const date = new Date(Number(timestamp) * 1000)
  return date.toLocaleDateString()
}

export function parseNumber(value) {
  return parseFloat(value.toString().replace(/,/g, '')) || 0
}

export function leftPad(value, length = 2, fillString = '0') {
  return value.toString().padStart(length, fillString)
}

export function handleKeyDown(event, allowDecimals) {
  if (
    // Allow: backspace, delete, tab, escape, enter, etc.
    ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter'].includes(event.key) ||
    // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X, etc.
    (event.ctrlKey && ['a', 'c', 'v', 'x'].includes(event.key.toLowerCase())) ||
    // Allow: home, end, left, right, etc.
    ['Home', 'End', 'ArrowLeft', 'ArrowRight'].includes(event.key)
  ) {
    return
  }

  // Allow one dot (.) but not at the start
  if (event.key === '.' && allowDecimals) {
    if (event.target.value === '' || event.target.value.includes('.')) {
      event.preventDefault()
    }
    return
  }

  // Ensure that it is a number and stop the keypress if not
  if (!/^[0-9]$/.test(event.key)) {
    event.preventDefault()
  }
}

export function copyToClipboard(text) {
  const clipboardData = window.clipboardData || navigator.clipboard

  clipboardData.writeText(text)
}

export function getChainSymbol(chainId) {
  switch (chainId) {
    case 56:
    case 97:
      return 'BNB'
    case 1:
    case 11_155_111:
      return 'ETH'
    case 42_161:
    case 421_614:
      return 'ARB'
    case 10:
    case 11155420:
      return 'OP'
    case 8453:
    case 84532:
      return 'BASE'
    case 137:
    case 80_002:
      return 'POL'
    case 43_114:
    case 43_113:
      return 'AVAX'
  }
}

export async function openModal() {
  // await useAppKit().open()
  await useWeb3Modal().open()
}

export function hasClaimStarted() {
  return Number(import.meta.env.VITE_CLAIM_START_TIME) <= new Date().getTime() / 1000
}

export function getLanguageNameFromLocale(locale) {
  switch (locale) {
    case 'en':
      return 'english'
    case 'es':
      return 'spanish'
    case 'pt':
      return 'portuguese_br'
    case 'fr':
      return 'french'
    case 'de':
      return 'german'
    default:
      return 'english'
  }
}
