<script setup>
import { advertisersTechnology, advertisersCrypto, marketingServices, coinsMobile } from '../assets/data/index';
import AdvertisersMarquee from '../components/AdvertisersMarquee.vue';
import DetailsCard from '../components/base/DetailsCard.vue';
import MarketingCard from '../components/base/MarketingCard.vue';
import OutlinedBtn from '../components/base/OutlinedBtn.vue';
import SecondaryBtn from '../components/base/SecondaryBtn.vue';
import Title from '../components/base/Title.vue';
import CoinsMarquee from '../components/CoinsMarquee.vue';
import { Vue3Lottie } from 'vue3-lottie';
import Hero2 from '@/assets/hero-2.json';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
    <main>
        <section>
            <div class="radial-background-left-mobile"></div>
            <div class="radial-background-right-mobile"></div>
            <CoinsMarquee class="image-mobile" :data="coinsMobile" :direction="'reverse'" />

            <div class="hero-2">
                <vue3-lottie class="lottie-image" :animation-data="Hero2" :loop="true" :autoplay="true"
                    renderer="canvas" :rendererSettings="{
                        preserveAspectRatio: 'none',
                        clearCanvas: true,
                        progressiveLoad: true,
                        hideOnTransparent: true,
                        imageSmoothing: false,
                        dpr: 1,
                    }" />
                <div class="hero-2-content ">
                    <div class="min-vh-100 d-flex" style="flex-direction: column;justify-content: center; gap: 70px;"
                        data-aos="fade-up">
                        <div class="container">
                            <div class="row  align-items-center justify-content-center text-center">
                                <div class="col-md-10">
                                    <OutlinedBtn>
                                        <template #title>

                                            <div class="d-flex align-items-center gap-1">
                                                {{ $t('general.headerBtn01') }}
                                                <img src="/images/right-arrow.svg" alt="Right arrow" width="16"
                                                    height="16" />
                                            </div>
                                        </template>
                                    </OutlinedBtn>
                                    <h1 class="display-2 text-white fw-bold my-4">
                                        <Title>
                                            <span class="text-white">{{ $t('general.web30') }}</span>
                                            <br />
                                            <template #after>{{ $t('viewMarketing.headerTitle') }}</template>
                                        </Title>
                                    </h1>
                                    <p class="text-secondary-light mx-auto" style="max-width: 70%;">{{
                                        $t('viewMarketing.headerDescription') }}
                                    </p>
                                    <div class="mt-4">
                                        <SecondaryBtn :title="$t('general.headerBtn02')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CoinsMarquee class="image-mobile" :data="coinsMobile" />

        </section>

        <AdvertisersMarquee :dark="true" :borders="true" :data="advertisersTechnology" id="next-section"/>

        <!-- ============================== -->
        <!-- WEB 3 MARKETING                -->
        <!-- ============================== -->

        <div>
            <DetailsCard :gradient="true">
                <template #header>
                    <h1 class="display-5 fw-bold ">
                        <Title>
                            {{ $t('viewMarketing.web3MarketingHeader01') }}<br /><template #after>{{
                                $t('viewMarketing.web3MarketingHeader02') }}</template>
                        </Title>
                    </h1>
                </template>

                <template #body>
                    <div class="fs-6 text-secondary-dark mt-4">
                        <p>
                            {{ $t('viewMarketing.web3MarketingDescription01') }}
                        </p>
                        <p>
                            {{ $t('viewMarketing.web3MarketingDescription02') }}
                        </p>
                    </div>
                </template>

                <template #image>
                    <img style="width: 100%;" src="/images/create-community.webp" alt="Marketing" />
                </template>
            </DetailsCard>

        </div>

        <!-- ============================== -->
        <!-- MARKETING SERVICES             -->
        <!-- ============================== -->

        <div class="py-container">
            <h1 class="display-5 fw-bold text-center text-white">
                <Title>
                    <template #before>{{ $t('viewMarketing.web3ServicesHeader01') }}</template> <template #afterText>
                        {{ $t('viewMarketing.web3ServicesHeader02') }}</template>
                </Title>
            </h1>
        </div>

        <div class="position-relative">
            <!-- gradiant bgs start-->
            <div class="center-gradient"></div>
            <div class="left-1-gradient"></div>
            <div class="left-2-gradient"></div>
            <div class="right-1-gradient"></div>
            <div class="right-2-gradient"></div>
            <!-- gradiant bgs-end -->
            <div class="container py-4">
                <div v-for="(item, idx) in marketingServices(t).slice(0, 3)" :key="idx" class="my-5">
                    <MarketingCard :dark="true" :item="item" />
                </div>
            </div>
        </div>


        <div class="bg-white">
            <div class="container py-4">
                <div v-for="(item, idx) in marketingServices(t).slice(3, 6)" :key="idx" class="my-5">
                    <MarketingCard :item="item" />
                </div>
            </div>
        </div>

        <div class="position-relative">
            <!-- gradiant bgs start-->
            <div class="center-gradient"></div>
            <div class="left-1-gradient"></div>
            <div class="left-2-gradient"></div>
            <div class="right-1-gradient"></div>
            <div class="right-2-gradient"></div>
            <!-- gradiant bgs-end -->
            <div class="container py-4">
                <div v-for="(item, idx) in marketingServices(t).slice(6, 9)" :key="idx" class="my-5">
                    <MarketingCard :dark="true" :item="item" />
                </div>
            </div>
        </div>

        <div class="bg-white">
            <div class="container py-4">
                <div v-for="(item, idx) in marketingServices(t).slice(9, 12)" :key="idx" class="my-5">
                    <MarketingCard :item="item" />
                </div>
            </div>
        </div>

        <div>
            <AdvertisersMarquee :dark="true" :borders="true" :title="$t('general.multiNetwork')" :data="advertisersCrypto" />
        </div>

    </main>
</template>


<style scoped>
.center-gradient {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 20% at 50% 20%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.3;

    transform: translateY(-50%);
    z-index: -1;
    pointer-events: none;
}

.left-1-gradient {
    position: absolute;
    top: 80%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 13% at 0% 30%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.5;

    transform: translateY(-50%);
    z-index: -1;
    pointer-events: none;
}

.left-2-gradient {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 13% at 0% 30%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.5;

    transform: translateY(-35%);
    z-index: -1;
    pointer-events: none;
}

.right-1-gradient {
    position: absolute;
    top: 65%;
    right: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(40% 13% at 100% 30%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.5;

    transform: translateY(-50%);
    z-index: -1;
    pointer-events: none;
}

.right-2-gradient {
    position: absolute;
    top: 97%;
    right: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(40% 13% at 100% 30%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.5;

    transform: translateY(-50%);
    z-index: -1;
    pointer-events: none;
}
</style>