<script setup>
import { hasClaimStarted } from '@/utils/helpers'
import ComingSoonWidget from './components/ComingSoonWidget.vue';
import PresaleWidget from './components/PresaleWidget.vue';
import PresaleOverWidget from './components/PresaleOverWidget.vue';
import ClaimWidget from './components/ClaimWidget.vue';
import { useData } from '@/composables/data';

const { presaleEnded } = useData()

const hasPresaleStarted = Number(import.meta.env.VITE_PRESALE_START_TIME) <= new Date().getTime() / 1000
</script>

<template>
    <div class="row justify-content-center px-2">
        <ComingSoonWidget v-if="!hasPresaleStarted" />
        <PresaleWidget v-else-if="!presaleEnded" />
        <PresaleOverWidget v-else-if="!hasClaimStarted()" />
        <ClaimWidget v-else />
    </div>
</template>