import { createRouter, createWebHistory } from "vue-router";
import IndexView from "../views/IndexView.vue";
import PaymentsView from "../views/PaymentsView.vue";
import MarketingView from "../views/MarketingView.vue";
import ContactUsView from "../views/ContactUsView.vue";
import CommunityGuidelinesView from "../views/terms/CommunityGuidelinesView.vue";
import CookiePolicyView from "../views/terms/CookiePolicyView.vue";
import TermsOfServicesView from "../views/terms/TermsOfServicesView.vue";
import UserAgreementView from "../views/terms/UserAgreementView.vue";
import StakingView from "@/views/StakingView.vue";
import Cookies from "js-cookie";
import { i18n } from "@/plugins/i18n";
import AirdropView from "@/views/AirdropView.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: () => {
        const savedLocale = localStorage.getItem("lang");
        return savedLocale ? `/${savedLocale}` : "/en";
      },
    },
    {
      path: "/:lang",
      name: "home",
      component: IndexView,
      meta: { title: "Home" },
    },
    {
      path: "/:lang/presale",
      name: "presale",
      component: PaymentsView,
      meta: { title: "Presale" },
    },
    {
      path: "/:lang/staking",
      name: "staking",
      component: StakingView,
      meta: { title: "Staking" },
    },
    {
      path: "/:lang/airdrop",
      name: "airdrop",
      component: AirdropView,
      meta: { title: "Airdrop" },
    },
    {
      path: "/:lang/marketing",
      name: "marketing",
      component: MarketingView,
      meta: { title: "Marketing" },
    },
    {
      path: "/:lang/contact-us",
      name: "contact-us",
      component: ContactUsView,
      meta: { title: "Contact Us" },
    },
    {
      path: "/:lang/terms/user-agreement",
      name: "user-agreement",
      component: UserAgreementView,
      meta: { title: "User Agreement" },
    },
    {
      path: "/:lang/terms/terms-of-services",
      name: "terms-of-services",
      component: TermsOfServicesView,
      meta: { title: "Terms Of Services" },
    },
    {
      path: "/:lang/terms/cookie-policy",
      name: "cookie-policy",
      component: CookiePolicyView,
      meta: { title: "Cookie Policy" },
    },
    {
      path: "/:lang/terms/community-guidelines",
      name: "community-guidelines",
      component: CommunityGuidelinesView,
      meta: { title: "Community Guidelines" },
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: () => "/",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.name !== from.name) {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | MintWeb3`;
  } else {
    document.title = "MintWeb3";
  }

  //Get available locales
  const availableLocales = i18n.global.availableLocales;

  //Get locale from URL
  const { lang } = to.params;

  //Check if locale is available
  if (!lang || !availableLocales.includes(lang)) {
    i18n.global.locale.value = "en";
    return next("/en");
  }

  //Save locale in local storage
  localStorage.setItem("lang", lang);

  //Change locale
  i18n.global.locale.value = lang;

  // Check if the 'afl' query parameter exists
  const affiliate = to.query.afl;

  if (affiliate) {
    // Save the query parameter in cookies
    Cookies.set("afl", affiliate, { expires: 365 * 10 });
  }

  next();
});

export default router;
