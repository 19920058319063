<script setup>
import Title from '../../components/base/Title.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
    <main>
        <section class="hero-3">
            <div class="hero" style="position: absolute !important;width: 100%;top: 0;left: 0;z-index: 0;">
            </div>
            <!-- <div class="grid-overlay"></div> -->
            <div class="d-flex" style="flex-direction: column;justify-content: center; min-height: 50vh;"
                data-aos="fade-up">
                <div class="container">
                    <div class="row align-items-center justify-content-center text-center">
                        <div class="col-md-10 ">
                            <h1 class="display-2 text-white fw-bold my-4">
                                <Title>
                                    <span class="text-white me-2">{{ $t('termsOfService.titlePart1') }}</span>
                                    <br />
                                    <template #after>{{ $t('termsOfService.titlePart2') }}</template>
                                </Title>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container position-relative">
                <div class="fs-4 fw-bold text-white " data-aos="fade-up">
                    {{ $t('termsOfService.mainTitle') }}
                </div>

                <div class="text-white mt-2" data-aos="fade-up">
                    <p>{{ $t('termsOfService.lastUpdated') }}</p>
                    <p>{{ $t('termsOfService.intro') }}</p>

                    <ol>
                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.acceptanceOfTermsTitle') }}</p>
                            <p>{{ $t('termsOfService.acceptanceOfTermsContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.servicesProvidedTitle') }}</p>
                            <p>{{ $t('termsOfService.servicesProvidedContent') }}</p>
                            <ul>
                                <li>{{ $t('termsOfService.servicesProvidedListItem1') }}</li>
                                <li>{{ $t('termsOfService.servicesProvidedListItem2') }}</li>
                                <li>{{ $t('termsOfService.servicesProvidedListItem3') }}</li>
                                <li>{{ $t('termsOfService.servicesProvidedListItem4') }}</li>
                                <li>{{ $t('termsOfService.servicesProvidedListItem5') }}</li>
                            </ul>
                            <p>{{ $t('termsOfService.servicesProvidedNotice') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.eligibilityTitle') }}</p>
                            <p>{{ $t('termsOfService.eligibilityContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.accountRegistrationTitle') }}</p>
                            <p>{{ $t('termsOfService.accountRegistrationContent') }}</p>
                            <ul>
                                <li>{{ $t('termsOfService.accountRegistrationListItem1') }}</li>
                                <li>{{ $t('termsOfService.accountRegistrationListItem2') }}</li>
                                <li>{{ $t('termsOfService.accountRegistrationListItem3') }}</li>
                            </ul>
                            <p>{{ $t('termsOfService.accountRegistrationResponsibility') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.paymentTermsFeesAndChargesTitle') }}</p>
                            <p>{{ $t('termsOfService.paymentTermsFeesAndChargesContent') }}</p>
                            <ul>
                                <li>{{ $t('termsOfService.paymentTermsFeesAndChargesListItem1') }}</li>
                                <li>{{ $t('termsOfService.paymentTermsFeesAndChargesListItem2') }}</li>
                                <li>{{ $t('termsOfService.paymentTermsFeesAndChargesListItem3') }}</li>
                            </ul>

                            <p class="fs-6 my-2">{{ $t('termsOfService.paymentTermsPaymentMethodsTitle') }}</p>
                            <p>{{ $t('termsOfService.paymentTermsPaymentMethodsContent') }}</p>

                            <p class="fs-6 my-2">{{ $t('termsOfService.paymentTermsLatePaymentsTitle') }}</p>
                            <p>{{ $t('termsOfService.paymentTermsLatePaymentsContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.intellectualPropertyOwnershipTitle') }}</p>
                            <p>{{ $t('termsOfService.intellectualPropertyOwnershipContent') }}</p>

                            <p class="fs-6 my-2">{{ $t('termsOfService.intellectualPropertyLicenseTitle') }}</p>
                            <p>{{ $t('termsOfService.intellectualPropertyLicenseContent') }}</p>

                            <p class="fs-6 my-2">{{ $t('termsOfService.intellectualPropertyUserGeneratedContentTitle') }}</p>
                            <p>{{ $t('termsOfService.intellectualPropertyUserGeneratedContentContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.confidentialityTitle') }}</p>
                            <p>{{ $t('termsOfService.confidentialityContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.complianceLegalComplianceTitle') }}</p>
                            <p>{{ $t('termsOfService.complianceLegalComplianceContent') }}</p>

                            <p class="fs-6 my-2">{{ $t('termsOfService.complianceAmlKycTitle') }}</p>
                            <p>{{ $t('termsOfService.complianceAmlKycContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.terminationByYouTitle') }}</p>
                            <p>{{ $t('termsOfService.terminationByYouContent') }}</p>

                            <p class="fs-6 my-2">{{ $t('termsOfService.terminationByMintweb3Title') }}</p>
                            <p>{{ $t('termsOfService.terminationByMintweb3Content') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.limitationOfLiabilityTitle') }}</p>
                            <p>{{ $t('termsOfService.limitationOfLiabilityContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.indemnificationTitle') }}</p>
                            <p>{{ $t('termsOfService.indemnificationContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.disputeResolutionTitle') }}</p>
                            <p>{{ $t('termsOfService.disputeResolutionContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.governingLawTitle') }}</p>
                            <p>{{ $t('termsOfService.governingLawContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.changesToTermsTitle') }}</p>
                            <p>{{ $t('termsOfService.changesToTermsContent') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ $t('termsOfService.contactUsTitle') }}</p>
                            <p>{{ $t('termsOfService.contactUsContent') }}</p>
                        </li>
                    </ol>

                </div>
            </div>
        </section>
    </main>
</template>


<style scoped>
.border-bottom {
    border-bottom: 1px solid white;
    opacity: 0.16;
}
</style>