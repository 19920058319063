export const en = {
  general: {
    submit: "Submit",
    connect: "Connect",
    buy: "Buy",
    buyAndStake: "Buy and stake",
    buyWithCard: "Buy with card",
    stake: "Stake",
    stakeNow: "Stake now",
    claimTokens: "Claim",
    claimAndStake: "Claim and stake",
    claimRewards: "Claim rewards",
    becomeAnAffiliate: "Become an Affiliate!",
    loading: "Loading...",
    viewTransaction: "View transaction",
    close: "Close",
    copy: "Copy",
    days: "Day | Days",
    hours: "Hour | Hours",
    minutes: "Minute | Minutes",
    seconds: "Second | Seconds",
    lastRound: "Last round",
    developedBy: "Developed by",
    headerBtn01: "Join Us Free World",
    headerBtn02: "Discover Those Worlds",
    web30: "WEB 3.0",
    multiNetwork: "Multi Network",
    socials: "Contacts",
    testnet: "Testnet",
    testnetDescription: "This widget is in testnet, do not use real funds.",
    previous: "Previous",
    next: "Next",
  },
  navbar: {
    home: "Home",
    presale: "Presale",
    staking: "Staking",
    airdrop: "Airdrop / Giveaway",
    marketing: "Marketing",
    contact: "Contact",
  },
  footer: {
    tittle01: "Complete And",
    tittle02: "Innovative",
    tittle03: "Solutions For",
    tittle04: "Your Web 3.0",
    tittle05: "Project",
    quickLinks: "Quick Links",
    legal: "Legal",
    termsOfServices: "Terms Of Services",
    cookiePolicy: "Cookie Policy",
    userAgreement: "User Agreement",
    communityGuideline: "Community Guideline",
  },
  presale: {
    howToBuy: "How to buy",
    presaleOpen: "{token} Presale is now open",
    stage: "Stage",
    untilPriceIncrease: "Until price increase",
    untilPriceIncreaseDescription:
      "Price will increase when the timer ends or when the USD raised reaches the target.",
    nextPrice: "Next price",
    usdtRaised: "USDT Raised",
    yourPurchased: "Your purchased {token}",
    yourPurchasedDescription: "Total purchased tokens with connected address on selected chain",
    yourStakeable: "Your stakeable {token}",
    yourStakeableDescription:
      "Total unstaked tokens with connected address on selected chain. You can stake your tokens now going to staking page.",
    yourStaked: "Your staked {token}",
    yourStakedDescription: "Total staked tokens with connected address on selected chain."
  },
  swap: {
    balance: "Balance",
    youPay: "{token} you pay",
    youReceive: "{token} you receive",
    lessThanMinimumError: "The amount of { token } to be purchased must be greater than { min }.",
    exceedsMaxError: "The amount of { token } to be purchased must be less than { max }.",
    notEnoughBalanceError: "Make sure you have enough balance to purchase.",
    needApprovalDescription:
      "To purchase {token} with {usdToken}, you first need to authorize us to access the {usdToken} in your wallet. You may need to check the wallet app if you're on mobile.",
    waitingApprovalDescription:
      "Authorization is in progress, you will be able to confirm the transaction shortly.",
    needConfirmationDescription:
      "To purchase {tokenSymbol} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile.",
    waitingTransactionDescription:
      "{amount} tokens will be purchased once the transaction is confirmed.",
    openingWertModal: "Wait while the payment screen opens.",
    waitingWertPayment:
      "To purchase {token} with card, proceed by filling in your informations on the payment screen.",
    waitingWertConfirmation:
      "{amount} tokens will be purchased once the payment provider confirms your payment.",
    successDescription: "{amount} tokens were successfully purchased!",
    confirmationError:
      "We were unable to confirm your transaction, please check if it is stuck in your wallet.",
    errorDescription: "Something went wrong, please try again.",
    startAgain: "Start again",
    notEnoughTokens: "Not enough {token}? Buy or swap now!",
    buyOrSwapTokens: "Buy or swap tokens"
  },
  presaleOver: {
    presaleOverTitle: "{token} Presale is over",
    presaleOverDescription:
      "Wait until the claim begins to claim and negotiate your {token} tokens. In the meantime, you can stake your purchased tokens and earn rewards.",
    untilClaimStart: "Until claim start"
  },
  claim: {
    claimOpen: "{token} Claim is now live",
    claimOpenDescription: "Now you can claim and stake your {token} to earn rewards.",
    importToken:
      "Import the {token} token in your wallet, using the address below, to see your claimed tokens.",
    importTokenDescription:
      "If you don't know how to do this, search for 'How to import custom tokens' in the wallet you are using.",
    yourClaimable: "Your claimable {token}",
    yourClaimableDescription:
      "Total tokens available to claim with the connected address. You can claim your tokens to your wallet or stake them directly.",
    yourStaked: "Your staked {token}",
    yourStakedDescription: "Total staked tokens with connected address.",
    buyNowOnExchange: "Buy {token} now on {exchange}",
    stakeNeedConfirmation:
      "To stake your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will stake all your claimable {token} tokens. Tokens will be locked for {lockDays} @.lower:days until you can withdraw.",
    claimNeedConfirmation:
      "To claim your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will claim all your claimable {token} tokens to your connected wallet.",
    stakeWaitingTransaction: "Tokens will be staked once the transaction is confirmed.",
    claimWaitingTransaction: "Tokens will be claimed once the transaction is confirmed.",
    stakeSuccess: "Tokens were successfully staked!",
    claimSuccess:
      "Tokens were successfully claimed! Remember, to see your {token} balance in your wallet, you need to import the token address above.",
    confirmationError:
      "We were unable to confirm your transaction, please check if it is stuck in your wallet.",
    errorDescription: "Something went wrong, please try again.",
    startAgain: "Start again"
  },
  staking: {
    welcome: "Welcome to {token} staking",
    distributionDescription:
      "The distribution of {tokenName} token rewards will occur at a rate of {rewardsPerDay} {tokenSymbol} per day and will be distributed over {duration} year.",
    withdrawStakedTokens: "Withdraw Staked Tokens",
    stakedBalance: "Staked balance",
    stakeableBalance: "Stakeable balance",
    totalRewards: "Total rewards",
    claimNotStarted:
      "The claim period will begin once the presale ends. Stay tuned for the presale conclusion to start claiming your rewards.",
    totalStaked: "Total staked",
    percentageOfPool: "Your % of pool",
    currentRewards: "Current rewards",
    estimatedRewards: "Estimated rewards",
    rewardRateDynamic: "Reward rate is dynamic",
    monthlyRewards: "Monthly = Rewards % / 12",
    dailyRewards: "Daily = Rewards % / 365",
    stakeNow: "Stake your {token} now",
    claimNow: "Claim your {token} now",
    withdrawNow: "Withdraw your {token} now",
    fillAmount:
      "Fill in the amount of {token} you wish to stake below and confirm the transaction to start to earning rewards.",
    amount: "Amount",
    notEnoughBalanceError: "Make sure you have enough balance to stake.",
    needApprovalDescription:
      "To stake {token}, you first need to authorize us to access the {token} in your wallet. You may need to check the wallet app if you're on mobile.",
    waitingApprovalDescription:
      "Authorization is in progress, you will be able to confirm the transaction shortly.",
    stakeNeedConfirmation:
      "To stake your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will stake all of your {token} tokens. Tokens will be locked for {lockDays} @.lower:days after claiming process goes live.",
    claimNeedConfirmation:
      "To claim your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will claim all of your {token} rewards.",
    withdrawNeedConfirmation:
      "To withdraw your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will withdraw all of your staked {token} tokens.",
    stakeWaitingTransaction: "Tokens will be staked once the transaction is confirmed.",
    claimWaitingTransaction: "Rewards will be claimed once the transaction is confirmed.",
    withdrawWaitingTransaction: "Tokens will be withdrawn once the transaction is confirmed.",
    stakeSuccess: "Tokens were successfully staked!",
    claimSuccess: "Tokens were successfully claimed!",
    withdrawSuccess: "Tokens were successfully withdrawn!",
    confirmationError:
      "We were unable to confirm your transaction, please check if it is stuck in your wallet.",
    errorDescription: "Something went wrong, please try again.",
    tryAgain: "Try again"
  },
  airdrop: {
    claimTitle: "{token} Airdrop Claim",
    claimNotStartedDescription:
      "The airdrop claim period will begin once the presale ends. Stay tuned for the presale conclusion to start claiming your rewards.",
    claimStartedDescription: "You can now claim your airdropped {token} to earn rewards.",
    importToken:
      "Import the {token} token in your wallet, using the address below, to see your claimed tokens.",
    importTokenDescription:
      "If you don\"t know how to do this, search for 'How to import custom tokens' in the wallet you are using.",
    yourClaimable: "Your claimable {token}",
    yourClaimableDescription:
      "Total tokens received during the airdrop available to claim with the connected address.",
    claimNeedConfirmation:
      "To claim your {token} tokens, please confirm the transaction in your wallet. You may need to check the wallet app if you're on mobile. This will claim all your claimable {token} tokens to your connected wallet.",
    claimWaitingTransaction: "Tokens will be claimed once the transaction is confirmed.",
    claimSuccess:
      "Tokens were successfully claimed! Remember, to see your {token} balance in your wallet, you need to import the token address above.",
    confirmationError:
      "We were unable to confirm your transaction, please check if it is stuck in your wallet.",
    errorDescription: "Something went wrong, please try again.",
    startAgain: "Start again"
  },
  affiliate: {
    affiliateName: "Affiliate name",
    affiliateNameDescription:
      "The affiliate name will be used for your identification and to generate your affiliate link",
    parentAffiliateName: "Parent affiliate name",
    affiliateCreated: "Affiliate created successfully!",
    affiliateLink: "Your affiliate link",
    affiliateLinkDescription: "Share your affiliate link with your community to earn rewards for your referrals.",
    balance: "Balance",
    available: "Available",
    withdrawn: "Withdrawn",
    total: "Total",
    transactions: "Transactions",
    transactionHash: "Transaction hash",
    transactionAmount: "Amount",
    affiliatePercentage: "Affiliate percentage",
    noTransactions: "No transactions yet",
    commission: "Commission",
    totalTx: "Total Tx",
    affiliateInformation: "Affiliate Information",
    date: "Date",
    network: "Network"
  },
  status: {
    NEED_APPROVAL: "Need approval",
    WAITING_APPROVAL: "Waiting for approval confirmation",
    NEED_CONFIRMATION: "Need confirmation",
    WAITING_TRANSACTION: "Waiting for transaction confirmation",
    OPENINIG_WERT_MODAL: "Opening payment screen",
    WAITING_WERT_PAYMENT: "Waiting payment",
    WAITING_WERT_CONFIRMATION: "Waiting payment confirmation",
    SUCCESS: "Success",
    ERROR: "Error"
  },
  viewIndex: {
    aboutItems01: "To democratize access to Web 3.0 by delivering innovative, scalable, and secure blockchain solutions.",
    aboutItems02: "We bridge the gap between traditional companies and the decentralized economy.",
    aboutItems03: "We guarantee seamless integration and maximum value for our customers.",
    development: "DEVELOPMENT",
    headerSubText: "Web 3.0 development creates decentralized apps (dApps) using blockchain for user-controlled data and secure peer-to-peer interactions.",
    aboutUs01: "About",
    aboutUs02: "Us",
    aboutUsText: "At MintWeb3, we empower businesses and individuals with cutting-edge Web 3.0 solutions. From token development to full-scale ICO management and marketing, we’re here to help you unlock the potential of the decentralized internet. Based in Hong Kong, a global leader in finance and technology, we offer unparalleled expertise in blockchain, cryptocurrency and digital payments.",
    ourMission: "Our Mission",
    discoverOurSolutions01: "Discover",
    discoverOurSolutions02: "Our Solutions",
    ourServices01Ttitle: "Token Development",
    ourServices01Description: "Custom token development on blockchain networks like Ethereum, Binance Smart Chain, Solana, and others.",
    ourServices02Ttitle: "Coin Development",
    ourServices02Description: "Development of RWA, meme coins, layer-1 and layer-2, AI and other themed cryptocurrencies.",
    ourServices03Ttitle: "Wallet Integration",
    ourServices03Description: "Integration of secure digital wallets like MetaMask and Trust Wallet for cryptocurrency management.",
    ourServices04Ttitle: "Whitepaper Creation",
    ourServices04Description: "We brainstorm with our blockchain experts to determine the best way to present your project.",
    ourServices05Ttitle: "Staking Multi-Chain Solution",
    ourServices05Description: "Multi-chain staking solutions, enabling rewards for token holders.",
    ourServices06Ttitle: "Smart Contract Development",
    ourServices06Description: "Develop smart contracts for your token. We build on ERC20, BEP2, TRON etc.",
    ourServices07Ttitle: "ICO Landing Page",
    ourServices07Description: "Design and development of ICO and memecoins, with full integration for their launch.",
    ourServices08Ttitle: "Affiliate Program",
    ourServices08Description: "Adding an affiliate system increases your brand's reach, generates passive sales, and strengthens strategic marketing partnerships.",
    ourServices09Ttitle: "Airdrop and Giveaways",
    ourServices09Description: "Airdrops and giveaways boost brand visibility, attract new users, and encourage engagement, generating positive attention.",
    ourServices10Ttitle: "Compliance and Audit",
    ourServices10Description: "Regulatory compliance and security audits to ensure the legality and safety of projects.",
    ourServices11Ttitle: "Consulting & Legal",
    ourServices11Description: "We offer consultancy for company opening, licenses, regulatory compliance and audits for smart contracts.",
    whyChoose01: "Why Choose",
    whyChoose02: "MintWeb3?",
    whyChooseText: "At MintWeb3, we understand that launching and scaling a Web 3.0 project requires a unique combination of technical expertise, innovative marketing, and strategic planning",
    expertiseWeb3: "Expertise in Web3 Technologies",
    expertise01: "Proven Marketing Strategies for ICOs",
    expertise02: "Customized Solutions",
    expertise03: "Consulting for fintechs",
    expertise04: "Global Reach and Network",
    expertise05: "Cutting-Edge Innovation",
  },
  viewAirdrop: {
    headerTitle: "AIRDROP / GIVEAWAY",
    headerDescription: "Enable seamless campaigns where users join airdrops, complete tasks, claim rewards, and grow the community.",
    airdropTitle01: "Web3-Powered",
    airdropTitle02: "Airdrops",
    airdropDescription01: "Our Airdrop Widget offers a seamless and secure solution for distributing tokens to your community. Users can easily connect their wallets, whether it's MetaMask, Trust Wallet, or others, to claim their airdrop rewards directly through the widget. This user-friendly integration ensures that the airdrop process is smooth and efficient, reducing friction for participants.",
    airdropDescription02: "Once connected, users can quickly claim their tokens without navigating complex procedures, driving higher participation and engagement in your campaign. The widget is fully customizable to match your branding and tokenomics, providing a consistent experience across platforms. Whether for promotional events, community-building, or token launches, our airdrop widget helps drive user acquisition and foster long-term growth.",
    airdropPlayButton: "Play Our Game On Telegram",
    airdropServices01Title: "Airdrop Gaming",
    airdropServices01Description: "Our Airdrop Telegram Game Solution offers an interactive and engaging way to distribute tokens through a Click-to-Earn game. Users can participate directly within your Telegram group by completing simple tasks, such as clicking on links, answering questions, or performing actions that unlock airdrop rewards. This gamified approach not only drives more participation but also boosts community interaction and excitement. It’s an effective tool to grow your Telegram community while rewarding users for their engagement. The solution is easy to set up and integrates seamlessly with Telegram, providing a fun and rewarding experience for all participants.",
    airdropServices01Item01Title: "Increased Engagement: ",
    airdropServices01Item01Description: "Gamifies the airdrop process, encouraging more participation.",
    airdropServices01Item02Title: "Easy Telegram Integration: ",
    airdropServices01Item02Description: "Seamlessly connects with your existing Telegram community.",
    airdropServices01Item03Title: "Boosts Community Growth: ",
    airdropServices01Item03Description: "Drives new users and retains existing participants with fun rewards.",
    giveawayTitle01: "Web3-Powered",
    giveawayTitle02: "Giveaway",
    giveawayDescription: "Our Giveaway Widget offers an easy and engaging way for projects to host token giveaways and reward their community. Fully customizable, the widget can be integrated into your website or platform, allowing users to participate by completing simple tasks like following social media accounts, sharing content, or referring friends. Participants can track their progress and entries in real-time, making the process transparent and interactive. The widget is designed for seamless use, ensuring a smooth experience for both administrators and users. It helps increase brand visibility, grow your community, and incentivize user participation. Whether for marketing campaigns, promotions, or community-building, our Giveaway Widget is the perfect tool to boost engagement and attract new users.",
    giveawayKeyBenefits: "Key Benefits",
    giveawayWidget: "Mint Web3 Giveaway",
    giveawayKeyBenefits01: "Encourages active participation by rewarding users for completing tasks, fostering deeper connections and loyalty within your community.",
    giveawayKeyBenefits02: "Easily integrate the giveaway widget into your platform, with full customization options to align with your branding and goals.",
    giveawayKeyBenefits03: "Participants can monitor their progress in real-time, ensuring transparency and trust throughout the giveaway process.",
  },
  viewContact: {
    header01: "Contact",
    header02: "Us",
    description: "Get in touch with us for any inquiries, feedback, or support. We're here to help you!",
    schedule: "Schedule a meeting or contact us!",
  },
  viewMarketing: {
    headerTitle: "MARKETING",
    headerDescription: "Web 3.0 marketing uses decentralized platforms, NFTs, and blockchain to create transparent, personalized, and community-focused campaigns.",
    web3MarketingHeader01: "Create a",
    web3MarketingHeader02: "Community",
    web3MarketingDescription01: "In the fast-paced world of Web3, simply developing a project isn’t enough—you need a comprehensive marketing strategy to capture attention, build trust, and drive engagement. At MINTWEB3, we provide a full suite of Web3 marketing services designed to ensure the success of your project, whether you’re launching an ICO, managing a token presale, or building a community around your decentralized application (dApp).",
    web3MarketingDescription02: "Our team combines deep industry knowledge with innovative marketing techniques to give your Web3 project the exposure and credibility it needs to thrive in today’s competitive market.",
    web3ServicesHeader01: "Marketing",
    web3ServicesHeader02: "Services",
    marketing01Title: "Whitepaper Optimization",
    marketing01Description: "The whitepaper is the pillar of any Web3 project, serving as a detailed document that explains the technology, vision, and business model behind your project. At MintWeb3, we help you develop or optimize your whitepaper to clearly communicate your project’s goals and value to investors and the community.",
    marketing01Item01Title: "Clarity and Precision: ",
    marketing01Item01Description: "We ensure your whitepaper is easy to understand while covering the necessary technical details.",
    marketing01Item02Title: "Compelling Storytelling: ",
    marketing01Item02Description: "We use clear, concise language to engage both technical and non-technical audiences, presenting your project in a way that builds trust.",
    marketing02Title: "ICO Landing Page Design",
    marketing02Description: "A well-designed landing page is critical for capturing investor interest during an ICO or presale. Our team creates high-conversion landing pages that highlight your project’s benefits, showcase your team’s credibility, and make it easy for visitors to participate.",
    marketing02Item01Title: "User-Friendly Design: ",
    marketing02Item01Description: "Our landing pages are designed to be visually appealing and easy to navigate, with clear calls to action.",
    marketing02Item02Title: "Optimized for Conversions: ",
    marketing02Item02Description: "We focus on creating landing pages that drive sign-ups, token purchases, and community engagement.",
    marketing03Title: "ICO Management",
    marketing03Description: "Managing an ICO requires careful planning and execution. MinWeb3 provides full ICO management services, helping you strategize and execute every aspect of your token sale, from marketing and investor outreach to managing the fundraising process.",
    marketing03Item01Title: "Fundraising Strategy: ",
    marketing03Item01Description: "We help you develop a strategic approach to your ICO, setting realistic goals and designing an effective tokenomics model.",
    marketing03Item02Title: "End-to-End Support: ",
    marketing03Item02Description: "We handle everything from presale to token distribution, ensuring a smooth and compliant ICO process.",
    marketing04Title: "Exchange Listing Assistance",
    marketing04Description: "Getting your token listed on major cryptocurrency exchanges is key to its success. We assist you in listing your token on top-tier exchanges, ensuring liquidity and visibility for your token once the ICO is complete.",
    marketing04Item01Title: "Exchange Research: ",
    marketing04Item01Description: "We identify the best exchanges for your token based on your target market and tokenomics.",
    marketing04Item02Title: "Application and Negotiation: ",
    marketing04Item02Description: "We handle the application process and negotiate on your behalf to secure favorable listing terms.",
    marketing05Title: "ICO Listing on Popular Platforms",
    marketing05Description: "In addition to exchange listings, we help to list your ICO on prominent ICO tracking and listing platforms, giving your project exposure to a broad audience of investors and enthusiasts.",
    marketing05Item01Title: "Visibility: ",
    marketing05Item01Description: "We help you develop a strategic approach to your ICO, setting realistic goals and designing an effective tokenomics model.",
    marketing05Item02Title: "Trust and Engagement: ",
    marketing05Item02Description: "Listing on respected platforms helps build trust with potential investors and communities.",
    marketing06Title: "Community Management",
    marketing06Description: "Building and managing a vibrant, engaged community is crucial to the long-term success of any Web3 project. We help you manage communities on key platforms like Telegram, Tik Tok, X, Intagram and Discord, ensuring that your supporters stay informed, engaged, and excited about your project.",
    marketing06Item01Title: "Community Growth: ",
    marketing06Item01Description: "We help grow your community through active engagement, contests, and content creation.",
    marketing06Item02Title: "24/7 Moderation: ",
    marketing06Item02Description: "We handle the application process and negotiate on your behalf to secure favorable listing terms.",
    marketing07Title: "Website Audit and SEO",
    marketing07Description: "Your project’s website is often the first point of contact for potential investors and users. We perform a detailed audit of your website to ensure it is optimized for search engines (SEO) and delivers a smooth user experience.",
    marketing07Item01Title: "SEO Optimization: ",
    marketing07Item01Description: "We implement SEO best practices to ensure your website ranks higher on search engines and attracts organic traffic.",
    marketing07Item02Title: "User Experience (UX) Improvement: ",
    marketing07Item02Description: "We assess and improve your website’s usability, making it easier for visitors to navigate and take action.",
    marketing08Title: "Copywriting for Web3 Projects",
    marketing08Description: "Content is key in building a strong narrative around your Web3 project. Our team of skilled writers creates high-quality content for every stage of your project, from whitepapers and blog posts to social media updates and press releases.",
    marketing08Item01Title: "Engaging Content: ",
    marketing08Item01Description: "We write clear, compelling content that communicates the value of your project to a wide audience.",
    marketing08Item02Title: "Consistency Across Channels: ",
    marketing08Item02Description: "From your website to your social media accounts, we ensure consistent messaging and tone across all platforms.",
    marketing09Title: "Graphic and Video Content Creation",
    marketing09Description: "Visual content is essential in capturing attention and conveying complex ideas quickly. Our creative team designs graphics, videos, and animations that make your project stand out and communicate your vision effectively.",
    marketing09Item01Title: "Explainer Videos: ",
    marketing09Item01Description: "We create engaging explainer videos that break down your project’s technology and benefits.",
    marketing09Item02Title: "Graphic Design: ",
    marketing09Item02Description: "From logo creation to banner ads, we design visual assets that enhance your brand’s visibility and appeal.",
    marketing10Title: "Sticker Sets and GIFs",
    marketing10Description: "Boost engagement on social media and community platforms with fun, branded sticker sets and GIFs that resonate with your audience. These assets are perfect for increasing visibility during airdrops, giveaways, and community events.",
    marketing10Item01Title: "Custom Stickers: ",
    marketing10Item01Description: "We create unique sticker sets that reflect your project’s personality and branding.",
    marketing10Item02Title: "Promotional GIFs: ",
    marketing10Item02Description: "Shareable GIFs that can be used across social media platforms to increase your project’s visibility.",
    marketing11Title: "Promotional Banners",
    marketing11Description: "Our design team creates eye-catching banners for use on websites, blogs, and social media. These banners are designed to promote key aspects of your project, such as upcoming events, token sales, or new features.",
    marketing11Item01Title: "High-Quality Design: ",
    marketing11Item01Description: "Our banners are visually striking and designed to capture attention.",
    marketing11Item02Title: "Targeted Messaging: ",
    marketing11Item02Description: "Each banner communicates a clear, actionable message that drives interest and engagement.",
    marketing12Title: "Influencer and Viral Marketing",
    marketing12Description: "Leverage the power of influencers and viral content to increase your project’s reach. We partner with top crypto influencers and implement viral marketing campaigns that generate buzz around your project.",
    marketing12Item01Title: "Influencer Collaborations: ",
    marketing12Item01Description: "We connect you with leading influencers in the crypto space to promote your project to their audience.",
    marketing12Item02Title: "Viral Marketing Campaigns: ",
    marketing12Item02Description: "We create content designed to go viral, driving maximum exposure and engagement.",
  },
  viewPayments: {
    headerTitle: "PRESALE",
    headerDescription: "MintWeb's Crypto Toolkit simplifies launches with automated features, security, and customizable solutions for successful presales.",
    paymentsTitle01: "Web3-Powered",
    paymentsTitle02: "Payments",
    paymentsDescription01: "MintWeb’s Web3 Payment Widget is a powerful tool designed to streamline presale processes for token projects. With full multi-currency support, users can easily pay in a variety of cryptocurrencies, expanding your project’s reach to a global audience. The integrated swap functionality allows investors to seamlessly convert assets, making it easier to participate in the presale, regardless of their token holdings.",
    paymentsDescription02: "Our solution also includes built-in affiliate marketing features, enabling projects to reward users for driving traffic and bringing in new participants. This incentivizes community growth and engagement while boosting presale success. The widget gives projects full control over presale rounds, allowing for dynamic adjustments and offering a customizable experience to suit different fundraising strategies.",
    paymentsDescription03: "Additionally, MintWeb's Web3 Payment Widget supports card payments, removing the barriers for non-crypto natives to participate in token sales. By simplifying the payment process, you ensure that anyone can join, regardless of their experience with cryptocurrencies.",
    paymentsDescription04: "With robust security, seamless integration, and user-friendly features, MintWeb’s Web3 Payment Widget is the all-in-one solution to optimize your presale, increase accessibility, and drive project growth. It’s the perfect tool for any Web3 initiative looking to maximize efficiency and inclusivity.",
    multiWalletTittle01: "Multi-Wallet",
    multiWalletTittle02: "Integration",
    multiWalletDescription: "MintWeb’s multi-wallet integration offers users the flexibility to connect and manage multiple wallets during presales, including popular options like MetaMask, Trust Wallet, and others. This seamless integration ensures that participants can easily switch between wallets, streamlining the transaction process. With this feature, we enhance accessibility for a broader audience, making it easier for both crypto-savvy users and newcomers to engage in presales.",
    multiWalletBenefitsTitle: "Key Benefits",
    multiWalletBenefits01: "Simplified wallet management;",
    multiWalletBenefits02: "Broad wallet compatibility;",
    multiWalletBenefits03: "Enhanced user accessibility;",
    coinLaunchTittle01: "Coin Launch",
    coinLaunchTittle02: "Toolkit",
    coinLaunchDescription01: "MintWeb’s Coin Launch Toolkit is specially designed for launching meme coins, Real-World Asset (RWA)-backed tokens, and more. It simplifies token creation, presale management, and smart contract deployment with tailored features to suit unique use cases. Whether you're launching a fun meme coin or a serious RWA-backed token, our toolkit ensures secure, efficient execution.",
    coinLaunchDescription02: "With options for distribution, investor tracking, and scalability, MintWeb helps you bring your vision to life while maintaining transparency and security.",
    coinLaunchItems01: "Receive Coins",
    coinLaunchItems02: "Buy Coins Integration",
    coinLaunchItems03: "Staking Multichain",
    coinLaunchItems04: "Smart Contract Development",
    coinLaunchItems05: "Claim Token",
    coinLaunchItems06: "Whitepaper Creation",
    vestingTittle01: "Optimized Distribution",
    vestingTittle02: "With TGE, Cliff, and Vesting",
    vestingDescription: "Token distribution is a critical part of any successful crypto presale. With carefully designed mechanisms, you can ensure fairness, stability, and long-term value for your project. Incorporating these features into your presale not only protects your token’s value but also strengthens your project's foundation for future success.",
    vestingBenefitsTitle: "Here’s how it can enhance your presale:",
    vestingBenefits01: "TGE (Token Generation Event): Control when tokens are made available to investors, aligning with your project's goals and timeline. A well-timed TGE builds trust and excitement within your community;",
    vestingBenefits02: "Cliff Period: Lock tokens for a defined period after the presale to prevent immediate selling. This strategy supports price stability and helps maintain investor confidence;",
    vestingBenefits03: "Vesting Schedules: Distribute tokens gradually over time, rewarding loyal holders and fostering sustained project growth. Vesting encourages long-term engagement and reduces market volatility;",
    salesBotTittle01: "Social",
    salesBotTittle02: "Sales Bot",
    salesBotDescription01: "The Mintweb3 Sales Bot is a powerful tool for crypto projects to track and display sales activity across Telegram, Twitter, and Discord. It provides real-time updates on sales, transactions, and milestones, ensuring your community stays informed and engaged.",
    salesBotDescription02: "With customizable alerts and data insights, the Mintweb3 Sales Bot allows you to monitor performance and share key metrics with your community. Boost engagement, create transparency, and build trust by showcasing live sales data in a dynamic, professional way.",
  },
  viewStaking: {
    headerTitle: "STAKING",
    headerDescription: "We offer customizable, secure, multi-chain support, flexible models, to drive community engagement and token value growth.",
    stakingAdvantagesTitle01: "Staking",
    stakingAdvantagesTitle02: "Advantages",
    stakingAdvantages01Title: "Key Features",
    stakingAdvantages01Description: "Mint Web3 Staking Services provide a secure, customizable solution for token projects looking to increase user engagement and incentivize long-term holding. Our staking widget can be easily integrated into your platform, offering a seamless experience for users to stake across multiple blockchain networks. The widget is fully customizable, allowing you to adjust it to match your brand and tokenomics. By offering flexible staking models like single-token and liquidity pool staking, MintWeb3 helps create an ecosystem that suits your specific goals and reward structures.",
    stakingAdvantages01Item01Title: "Multi-Chain Staking: ",
    stakingAdvantages01Item01Description: "Stake across various blockchain networks for broader reach.",
    stakingAdvantages01Item02Title: "Customizable Widget: ",
    stakingAdvantages01Item02Description: "Tailor the staking interface to your branding and tokenomics.",
    stakingAdvantages01Item03Title: "User-Friendly Interface: ",
    stakingAdvantages01Item03Description: "Seamless integration for an intuitive staking experience.",
    stakingAdvantages02Title: "Key Benefits",
    stakingAdvantages02Description: "Reducing circulating supply through staking, projects can create scarcity, which can positively impact token value. This fosters a more engaged and loyal community while supporting the long-term growth of your ecosystem. We use industry-leading encryption protocols to ensure the safety of all staked assets, offering peace of mind to participants.",
    stakingAdvantages02Item01Title: "Robust Security: ",
    stakingAdvantages02Item01Description: "Advanced encryption to protect staked assets.",
    stakingAdvantages02Item02Title: "Increased Token Value: ",
    stakingAdvantages02Item02Description: "Reducing circulating supply through staking can enhance token scarcity.",
    stakingAdvantages02Item03Title: "Community Engagement: ",
    stakingAdvantages02Item03Description: "Incentivizing token holders leads to greater loyalty and participation.",
  },
  community: {
    guidelines: "Community",
    guidelinesSubtitle: "Guidelines",
    title: "MINTWEB3 Community Guidelines",
    welcomeMessage: "Welcome to the MINTWEB3 Community!",
    purposeMessage: "At MINTWEB3, we aim to build a thriving, inclusive, and innovative environment that empowers individuals and businesses to embrace Web 3.0 technologies.",
    participationMessage: "By participating in the MINTWEB3 community—whether on our platform, social media, or any associated forums—you agree to adhere to the following guidelines.",
    coreValuesTitle: "Core Values",
    coreValuesDescription: "We expect all community members to uphold the following core values:",
    coreValuesRespect: "Respect: Treat all members with dignity and respect. Disagreements are natural, but civility is mandatory.",
    coreValuesCollaboration: "Collaboration: Support each other by sharing knowledge, skills, and constructive feedback.",
    coreValuesIntegrity: "Integrity: Uphold honesty and transparency in all interactions.",
    coreValuesInnovation: "Innovation: Encourage and celebrate creativity, whether in the form of ideas, content, or solutions related to Web 3.0.",
    respectfulCommunicationTitle: "Respectful and Inclusive Communication",
    respectfulCommunicationDescription: "We are committed to maintaining an inclusive community where all voices can be heard. This means:",
    respectfulCommunicationNoHarassment: "Zero Tolerance for Harassment: Harassment, abuse, bullying, or threats of any kind will not be tolerated.",
    respectfulCommunicationNoHateSpeech: "No Hate Speech: Content or behavior that promotes or incites violence, hatred, or discrimination is strictly prohibited.",
    respectfulCommunicationConstructiveCriticism: "Constructive Criticism Only: Feedback must be respectful and focused on solutions, not personal attacks.",
    prohibitedConductTitle: "Prohibited Conduct",
    prohibitedConductDescription: "To maintain a positive environment, the following behaviors are prohibited:",
    prohibitedConductNoSpam: "Spam and Unsolicited Promotion: Irrelevant or repetitive content is not allowed.",
    prohibitedConductNoImpersonation: "Impersonation: Pretending to be MINTWEB3 staff, moderators, or other users is forbidden.",
    prohibitedConductNoIllegalActivities: "Illegal Activities: Content that promotes illegal activities will not be tolerated.",
    prohibitedConductNoMaliciousBehavior: "Malicious Behavior: Do not disrupt the community through hacking, phishing, or malware.",
    intellectualPropertyTitle: "Protecting Intellectual Property",
    intellectualPropertyDescription: "We respect the intellectual property (IP) of others and expect our community members to do the same:",
    intellectualPropertyNoCopyright: "No Copyright Infringement: Do not post content that infringes on copyrights or trademarks.",
    intellectualPropertyOriginalContent: "Original Content: Ensure you have permission to share content or that you own the rights to it.",
    intellectualPropertyAttribution: "Attribution: Give proper credit to original authors and creators.",
    securityPrivacyTitle: "Security and Privacy",
    securityPrivacyDescription: "Your security and privacy are our top priorities. Please follow these practices:",
    securityPrivacyNoPersonalInfo: "Do Not Share Personal Information: Avoid sharing sensitive personal information publicly.",
    securityPrivacyProtectAccount: "Protect Your Account: Use strong passwords and two-factor authentication (2FA).",
    securityPrivacyReportIssues: "Report Security Issues: Immediately report any vulnerabilities to our support team.",
    contentStandardsTitle: "Content Standards",
    contentStandardsDescription: "To keep the community helpful, relevant, and professional, follow these content standards:",
    contentStandardsRelevance: "Relevance: Ensure all contributions are related to Web 3.0, blockchain, or decentralized finance.",
    contentStandardsClarityAccuracy: "Clarity and Accuracy: Provide clear, concise, and accurate information. Misinformation will not be tolerated.",
    contentStandardsNoInappropriateContent: "No NSFW or Inappropriate Content: Avoid sharing sexually explicit or otherwise inappropriate content.",
    moderationEnforcementTitle: "Moderation and Enforcement",
    moderationEnforcementDescription: "We take violations seriously, and our moderators enforce these guidelines:",
    moderationEnforcementWarnings: "Warnings: Minor violations may result in a warning.",
    moderationEnforcementContentRemoval: "Content Removal: Posts that violate guidelines may be removed.",
    moderationEnforcementSuspensionBan: "Suspension or Ban: Severe violations can result in a ban.",
    moderationEnforcementAppeals: "Appeals: Users can appeal if they believe moderation was unfair.",
    reportingViolationsTitle: "Reporting Violations",
    reportingViolationsDescription: "Help us keep the community safe by reporting violations. Reports are confidential, and retaliation is not tolerated.",
    thirdPartyLinksTitle: "Third-Party Links and Content",
    thirdPartyLinksDescription: "MINTWEB3 is not responsible for third-party content linked within the community. Use external links at your own discretion.",
    updatesTitle: "Updates to the Guidelines",
    updatesDescription: "We may update these guidelines as needed. Participation after updates indicates acceptance of the new rules.",
    contactTitle: "Contact Us",
    contactDescription: "If you have questions, please contact us.",
  },
  cookiePolicy: {
    titleCookie: "Cookie",
    titlePolicy: "Policy",
    titleFull: "Cookie Policy",
    introHeader: "MINT WEB3 Cookie Policy",
    introEffectiveDate: "Effective Date: 18/10/2024",
    introParagraph: "At MINTWEB3, we value your privacy and strive to be transparent about how we collect and use data related to your online experience. This Cookie Policy explains what cookies are, how we use them, and your options regarding their usage on our platform.",
    whatAreCookiesTitle: "What Are Cookies?",
    whatAreCookiesDescription: "Cookies are small text files that are placed on your computer or mobile device when you visit websites. Cookies allow websites to recognize your device, enhance your user experience, and store preferences or other information for future visits.",
    whatAreCookiesTypesSessionCookies: "Session Cookies: Temporary cookies that expire when you close your browser.",
    whatAreCookiesTypesPersistentCookies: "Persistent Cookies: These cookies remain on your device for a set period or until you delete them.",
    whatAreCookiesTypesFirstPartyCookies: "First-Party Cookies: These are set by the website you're visiting.",
    whatAreCookiesTypesThirdPartyCookies: "Third-Party Cookies: These are set by third parties (such as analytics or advertising platforms) to track your online activity across different websites.",
    whyUseCookiesTitle: "Why Do We Use Cookies?",
    whyUseCookiesDescription: "MINTWEB3 uses cookies to improve the user experience on our platform. Specifically, we use cookies for the following purposes:",
    whyUseCookiesPurposesEssentialCookiesTitle: "Essential Cookies",
    whyUseCookiesPurposesEssentialCookiesDescription: "These cookies are critical for the operation of our website and services. They enable basic functions such as navigating between pages, accessing secure areas of the site, and ensuring the smooth functionality of our services.",
    whyUseCookiesPurposesEssentialCookiesExample: "Example: Authentication cookies to keep you logged in during your session.",
    whyUseCookiesPurposesPerformanceCookiesTitle: "Performance and Analytics Cookies",
    whyUseCookiesPurposesPerformanceCookiesDescription: "Performance cookies help us analyze how visitors interact with our platform. We use this data to improve the website’s performance, troubleshoot technical issues, and optimize the user experience.",
    whyUseCookiesPurposesPerformanceCookiesExample: "Example: Google Analytics cookies that collect data on user behavior, including pages visited, time spent on pages, and the source of traffic.",
    whyUseCookiesPurposesFunctionalityCookiesTitle: "Functionality Cookies",
    whyUseCookiesPurposesFunctionalityCookiesDescription: "These cookies enable the website to remember choices you’ve made in the past, such as your language preferences, location, or other personalized settings, improving the user experience by tailoring the platform to your needs.",
    whyUseCookiesPurposesFunctionalityCookiesExample: "Example: Remembering your preferred language settings.",
    whyUseCookiesPurposesMarketingCookiesTitle: "Marketing and Advertising Cookies",
    whyUseCookiesPurposesMarketingCookiesDescription: "Marketing cookies are used to track visitors across websites. Their purpose is to display ads that are relevant and engaging for the individual user. These cookies may be set by us or by third-party partners (such as advertising networks).",
    whyUseCookiesPurposesMarketingCookiesExample: "Example: Cookies that track engagement with our advertising campaigns and help us deliver targeted ads.",
    whyUseCookiesPurposesSocialMediaCookiesTitle: "Social Media Cookies",
    whyUseCookiesPurposesSocialMediaCookiesDescription: "These cookies enable you to share our content on social media platforms like Facebook, Twitter, and LinkedIn, or to interact with embedded content from those services. These cookies may track your activity across websites.",
    whyUseCookiesPurposesSocialMediaCookiesExample: "Example: Share buttons on blog posts or product pages that integrate with social media networks.",
    cookieTypesTitle: "What Types of Cookies Do We Use?",
    cookieTypesDescription: "Below is a summary of the types of cookies used on our platform:",
    cookieTypesTableHeadersCookieType: "Type of Cookie",
    cookieTypesTableHeadersPurpose: "Purpose",
    cookieTypesTableHeadersDuration: "Duration",
    cookieTypesRowsStrictlyNecessaryType: "Strictly Necessary Cookies",
    cookieTypesRowsStrictlyNecessaryPurpose: "Required for essential website functions, such as logging in and securing the platform.",
    cookieTypesRowsStrictlyNecessaryDuration: "Session (Temporary)",
    cookieTypesRowsPerformanceType: "Performance Cookies",
    cookieTypesRowsPerformancePurpose: "Analyzes user activity to improve the site’s performance and user experience.",
    cookieTypesRowsPerformanceDuration: "Persistent (Up to 2 years)",
    cookieTypesRowsFunctionalityType: "Functional Cookies",
    cookieTypesRowsFunctionalityPurpose: "Stores user preferences like language and location for a more personalized experience.",
    cookieTypesRowsFunctionalityDuration: "Persistent (1 year)",
    cookieTypesRowsMarketingType: "Targeting/Marketing Cookies",
    cookieTypesRowsMarketingPurpose: "Tracks user activity for advertising purposes and personalizes ad delivery across platforms.",
    cookieTypesRowsMarketingDuration: "Persistent (Up to 6 months)",
    cookieTypesRowsSocialType: "Social Media Cookies",
    cookieTypesRowsSocialPurpose: "Facilitates content sharing and social media interaction from within our platform.",
    cookieTypesRowsSocialDuration: "Persistent (1 year)",
    thirdPartyCookiesTitle: "Third-Party Cookies",
    thirdPartyCookiesDescription: "We work with third-party service providers, including analytics, marketing, and social media partners, who may set cookies on our behalf. These third parties may use the information collected through cookies for their own purposes, such as to improve their own services or to tailor advertising.",
    thirdPartyCookiesExamplesGoogleAnalytics: "Google Analytics: Tracks user interactions and site performance.",
    thirdPartyCookiesExamplesFacebookAds: "Facebook Ads: Delivers personalized ads based on your activity on our website.",
    thirdPartyCookiesExamplesSocialPlatforms: "Twitter, LinkedIn, etc.: For social sharing and engagement.",
    thirdPartyCookiesNote: "Please refer to the privacy and cookie policies of these third parties for detailed information on how they use cookies.",
    controlCookiesTitle: "How Can You Control Cookies?",
    controlCookiesDescription: "You have the right to manage and control your cookie preferences. You can do this in the following ways:",
    controlCookiesMethodsBrowserSettingsTitle: "Browser Settings",
    controlCookiesMethodsBrowserSettingsDescription: "Most web browsers allow you to control cookies through their settings. You can choose to block or delete cookies, or be notified when a cookie is being set on your device. Please note that disabling cookies may affect the functionality of certain parts of our website.",
    controlCookiesMethodsBrowserSettingsLinksChrome: "For Google Chrome: (https://support.google.com/chrome/answer/95647)",
    controlCookiesMethodsBrowserSettingsLinksFirefox: "For Mozilla Firefox: (https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US)",
    controlCookiesMethodsBrowserSettingsLinksSafari: "For Safari: (https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac)",
    controlCookiesMethodsBrowserSettingsLinksEdge: "For Microsoft Edge: (https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d)",
    controlCookiesMethodsOptOutTitle: "Opting Out of Third-Party Cookies",
    controlCookiesMethodsOptOutDescription: "You can opt out of third-party marketing and advertising cookies by visiting their opt-out pages:",
    controlCookiesMethodsOptOutLinksGoogleAds: "Google Ads Preferences: (https://myadcenter.google.com/home?sasb=true&ref=ad-settings)",
    controlCookiesMethodsOptOutLinksFacebookAds: "Facebook Ad Preferences: (https://www.facebook.com/settings?tab=ads)",
    controlCookiesMethodsOptOutLinksYourChoices: "Your Online Choices: (https://www.youronlinechoices.com/)",
    controlCookiesMethodsCookieConsentBannerTitle: "Cookie Consent Banner",
    controlCookiesMethodsCookieConsentBannerDescription: "Upon your first visit to MINTWEB3, you will see a cookie consent banner. You can choose to accept or decline cookies through this banner, and manage your preferences at any time through our website’s privacy settings.",
    policyChangesTitle: "Changes to This Policy",
    policyChangesDescription: "We may update this Cookie Policy from time to time to reflect changes in technology, applicable law, or our practices. When we make significant changes, we will notify you by posting a prominent notice on our website or by directly informing you through email or other communication channels.",
    contactTitle: "Contact Us",
    contactDescription: "If you have any questions about this Cookie Policy or how we use cookies, feel free to contact us.",
  },
  termsOfService: {
    titlePart1: "Terms of",
    titlePart2: "Services",
    mainTitle: "MINTWEB3 Terms of Service",
    lastUpdated: "Last Updated: 18/10/2024",
    intro: "Welcome to MINTWEB3 ('Company', 'we', 'us', or 'our'). These Terms of Service govern your use of our website, platform, and any related services provided by MINTWEB3 (collectively, the 'Services'). By accessing or using our Services, you agree to comply with and be bound by these Terms of Service (the 'Terms'). If you do not agree with these Terms, you must not access or use our Services.",
    acceptanceOfTermsTitle: "Acceptance of Terms",
    acceptanceOfTermsContent: "By using our Services, you agree to be bound by these Terms, as well as our Privacy Policy, Cookie Policy, User Agreement, and any other legal documents that may apply. These Terms apply to all users, including but not limited to visitors, registered users, clients, and contributors of content.",
    servicesProvidedTitle: "Services Provided",
    servicesProvidedContent: "MINTWEB3 is a technology and marketing company that specializes in Web 3.0 solutions, offering a broad range of services, including but not limited to:",
    servicesProvidedListItem1: "Token creation and development on various blockchain networks.",
    servicesProvidedListItem2: "Smart contract development and auditing.",
    servicesProvidedListItem3: "Marketing services for ICOs and STOs, including whitepaper creation, community management, and SEO.",
    servicesProvidedListItem4: "Multi-chain staking and wallet integrations.",
    servicesProvidedListItem5: "Consultation and compliance services for blockchain projects.",
    servicesProvidedNotice: "We reserve the right to modify, suspend, or discontinue the Services at any time, with or without notice, and without liability to you.",
    eligibilityTitle: "Eligibility",
    eligibilityContent: "You must be at least 18 years of age or the legal age of majority in your jurisdiction to use our Services. By using our Services, you represent that you have the legal capacity to enter into a binding contract.",
    accountRegistrationTitle: "Account Registration",
    accountRegistrationContent: "To access certain features of our Services, you may be required to register an account. You agree to:",
    accountRegistrationListItem1: "Provide accurate, current, and complete information during registration.",
    accountRegistrationListItem2: "Maintain the confidentiality of your account credentials.",
    accountRegistrationListItem3: "Notify us immediately if you suspect unauthorized access or use of your account.",
    accountRegistrationResponsibility: "You are responsible for all activities that occur under your account, and we are not liable for any loss or damage arising from unauthorized use.",
    paymentTermsFeesAndChargesTitle: "Fees and Charges",
    paymentTermsFeesAndChargesContent: "All fees and costs associated with the Services will be clearly outlined in your specific agreement or as listed on our website. Payment is required before services are rendered unless otherwise agreed upon in writing. Fees may include, but are not limited to:",
    paymentTermsFeesAndChargesListItem1: "Development fees for token and smart contract creation.",
    paymentTermsFeesAndChargesListItem2: "Marketing fees for ICO campaigns, community management, and PR services.",
    paymentTermsFeesAndChargesListItem3: "Consulting fees for compliance, auditing, and strategy development.",
    paymentTermsPaymentMethodsTitle: "Payment Methods",
    paymentTermsPaymentMethodsContent: "MINTWEB3 accepts payments in cryptocurrency and fiat currencies via credit card or bank transfer. Users are responsible for any transaction fees, including blockchain network fees or currency conversion costs.",
    paymentTermsLatePaymentsTitle: "Late Payments",
    paymentTermsLatePaymentsContent: "If payment is not received by the due date, we reserve the right to suspend or terminate the provision of Services. Late payments may also incur a late fee as specified in your agreement.",
    intellectualPropertyOwnershipTitle: "Ownership of Content",
    intellectualPropertyOwnershipContent: "Unless otherwise specified, all materials, content, and services provided by MINTWEB3, including but not limited to software, graphics, text, and intellectual property rights, are owned by MINTWEB3 or its licensors. You may not copy, modify, distribute, or create derivative works based on such materials without our express written permission.",
    intellectualPropertyLicenseTitle: "License to Use",
    intellectualPropertyLicenseContent: "Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for your internal business purposes only.",
    intellectualPropertyUserGeneratedContentTitle: "User-Generated Content",
    intellectualPropertyUserGeneratedContentContent: "You retain ownership of any content you submit to MINTWEB3. By submitting content, you grant MINTWEB3 a worldwide, royalty-free, and non-exclusive license to use, reproduce, distribute, and display your content for the purposes of providing and promoting the Services.",
    confidentialityTitle: "Confidentiality",
    confidentialityContent: "Both parties agree to maintain the confidentiality of proprietary information shared in the course of using the Services. This includes, but is not limited to, technical data, business strategies, client lists, and project details. This clause will remain in effect even after the termination of the contract.",
    complianceLegalComplianceTitle: "Legal Compliance",
    complianceLegalComplianceContent: "MINTWEB3 operates under the legal framework of Hong Kong, where it is headquartered. You are responsible for ensuring that your use of our Services complies with the laws of your jurisdiction.",
    complianceAmlKycTitle: "Anti-Money Laundering (AML) and Know Your Customer (KYC)",
    complianceAmlKycContent: "Depending on the nature of the Services, we may require compliance with AML and KYC regulations. You agree to provide the necessary documentation to verify your identity and business operations when requested.",
    terminationByYouTitle: "By You",
    terminationByYouContent: "You may terminate your account at any time by contacting customer support. However, you will remain responsible for any outstanding fees or charges.",
    terminationByMintweb3Title: "By MINTWEB3",
    terminationByMintweb3Content: "We may terminate or suspend your account or access to Services at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties. Upon termination, all rights granted to you under these Terms will cease.",
    limitationOfLiabilityTitle: "Limitation of Liability",
    limitationOfLiabilityContent: "To the fullest extent permitted by law, MINTWEB3 and its affiliates, partners, and employees will not be liable for any indirect, incidental, punitive, or consequential damages arising out of or in connection with the use or inability to use the Services. This includes, but is not limited to, loss of profits, business interruption, or loss of data.",
    indemnificationTitle: "Indemnification",
    indemnificationContent: "You agree to indemnify, defend, and hold harmless MINTWEB3 and its officers, directors, employees, and agents from and against any claims, damages, liabilities, and expenses arising from:",
    disputeResolutionTitle: "Dispute Resolution",
    disputeResolutionContent: "In the event of a dispute arising from or related to these Terms, the parties agree to first attempt resolution through good-faith negotiations. If negotiations fail, the dispute shall be settled by arbitration in Hong Kong, in accordance with the rules of the Hong Kong International Arbitration Centre (HKIAC). The decision of the arbitrator will be final and binding.",
    governingLawTitle: "Governing Law",
    governingLawContent: "These Terms of Service shall be governed by and construed in accordance with the laws of Hong Kong, without regard to its conflict of law principles.",
    changesToTermsTitle: "Changes to Terms of Service",
    changesToTermsContent: "MINTWEB3 reserves the right to modify these Terms at any time. Users will be notified of any material changes, and continued use of the Services after such changes constitutes acceptance of the revised Terms.",
    contactUsTitle: "Contact Us",
    contactUsContent: "For any questions regarding these Terms, please contact us.",
  },
  userAgreement: {
    title: "User",
    afterTitle: "Agreement",
    cookiePolicy: "MINTWEB3 Cookie Policy",
    effectiveDate: "Effective Date",
    welcomeMessage: "Welcome to MINTWEB3! This User Agreement ('Agreement') governs your access and use of MINTWEB3’s platform, products, and services (collectively referred to as the 'Services'). By registering for an account or using our Services, you agree to comply with and be bound by the terms and conditions set forth in this Agreement.",
    agreementMessage: "If you do not agree with any of the provisions outlined below, please refrain from using our Services.",
    acceptanceOfTerms: "Acceptance of Terms",
    acceptanceDescription: "By creating an account, accessing, or using any part of the MINTWEB3 platform, you agree to the terms outlined in this Agreement. This includes any future amendments or modifications we may implement from time to time. Your continued use of the Services after such changes have been communicated constitutes your acceptance of the new terms.",
    eligibility: "Eligibility",
    eligibilityRequirements: "To use MINTWEB3 Services, you must:",
    eligibilityAge: "Be at least 18 years old or the age of majority in your jurisdiction.",
    eligibilityLegalCapacity: "Have the legal capacity to enter into binding agreements.",
    eligibilityCompliance: "Comply with all applicable laws and regulations, including those related to cryptocurrencies, blockchain technologies, and digital assets.",
    mintweb3Right: "MINTWEB3 reserves the right to refuse or terminate service to any user without notice, particularly those violating this Agreement or applicable laws.",
    accountRegistration: "Account Registration",
    accountRegistrationDescription: "To access certain features of the MINTWEB3 platform, users must register for an account. During registration, you agree to provide:",
    accountRegistrationAccurateInfo: "Accurate and current information.",
    accountRegistrationSecurePassword: "A secure and unique password for your account.",
    accountRegistrationUpdateDetails: "Prompt updates to your account details when necessary.",
    accountResponsibility: "You are solely responsible for maintaining the confidentiality of your account login credentials and for all activities that occur under your account. If you suspect unauthorized access or security breaches, notify MINTWEB3 immediately at [insert support email].",
    servicesProvided: "Services Provided",
    servicesDescription: "MINTWEB3 offers a wide range of Web 3.0 solutions, including but not limited to:",
    service1: "Token creation and smart contract development.",
    service2: "Multi-chain staking solutions.",
    service3: "Web3-powered payment systems.",
    service4: "ICO (Initial Coin Offering) marketing and strategy development.",
    service5: "Integration of wallets such as MetaMask, Trust Wallet, and WalletConnect.",
    servicesModification: "We strive to provide secure and cutting-edge solutions tailored to the decentralized economy. MINTWEB3 reserves the right to modify, enhance, or discontinue any aspect of the Services at any time without prior notice.",
    userResponsibilities: "User Responsibilities",
    userResponsibilitiesDescription: "When using MINTWEB3 Services, you agree to:",
    userResponsibility1: "Use the platform for lawful purposes only.",
    userResponsibility2: "Avoid actions that could harm the platform, other users, or third parties.",
    userResponsibility3: "Comply with all applicable laws, including cryptocurrency regulations.",
    userResponsibility4: "Refrain from uploading, posting, or sharing any unlawful, harmful, or inappropriate content.",
    userProhibitedActions: "You are prohibited from:",
    userProhibitedAction1: "Engaging in fraudulent activities, including phishing, money laundering, or scams.",
    userProhibitedAction2: "Hacking, reverse-engineering, or attempting to compromise the platform's security.",
    userProhibitedAction3: "Impersonating any person or entity or falsely representing your affiliation with a person or entity.",
    intellectualProperty: "Intellectual Property",
    intellectualPropertyDescription: "All content and materials on the MINTWEB3 platform, including but not limited to text, graphics, logos, designs, software, and trademarks, are the property of MINTWEB3 or its licensors and are protected by intellectual property laws.",
    intellectualPropertyRights: "As a user, you retain the rights to any content you submit, post, or upload to the platform. However, by doing so, you grant MINTWEB3 a non-exclusive, worldwide, royalty-free license to use, display, distribute, and promote your content within the scope of our Services.",
    paymentTerms: "Payment Terms",
    paymentDescription: "MINTWEB3 provides its services for a fee, as outlined in the individual service agreements.",
    paymentAgreement: "By using our paid services, you agree to:",
    paymentResponsibility1: "Pay all applicable fees promptly.",
    paymentResponsibility2: "Ensure sufficient funds for transactions.",
    paymentResponsibility3: "Accept responsibility for any associated taxes or fees based on your location.",
    paymentRefundPolicy: "Refunds for any services are not provided unless expressly agreed in writing by MINTWEB3. All payments are final and non-reversible unless there has been a verifiable error in billing.",
    securityAndPrivacy: "Security and Privacy",
    securityDescription: "We take the privacy and security of our users seriously. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.",
    securityRiskAcknowledgment: "While we strive to maintain the highest security standards, no system is completely immune to risks. By using MINTWEB3’s Services, you acknowledge and accept these inherent risks associated with Web 3.0 technologies, including the potential loss of funds due to hacks, unauthorized access, or network failures.",
    limitationOfLiability: "Limitation of Liability",
    limitationDescription: "MINTWEB3 will not be held liable for:",
    limitationLiability1: "Any loss or damages, whether direct, indirect, incidental, or consequential, arising from the use or inability to use our platform.",
    limitationLiability2: "Loss of assets, profits, business opportunities, or data, even if MINTWEB3 has been advised of the possibility of such damages.",
    limitationLiability3: "Errors, delays, or failures in the functionality of blockchain networks, smart contracts, or third-party integrations.",
    indemnityClause: "You agree to indemnify MINTWEB3 and its affiliates, officers, and employees from any claim, loss, or damage resulting from your breach of this Agreement or misuse of the Services.",
    thirdPartyLinks: "Third-Party Links and Services",
    thirdPartyLinksDescription: "The MINTWEB3 platform may contain links to third-party websites, tools, or services. These are provided for your convenience, and MINTWEB3 does not endorse, guarantee, or assume responsibility for any third-party content or services. You acknowledge that your interactions with such third-party services are at your own risk and subject to the terms and conditions of the respective providers.",
    termination: "Termination",
    terminationDescription: "MINTWEB3 reserves the right to terminate or suspend your account and access to the Services at any time, with or without cause, and without prior notice.",
    disputeResolution: "Dispute Resolution",
    disputeResolutionDescription: "In the event of any disputes arising from this Agreement or your use of the Services, you agree to first contact MINTWEB3 to attempt to resolve the issue informally. If a resolution cannot be reached, disputes will be governed by the laws of Hong Kong, and you agree to submit to the exclusive jurisdiction of its courts.",
    modifications: "Modifications",
    modificationsDescription: "MINTWEB3 reserves the right to modify this Agreement at any time. Any changes will be posted on our platform, and your continued use of the Services constitutes acceptance of the revised Agreement."
  },
}
