import axios from 'axios'
import { getLanguageNameFromLocale } from '@/utils/helpers'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BECKEND_URL,
  timeout: 5000
})

axiosInstance.interceptors.request.use(
  (config) => {
    //TODO Pode adicionar headers e etc
    config.headers['Language'] = getLanguageNameFromLocale(localStorage.getItem('lang'))
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    const data = response.data

    if (data.code < 0) {
      return Promise.reject(response)
    }

    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
