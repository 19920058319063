export const fr = {
  general: {
    submit: "Soumettre",
    connect: "Connecter",
    buy: "Acheter",
    buyAndStake: "Acheter et miser",
    buyWithCard: "Acheter avec une carte",
    stake: "Miser",
    stakeNow: "Miser maintenant",
    claimTokens: "Réclamer",
    claimAndStake: "Réclamer et miser",
    claimRewards: "Réclamer des récompenses",
    becomeAnAffiliate: "Devenez un affilié!",
    loading: "Chargement...",
    viewTransaction: "Voir la transaction",
    close: "Fermer",
    copy: "Copier",
    days: "Jour | Jours",
    hours: "Heure | Heures",
    minutes: "Minute | Minutes",
    seconds: "Seconde | Secondes",
    lastRound: "Dernier tour",
    developedBy: "Développé par",
    headerBtn01: "Rejoignez-nous Monde Libre",
    headerBtn02: "Découvrir ces mondes",
    web30: "WEB 3.0",
    multiNetwork: "Multi-réseau",
    socials: "Réseaux sociaux",
  },
  navbar: {
    home: "Accueil",
    presale: "Prévente",
    staking: "Staking",
    airdrop: "Airdrop / Cadeau",
    marketing: "Marketing",
    contact: "Contact",
  },
  footer: {
    tittle01: "Complet Et",
    tittle02: "Innovant",
    tittle03: "Solutions Pour",
    tittle04: "Votre Web 3.0",
    tittle05: "Projet",
    quickLinks: "Liens Rapides",
    legal: "Légal",
    termsOfServices: "Conditions De Service",
    cookiePolicy: "Politique de Cookies",
    userAgreement: "Contrat d'Utilisateur",
    communityGuideline: "Ligne Directrice de la Communauté",
  },
  presale: {
    howToBuy: "Comment acheter",
    presaleOpen: "{token} La prévente est maintenant ouverte",
    stage: "Étape",
    untilPriceIncrease: "Jusqu'à l'augmentation du prix",
    untilPriceIncreaseDescription:
      "Le prix augmentera lorsque le minuteur se termine ou lorsque le USD levé atteint l'objectif.",
    nextPrice: "Prochain prix",
    usdtRaised: "USDT Levé",
    yourPurchased: "Votre {token} acheté",
    yourPurchasedDescription: "Total des tokens achetés avec l'adresse connectée sur la chaîne sélectionnée",
    yourStakeable: "Votre {token} à miser",
    yourStakeableDescription:
      "Total des tokens non miseurs avec l'adresse connectée sur la chaîne sélectionnée. Vous pouvez maintenant miser vos tokens en allant sur la page de staking.",
    yourStaked: "Votre {token} misé",
    yourStakedDescription: "Total des tokens miseurs avec l'adresse connectée sur la chaîne sélectionnée."
  },
  swap: {
    balance: "Solde",
    youPay: "{token} vous payez",
    youReceive: "{token} vous recevez",
    lessThanMinimumError: "La quantité de { token } à acheter doit être supérieure à { min }.",
    exceedsMaxError: "La quantité de { token } à acheter doit être inférieure à { max }.",
    notEnoughBalanceError: "Assurez-vous d'avoir assez de solde pour acheter.",
    needApprovalDescription:
      "Pour acheter {token} avec {usdToken}, vous devez d'abord nous autoriser à accéder au {usdToken} dans votre portefeuille. Vous devrez peut-être vérifier l'application de portefeuille si vous êtes sur mobile.",
    waitingApprovalDescription:
      "L'autorisation est en cours, vous pourrez confirmer la transaction sous peu.",
    needConfirmationDescription:
      "Pour acheter des jetons {tokenSymbol}, veuillez confirmer la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application de portefeuille si vous êtes sur mobile.",
    waitingTransactionDescription:
      "{amount} de jetons seront achetés une fois que la transaction sera confirmée.",
    openingWertModal: "Attendez pendant que l'écran de paiement s'ouvre.",
    waitingWertPayment:
      "Pour acheter {token} avec carte, procédez en remplissant vos informations sur l'écran de paiement.",
    waitingWertConfirmation:
      "{amount} de jetons seront achetés une fois que le fournisseur de paiement confirme votre paiement.",
    successDescription: "{amount} de jetons ont été achetés avec succès!",
    confirmationError:
      "Nous n'avons pas pu confirmer votre transaction, veuillez vérifier si elle est bloquée dans votre portefeuille.",
    errorDescription: "Quelque chose s'est mal passé, veuillez réessayer.",
    startAgain: "Recommencer",
    notEnoughTokens: "Pas assez de {token}? Achetez ou échangez maintenant!",
    buyOrSwapTokens: "Acheter ou échanger des jetons"
  },
  presaleOver: {
    presaleOverTitle: "La prévente de {token} est terminée",
    presaleOverDescription:
      "Attendez que commence l'éclaim pour réclamer et négocier vos jetons {token}. En attendant, vous pouvez mettre en jeu vos jetons achetés et gagner des récompenses.",
    untilClaimStart: "Jusqu'au début de l'éclaim"
  },
  claim: {
    claimOpen: "Le claim de {token} est maintenant ouvert",
    claimOpenDescription: "Vous pouvez maintenant claim et staker votre {token} pour gagner des récompenses.",
    importToken:
      "Importez le token {token} dans votre portefeuille, en utilisant l'adresse ci-dessous, pour voir vos tokens claimés.",
    importTokenDescription:
      "Si vous ne savez pas comment faire, recherchez 'Comment importer des tokens personnalisés' dans le portefeuille que vous utilisez.",
    yourClaimable: "Votre claimable {token}",
    yourClaimableDescription:
      "Total de tokens disponibles pour claim avec l'adresse connectée. Vous pouvez claim vos tokens vers votre portefeuille ou les staker directement.",
    yourStaked: "Votre {token} staké",
    yourStakedDescription: "Total de tokens stakés avec l'adresse connectée.",
    buyNowOnExchange: "Achetez {token} maintenant sur {exchange}",
    stakeNeedConfirmation:
      "Pour staker vos tokens {token}, veuillez confirmer la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela va staker tous vos tokens claimables {token}. Les tokens seront bloqués pendant {lockDays} @.lower:jours jusqu'à ce que vous puissiez les retirer.",
    claimNeedConfirmation:
      "Pour claim vos tokens {token}, veuillez confirmer la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela va claim tous vos tokens claimables {token} sur votre portefeuille connecté.",
    stakeWaitingTransaction: "Les tokens seront stakés une fois la transaction confirmée.",
    claimWaitingTransaction: "Les tokens seront claimés une fois la transaction confirmée.",
    stakeSuccess: "Les tokens ont été stakés avec succès!",
    claimSuccess:
      "Les tokens ont été claimés avec succès! N'oubliez pas, pour voir votre balance {token} dans votre portefeuille, vous devez importer l'adresse du token ci-dessus.",
    confirmationError:
      "Nous n'avons pas pu confirmer votre transaction, veuillez vérifier si elle est bloquée dans votre portefeuille.",
    errorDescription: "Quelque chose a mal tourné, veuillez essayer à nouveau.",
    startAgain: "Commencez à nouveau"
  },
  staking: {
    welcome: "Bienvenue au staking {token}",
    distributionDescription:
      "La distribution des récompenses de token {tokenName} se fera à un rythme de {rewardsPerDay} {tokenSymbol} par jour et sera distribuée sur {duration} an.",
    withdrawStakedTokens: "Retirer les Tokens Stakés",
    stakedBalance: "Solde staké",
    stakeableBalance: "Solde stakeable",
    totalRewards: "Total des récompenses",
    claimNotStarted:
      "La période de réclamation commencera une fois la prévente terminée. Restez à l'écoute pour la conclusion de la prévente pour commencer à réclamer vos récompenses.",
    totalStaked: "Total staké",
    percentageOfPool: "Votre % de pool",
    currentRewards: "Récompenses actuelles",
    estimatedRewards: "Récompenses estimées",
    rewardRateDynamic: "Le taux de récompense est dynamique",
    monthlyRewards: "Mensuel = Récompenses % / 12",
    dailyRewards: "Quotidien = Récompenses % / 365",
    stakeNow: "Stakez votre {token} maintenant",
    claimNow: "Réclamez votre {token} maintenant",
    withdrawNow: "Retirez votre {token} maintenant",
    fillAmount:
      "Remplissez le montant de {token} que vous souhaitez staker ci-dessous et confirmez la transaction pour commencer à gagner des récompenses.",
    amount: "Montant",
    notEnoughBalanceError: "Assurez-vous d'avoir suffisamment de solde pour staker.",
    needApprovalDescription:
      "Pour staker {token}, vous devez d'abord nous autoriser à accéder au {token} dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile.",
    waitingApprovalDescription:
      "L'autorisation est en cours, vous pourrez confirmer la transaction sous peu.",
    stakeNeedConfirmation:
      "Pour staker vos tokens {token}, veuillez confirmer la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela stakera tous vos tokens {token}. Les tokens seront bloqués pour {lockDays} @.lower:jours après que le processus de réclamation soit en direct.",
    claimNeedConfirmation:
      "Pour réclamer vos tokens {token}, veuillez confirmer la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela réclamera toutes vos récompenses {token}.",
    withdrawNeedConfirmation:
      "Pour retirer vos tokens {token}, veuillez confirmer la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application du portefeuille si vous êtes sur mobile. Cela retirera tous vos tokens stakés {token}.",
    stakeWaitingTransaction: "Les tokens seront stakés une fois la transaction confirmée.",
    claimWaitingTransaction: "Les récompenses seront réclamées une fois la transaction confirmée.",
    withdrawWaitingTransaction: "Les tokens seront retirés une fois la transaction confirmée.",
    stakeSuccess: "Les tokens ont été stakés avec succès!",
    claimSuccess: "Les tokens ont été réclamés avec succès!",
    withdrawSuccess: "Les tokens ont été retirés avec succès!",
    confirmationError:
      "Nous n'avons pas pu confirmer votre transaction, veuillez vérifier si elle est bloquée dans votre portefeuille.",
    errorDescription: "Quelque chose a mal tourné, veuillez réessayer.",
    tryAgain: "Réessayer"
  },
  airdrop: {
    claimTitle: "{token} Réclamation Airdrop",
    claimNotStartedDescription:
      "La période de réclamation d'airdrop commencera une fois la prévente terminée. Restez à l'écoute pour le conclusion de la prévente pour commencer à réclamer vos récompenses.",
    claimStartedDescription: "Vous pouvez maintenant réclamer vos {token} airdroppés pour gagner des récompenses.",
    importToken:
      "Importez le jeton {token} dans votre portefeuille, en utilisant l'adresse ci-dessous, pour voir vos jetons réclamés.",
    importTokenDescription:
      "Si vous ne savez pas comment faire, recherchez 'Comment importer des jetons personnalisés' dans le portefeuille que vous utilisez.",
    yourClaimable: "Votre {token} réclamable",
    yourClaimableDescription:
      "Total des jetons reçus pendant l'airdrop disponibles à réclamer avec l'adresse connectée.",
    claimNeedConfirmation:
      "Pour réclamer vos jetons {token}, veuillez confirmer la transaction dans votre portefeuille. Vous devrez peut-être vérifier l'application de portefeuille si vous êtes sur mobile. Cela réclamera tous vos jetons {token} réclamables dans votre portefeuille connecté.",
    claimWaitingTransaction: "Les jetons seront réclamés une fois la transaction confirmée.",
    claimSuccess:
      "Les jetons ont été réclamés avec succès ! N'oubliez pas, pour voir votre solde en {token} dans votre portefeuille, vous devez importer l'adresse du jeton ci-dessus.",
    confirmationError:
      "Nous n'avons pas pu confirmer votre transaction, veuillez vérifier si elle est bloquée dans votre portefeuille.",
    errorDescription: "Quelque chose a mal tourné, veuillez réessayer.",
    startAgain: "Commencer à nouveau"
  },
  affiliate: {
    affiliateName: "Nom d'affilié",
    affiliateNameDescription:
      "Le nom d'affilié sera utilisé pour votre identification et pour générer votre lien d'affiliation",
    parentAffiliateName: "Nom de l'affilié parent",
    affiliateCreated: "Affilié créé avec succès!",
    affiliateLink: "Votre lien d'affiliation",
    affiliateLinkDescription: "Partagez votre lien d'affiliation avec votre communauté pour gagner des récompenses pour vos références.",
    balance: "Solde",
    available: "Disponible",
    withdrawn: "Retiré",
    total: "Total",
    transactions: "Transactions",
    transactionHash: "Hash de transaction",
    transactionAmount: "Montant",
    affiliatePercentage: "Pourcentage d'affiliation",

  },
  status: {
    NEED_APPROVAL: "Besoin d'approbation",
    WAITING_APPROVAL: "En attente de confirmation d'approbation",
    NEED_CONFIRMATION: "Besoin de confirmation",
    WAITING_TRANSACTION: "En attente de confirmation de transaction",
    OPENINIG_WERT_MODAL: "Ouverture de l'écran de paiement",
    WAITING_WERT_PAYMENT: "Paiement en attente",
    WAITING_WERT_CONFIRMATION: "En attente de confirmation de paiement",
    SUCCESS: "Succès",
    ERROR: "Erreur"
  },
  viewIndex: {
    aboutItems01: "Démocratiser l'accès au Web 3.0 en proposant des solutions blockchain innovantes, évolutives et sécurisées.",
    aboutItems02: "Nous faisons le lien entre les entreprises traditionnelles et l'économie décentralisée.",
    aboutItems03: "Nous garantissons une intégration transparente et une valeur maximale pour nos clients.",
    development: "DÉVELOPPEMENT",
    headerSubText: "Le développement Web 3.0 crée des applications décentralisées (dApps) utilisant la blockchain pour des données contrôlées par l'utilisateur et des interactions sécurisées de pair à pair.",
    aboutUs01: "À Propos",
    aboutUs02: "de Nous",
    aboutUsText: "Chez MintWeb3, nous permettons aux entreprises et aux particuliers de bénéficier de solutions Web 3.0 de pointe. De la création de tokens à la gestion et au marketing à grande échelle des ICO, nous sommes là pour vous aider à exploiter le potentiel d'Internet décentralisé. Basés à Hong Kong, leader mondial de la finance et de la technologie, nous proposons une expertise inégalée en matière de blockchain, de crypto-monnaie et de paiements numériques.",
    ourMission: "Notre Mission",
    discoverOurSolutions01: "Découvrir",
    discoverOurSolutions02: "Nos Solutions",
    ourServices01Ttitle: "Développement de Tokens",
    ourServices01Description: "Développement de tokens personnalisés sur des réseaux blockchain comme Ethereum, Binance Smart Chain, Solana, et autres.",
    ourServices02Ttitle: "Développement de Coin",
    ourServices02Description: "Développement de RWA, meme coins, layer-1 et layer-2, IA et autres cryptomonnaies thématiques.",
    ourServices03Ttitle: "Intégration de Wallet",
    ourServices03Description: "Intégration de portefeuilles numériques sécurisés comme MetaMask et Trust Wallet pour la gestion de la crypto-monnaie.",
    ourServices04Ttitle: "Création de Whitepaper",
    ourServices04Description: "Nous brainstormons avec nos experts en blockchain pour déterminer la meilleure façon de présenter votre projet.",
    ourServices05Ttitle: "Solution Multi-Chain de Staking",
    ourServices05Description: "Solutions de staking multi-chaînes, permettant des récompenses pour les détenteurs de tokens.",
    ourServices06Ttitle: "Développement de Smart Contract",
    ourServices06Description: "Développez des smart contracts pour votre token. Nous construisons sur ERC20, BEP2, TRON etc.",
    ourServices07Ttitle: "Page d'atterrissage de l'ICO",
    ourServices07Description: "Conception et développement de l'ICO et des memecoins, avec une intégration complète pour leur lancement.",
    ourServices08Ttitle: "Programme d'affiliation",
    ourServices08Description: "Ajouter un système d'affiliation augmente la portée de votre marque, génère des ventes passives et renforce les partenariats de marketing stratégique.",
    ourServices09Ttitle: "Airdrop et Giveaways",
    ourServices09Description: "Les airdrops et les giveaways augmentent la visibilité de la marque, attirent de nouveaux utilisateurs et encouragent l'engagement, générant une attention positive.",
    ourServices10Ttitle: "Conformité et Audit",
    ourServices10Description: "Conformité réglementaire et audits de sécurité pour garantir la légalité et la sécurité des projets.",
    ourServices11Ttitle: "Consulting & Juridique",
    ourServices11Description: "Nous offrons des conseils pour l'ouverture d'entreprises, les licences, la conformité réglementaire et les audits de smart contracts.",
    whyChoose01: "Pourquoi Choisir",
    whyChoose02: "MintWeb3?",
    whyChooseText: "Chez MintWeb3, nous comprenons que le lancement et la mise à l'échelle d'un projet Web 3.0 nécessite une combinaison unique d'expertise technique, de marketing innovant et de planification stratégique",
    expertiseWeb3: "Expertise dans les technologies Web3",
    expertise01: "Stratégies Marketing Éprouvées pour les ICOs",
    expertise02: "Solutions Sur Mesure",
    expertise03: "Consultation pour les fintechs",
    expertise04: "Portée et Réseau Mondial",
    expertise05: "Innovation de Pointe",
  },
  viewAirdrop: {
    headerTitle: "AIRDROP / CADEAUX",
    headerDescription: "Activez des campagnes transparentes où les utilisateurs rejoignent les airdrops, accomplissent des tâches, réclament des récompenses et développent la communauté.",
    airdropTitle01: "Alimenté par Web3",
    airdropTitle02: "Airdrops",
    airdropDescription01: "Notre Widget Airdrop offre une solution transparente et sécurisée pour distribuer des tokens à votre communauté. Les utilisateurs peuvent facilement connecter leurs porte-monnaie, que ce soit MetaMask, Trust Wallet, ou d'autres, pour réclamer leurs récompenses airdrop directement via le widget. Cette intégration conviviale garantit que le processus d'airdrop est fluide et efficace, réduisant les frictions pour les participants.",
    airdropDescription02: "Une fois connectés, les utilisateurs peuvent rapidement réclamer leurs tokens sans naviguer dans des procédures complexes, favorisant une participation et un engagement plus importants dans votre campagne. Le widget est entièrement personnalisable pour correspondre à votre image de marque et à votre tokenomics, offrant une expérience cohérente sur toutes les plateformes. Que ce soit pour des événements promotionnels, la construction de la communauté ou le lancement de tokens, notre widget airdrop aide à stimuler l'acquisition d'utilisateurs et à favoriser une croissance à long terme.",
    airdropPlayButton: "Jouez à notre jeu sur Telegram",
    airdropServices01Title: "Airdrop Gaming",
    airdropServices01Description: "Notre solution de jeu Airdrop Telegram offre une manière interactive et engageante de distribuer des tokens à travers un jeu Click-to-Earn. Les utilisateurs peuvent participer directement au sein de votre groupe Telegram en accomplissant des tâches simples, comme cliquer sur des liens, répondre à des questions ou effectuer des actions qui débloquent des récompenses airdrop. Cette approche ludique non seulement motive davantage la participation, mais stimule également l’interaction et l’émulation au sein de la communauté. C’est un outil efficace pour faire croître votre communauté Telegram tout en récompensant les utilisateurs pour leur engagement. La solution est facile à mettre en place et s'intègre parfaitement à Telegram, offrant une expérience amusante et enrichissante pour tous les participants.",
    airdropServices01Item01Title: "Engagement accru : ",
    airdropServices01Item01Description: "Gamifie le processus d'airdrop, encourageant plus de participation.",
    airdropServices01Item02Title: "Intégration facile avec Telegram : ",
    airdropServices01Item02Description: "Se connecte sans problème avec votre communauté Telegram existante.",
    airdropServices01Item03Title: "Stimule la croissance de la communauté : ",
    airdropServices01Item03Description: "Attire de nouveaux utilisateurs et fidélise les participants existants avec des récompenses amusantes.",
    giveawayTitle01: "Alimenté par Web3",
    giveawayTitle02: "Cadeaux",
    giveawayDescription: "Notre widget Giveaway offre une manière facile et engageante pour les projets d'organiser des cadeaux de tokens et de récompenser leur communauté. Entièrement personnalisable, le widget peut être intégré à votre site web ou plateforme, permettant aux utilisateurs de participer en accomplissant des tâches simples comme suivre des comptes sur les réseaux sociaux, partager du contenu ou recommander des amis. Les participants peuvent suivre leurs progrès et leurs inscriptions en temps réel, rendant le processus transparent et interactif. Le widget est conçu pour une utilisation facile, garantissant une expérience fluide pour les administrateurs et les utilisateurs. Il contribue à augmenter la visibilité de la marque, à développer votre communauté, et à inciter à la participation des utilisateurs. Que ce soit pour des campagnes marketing, des promotions ou la construction de la communauté, notre Widget Giveaway est l'outil parfait pour booster l'engagement et attirer de nouveaux utilisateurs.",
    giveawayKeyBenefits: "Principaux avantages",
    giveawayWidget: "Mint Web3 Giveaway",
    giveawayKeyBenefits01: "Encourage la participation active en récompensant les utilisateurs pour l'accomplissement de tâches, favorisant des liens plus profonds et la fidélité au sein de votre communauté.",
    giveawayKeyBenefits02: "Intégrez facilement le widget de cadeau à votre plateforme, avec des options de personnalisation complètes pour s'aligner avec votre image de marque et vos objectifs.",
    giveawayKeyBenefits03: "Les participants peuvent suivre leurs progrès en temps réel, garantissant la transparence et la confiance tout au long du processus de cadeau.",
  },
  viewContact: {
    header01: "Contact",
    header02: "Nous",
    description: "Contactez-nous pour toute demande, commentaire ou support. Nous sommes là pour vous aider!",
    schedule: "Planifiez une réunion ou contactez-nous!",
  },
  viewMarketing: {
    headerTitle: "MARKETING",
    headerDescription: "Le marketing Web 3.0 utilise des plateformes décentralisées, des NFT et la blockchain pour créer des campagnes transparentes, personnalisées et axées sur la communauté.",
    web3MarketingHeader01: "Créer un",
    web3MarketingHeader02: "Communauté",
    web3MarketingDescription01: "Dans le monde trépidant du Web3, il ne suffit pas de développer un projet, vous avez besoin d'une stratégie marketing complète pour capter l'attention, établir la confiance et stimuler l'engagement. Chez MINTWEB3, nous fournissons une gamme complète de services de marketing Web3 conçus pour garantir le succès de votre projet, que vous lanciez une ICO, gériez une prévente de token, ou construisiez une communauté autour de votre application décentralisée (dApp).",
    web3MarketingDescription02: "Notre équipe allie une connaissance approfondie de l'industrie à des techniques de marketing innovantes pour donner à votre projet Web3 l'exposition et la crédibilité dont il a besoin pour prospérer sur le marché concurrentiel d'aujourd'hui.",
    web3ServicesHeader01: "Marketing",
    web3ServicesHeader02: "Services",
    marketing01Title: "Optimisation du Livre Blanc",
    marketing01Description: "Le livre blanc est le pilier de tout projet Web3, servant de document détaillé qui explique la technologie, la vision et le modèle d'affaires derrière votre projet. Chez MintWeb3, nous vous aidons à développer ou à optimiser votre livre blanc pour communiquer clairement les objectifs et la valeur de votre projet aux investisseurs et à la communauté.",
    marketing01Item01Title: "Clarté et Précision: ",
    marketing01Item01Description: "Nous nous assurons que votre livre blanc est facile à comprendre tout en couvrant les détails techniques nécessaires.",
    marketing01Item02Title: "Récit captivant: ",
    marketing01Item02Description: "Nous utilisons un langage clair et concis pour attirer à la fois les publics techniques et non techniques, présentant votre projet d'une manière qui suscite la confiance.",
    marketing02Title: "Design de la Page d'Atterrissage de l'ICO",
    marketing02Description: "Une page d'atterrissage bien conçue est essentielle pour capter l'intérêt des investisseurs lors d'une ICO ou d'une prévente. Notre équipe crée des pages d'atterrissage à haut taux de conversion qui mettent en valeur les avantages de votre projet, démontrent la crédibilité de votre équipe, et facilitent la participation des visiteurs.",
    marketing02Item01Title: "Design Convivial: ",
    marketing02Item01Description: "Nos pages d'atterrissage sont conçues pour être visuellement attrayantes et faciles à naviguer, avec des appels clairs à l'action.",
    marketing02Item02Title: "Optimisé pour les Conversions: ",
    marketing02Item02Description: "Nous nous concentrons sur la création de pages d'atterrissage qui favorisent les inscriptions, les achats de tokens, et l'engagement communautaire.",
    marketing03Title: "Gestion de l'ICO",
    marketing03Description: "La gestion d'une ICO nécessite une planification et une exécution minutieuses. MinWeb3 fournit des services complets de gestion de l'ICO, vous aidant à stratégiser et à exécuter tous les aspects de votre vente de tokens, du marketing et de la sensibilisation des investisseurs à la gestion du processus de collecte de fonds.",
    marketing03Item01Title: "Stratégie de Financement: ",
    marketing03Item01Description: "Nous vous aidons à développer une approche stratégique de votre ICO, en fixant des objectifs réalistes et en élaborant un modèle de tokenomics efficace.",
    marketing03Item02Title: "Support de bout en bout: ",
    marketing03Item02Description: "Nous nous occupons de tout, depuis la prévente jusqu'à la distribution des tokens, en assurant un processus d'ICO fluide et conforme.",
    marketing04Title: "Assistance à l'Inscription en Bourse",
    marketing04Description: "L'inscription de votre token sur les principales bourses de cryptomonnaies est la clé de son succès. Nous vous aidons à inscrire votre token sur les meilleures bourses, en assurant la liquidité et la visibilité de votre token une fois l'ICO terminée.",
    marketing04Item01Title: "Recherche sur les Échanges: ",
    marketing04Item01Description: "Nous identifions les meilleures bourses pour votre token en fonction de votre marché cible et de votre tokenomie.",
    marketing04Item02Title: "Demande et Négociation: ",
    marketing04Item02Description: "Nous nous occupons du processus de demande et négocions en votre nom pour obtenir des conditions d'inscription favorables.",
    marketing05Title: "Inscription de l'ICO sur les Plateformes Populaires",
    marketing05Description: "En plus des inscriptions en bourse, nous aidons à inscrire votre ICO sur des plateformes de suivi et de cotation d'ICO bien connues, donnant à votre projet une visibilité auprès d'un large public d'investisseurs et d'enthousiastes.",
    marketing05Item01Title: "Visibilité: ",
    marketing05Item01Description: "Nous vous aidons à développer une approche stratégique de votre ICO, en fixant des objectifs réalistes et en élaborant un modèle de tokenomie efficace.",
    marketing05Item02Title: "Confiance et Engagement: ",
    marketing05Item02Description: "Être listé sur des plateformes respectées aide à établir la confiance avec les investisseurs potentiels et les communautés.",
    marketing06Title: "Gestion de la Communauté",
    marketing06Description: "Construire et gérer une communauté dynamique et engagée est essentiel pour le succès à long terme de tout projet Web3. Nous vous aidons à gérer les communautés sur des plateformes clés comme Telegram, Tik Tok, X, Instagram et Discord, en veillant à ce que vos supporters restent informés, engagés, et excités par votre projet.",
    marketing06Item01Title: "Croissance de la Communauté: ",
    marketing06Item01Description: "Nous aidons à développer votre communauté grâce à un engagement actif, des concours et la création de contenu.",
    marketing06Item02Title: "Modération 24/7: ",
    marketing06Item02Description: "Nous nous occupons du processus de demande et négocions en votre nom pour obtenir des conditions d'inscription favorables.",
    marketing07Title: "Audit de Site Web et SEO",
    marketing07Description: "Le site web de votre projet est souvent le premier point de contact pour les investisseurs et les utilisateurs potentiels. Nous effectuons un audit détaillé de votre site web pour s'assurer qu'il est optimisé pour les moteurs de recherche (SEO) et offre une expérience utilisateur fluide.",
    marketing07Item01Title: "Optimisation SEO: ",
    marketing07Item01Description: "Nous mettons en œuvre les meilleures pratiques de SEO pour assurer que votre site web se classe plus haut dans les moteurs de recherche et attire le trafic organique.",
    marketing07Item02Title: "Amélioration de l'Expérience Utilisateur (UX): ",
    marketing07Item02Description: "Nous évaluons et améliorons l'usabilité de votre site web, le rendant plus facile à naviguer et à prendre des mesures.",
    marketing08Title: "Rédaction pour les Projets Web3",
    marketing08Description: "Le contenu est la clé pour construire un récit fort autour de votre projet Web3. Notre équipe de rédacteurs qualifiés crée du contenu de haute qualité pour chaque étape de votre projet, des livres blancs et des articles de blog aux mises à jour sur les médias sociaux et les communiqués de presse.",
    marketing08Item01Title: "Contenu Engageant: ",
    marketing08Item01Description: "Nous rédigeons des contenus clairs et percutants qui communiquent la valeur de votre projet à un large public.",
    marketing08Item02Title: "Consistence sur tous les Canaux: ",
    marketing08Item02Description: "De votre site web à vos comptes de médias sociaux, nous assurons un message et un ton cohérents sur toutes les plateformes.",
    marketing09Title: "Création de Contenu Graphique et Vidéo",
    marketing09Description: "Le contenu visuel est essentiel pour capter l'attention et véhiculer rapidement des idées complexes. Notre équipe créative conçoit des graphiques, des vidéos et des animations qui font ressortir votre projet et communiquent efficacement votre vision.",
    marketing09Item01Title: "Vidéos Explicatives: ",
    marketing09Item01Description: "Nous créons des vidéos explicatives engageantes qui décomposent la technologie et les avantages de votre projet.",
    marketing09Item02Title: "Design Graphique: ",
    marketing09Item02Description: "De la création de logo aux bannières publicitaires, nous concevons des assets visuels qui renforcent la visibilité et l'attrait de votre marque.",
    marketing10Title: "Jeux d'Autocollants et GIFs",
    marketing10Description: "Augmentez l'engagement sur les réseaux sociaux et les plateformes communautaires avec des jeux d'autocollants et des GIFs amusants et marqués qui résonnent avec votre public. Ces assets sont parfaits pour augmenter la visibilité lors des airdrops, des cadeaux et des événements communautaires.",
    marketing10Item01Title: "Autocollants Personnalisés: ",
    marketing10Item01Description: "Nous créons des ensembles d'autocollants uniques qui reflètent la personnalité et la marque de votre projet.",
    marketing10Item02Title: "GIFs Promotionnels: ",
    marketing10Item02Description: "GIFs partageables qui peuvent être utilisés sur les plateformes de médias sociaux pour augmenter la visibilité de votre projet.",
    marketing11Title: "Bannières Promotionnelles",
    marketing11Description: "Notre équipe de design crée des bannières accrocheuses pour une utilisation sur les sites web, les blogs et les réseaux sociaux. Ces bannières sont conçues pour promouvoir des aspects clés de votre projet, tels que les événements à venir, les ventes de tokens, ou les nouvelles fonctionnalités.",
    marketing11Item01Title: "Design de Haute Qualité: ",
    marketing11Item01Description: "Nos bannières sont visuellement saisissantes et conçues pour capter l'attention.",
    marketing11Item02Title: "Message Ciblé: ",
    marketing11Item02Description: "Chaque bannière communique un message clair et actionnable qui stimule l'intérêt et l'engagement.",
    marketing12Title: "Marketing d'Influence et Viral",
    marketing12Description: "Exploitez la puissance des influenceurs et du contenu viral pour augmenter la portée de votre projet. Nous nous associions à des influenceurs crypto de premier plan et mettons en œuvre des campagnes de marketing viral qui suscitent un buzz autour de votre projet.",
    marketing12Item01Title: "Collaborations avec des Influenceurs: ",
    marketing12Item01Description: "Nous vous mettons en relation avec des influenceurs leaders dans l'espace crypto pour promouvoir votre projet auprès de leur audience.",
    marketing12Item02Title: "Campagnes de Marketing Viral: ",
    marketing12Item02Description: "Nous créons du contenu conçu pour devenir viral, générant une exposition maximale et un engagement intensif.",
  },
  viewPayments: {
    headerTitle: "PRÉVENTE",
    headerDescription: "La boîte à outils Crypto de MintWeb simplifie les lancements avec des fonctionnalités automatisées, la sécurité et des solutions personnalisables pour des préventes réussies.",
    paymentsTitle01: "Web3-Powered",
    paymentsTitle02: "Paiements",
    paymentsDescription01: "Le widget de paiement Web3 de MintWeb est un outil puissant conçu pour rationaliser les processus de prévente pour les projets de jetons. Avec une prise en charge multidevises complète, les utilisateurs peuvent facilement payer dans différentes cryptomonnaies, élargissant ainsi la portée de votre projet à un public mondial. La fonctionnalité d'échange intégrée permet aux investisseurs de convertir sans problème les actifs, facilitant la participation à la prévente, quel que soit leur portefeuille de jetons.",
    paymentsDescription02: "Notre solution comprend également des fonctionnalités de marketing d'affiliation intégrées, permettant aux projets de récompenser les utilisateurs pour avoir généré du trafic et attiré de nouveaux participants. Cela incite à la croissance et à l'engagement de la communauté tout en stimulant le succès de la prévente. Le widget donne aux projets un contrôle total sur les tours de prévente, permettant des ajustements dynamiques et offrant une expérience personnalisable pour s'adapter à différentes stratégies de collecte de fonds.",
    paymentsDescription03: "De plus, le widget de paiement Web3 de MintWeb prend en charge les paiements par carte, supprimant les obstacles pour les non-initiés à la crypto pour participer aux ventes de jetons. En simplifiant le processus de paiement, vous assurez que n'importe qui peut rejoindre, quel que soit leur expérience avec les cryptomonnaies.",
    paymentsDescription04: "Avec une sécurité robuste, une intégration transparente et des fonctionnalités conviviales, le widget de paiement Web3 de MintWeb est la solution tout-en-un pour optimiser votre prévente, augmenter l'accessibilité et stimuler la croissance du projet. C'est l'outil parfait pour toute initiative Web3 cherchant à maximiser l'efficacité et l'inclusivité.",
    multiWalletTittle01: "Multi-Portefeuilles",
    multiWalletTittle02: "Intégration",
    multiWalletDescription: "L'intégration multi-portefeuilles de MintWeb offre aux utilisateurs la flexibilité de connecter et de gérer plusieurs portefeuilles pendant les préventes, y compris des options populaires comme MetaMask, Trust Wallet et d'autres. Cette intégration transparente garantit que les participants peuvent facilement changer de portefeuille, optimisant le processus de transaction. Avec cette fonctionnalité, nous améliorons l'accessibilité pour un public plus large, facilitant la participation aux préventes tant pour les utilisateurs avertis en crypto que pour les novices.",
    multiWalletBenefitsTitle: "Avantages clés",
    multiWalletBenefits01: "Gestion simplifiée du portefeuille;",
    multiWalletBenefits02: "Compatibilité large du portefeuille;",
    multiWalletBenefits03: "Accessibilité utilisateur améliorée;",
    coinLaunchTittle01: "Lancement de pièce",
    coinLaunchTittle02: "Boite à outils",
    coinLaunchDescription01: "La boîte à outils de lancement de pièces de MintWeb est spécialement conçue pour le lancement de pièces de monnaie, de jetons soutenus par des actifs réels (RWA) et plus encore. Elle simplifie la création de token, la gestion de la prévente et le déploiement de contrat intelligent avec des fonctionnalités adaptées à des cas d'utilisation uniques. Que vous lanciez une monnaie    divertissante ou un jeton sérieux soutenu par des RWA, notre boîte à outils garantit une exécution sécurisée et efficace.",
    coinLaunchDescription02: "Avec des options pour la distribution, le suivi des investisseurs et la scalabilité, MintWeb vous aide à concrétiser votre vision tout en maintenant la transparence et la sécurité.",
    coinLaunchItems01: "Recevoir des pièces",
    coinLaunchItems02: "Intégration d'achat de pièces",
    coinLaunchItems03: "Staking Multichain",
    coinLaunchItems04: "Développement de contrats intelligents",
    coinLaunchItems05: "Réclamer un jeton",
    coinLaunchItems06: "Création de Whitepaper",
  },
  viewStaking: {
    headerTitle: "STAKING",
    headerDescription: "Nous proposons des modèles personnalisables, sécurisés, multi-chaîne, flexibles, pour stimuler l'engagement de la communauté et la croissance de la valeur des jetons.",
    stakingAdvantagesTitle01: "Staking",
    stakingAdvantagesTitle02: "Avantages",
    stakingAdvantages01Title: "Caractéristiques Principales",
    stakingAdvantages01Description: "Les services de staking de Mint Web3 offrent une solution sécurisée et personnalisable pour les projets de jetons qui cherchent à augmenter l'engagement des utilisateurs et à inciter à la détention à long terme. Notre widget de staking peut être facilement intégré à votre plateforme, offrant une expérience transparente pour les utilisateurs pour staker sur plusieurs réseaux blockchain. Le widget est entièrement personnalisable, vous permettant de l'ajuster pour correspondre à votre marque et à votre tokenomics. En offrant des modèles de staking flexibles comme le staking de jeton unique et le staking de pool de liquidités, MintWeb3 aide à créer un écosystème qui correspond à vos objectifs spécifiques et à vos structures de récompense.",
    stakingAdvantages01Item01Title: "Staking Multi-Chaîne: ",
    stakingAdvantages01Item01Description: "Staker sur différents réseaux blockchain pour une portée plus large.",
    stakingAdvantages01Item02Title: "Widget personnalisable: ",
    stakingAdvantages01Item02Description: "Adaptez l'interface de staking à votre image de marque et à votre tokenomics.",
    stakingAdvantages01Item03Title: "Interface conviviale: ",
    stakingAdvantages01Item03Description: "Intégration transparente pour une expérience de staking intuitive.",
    stakingAdvantages02Title: "Avantages Clés",
    stakingAdvantages02Description: "En réduisant la quantité de jetons en circulation grâce au staking, les projets peuvent créer une rareté, ce qui peut avoir un impact positif sur la valeur des jetons. Cela favorise une communauté plus engagée et fidèle tout en soutenant la croissance à long terme de votre écosystème. Nous utilisons des protocoles de cryptage leader de l'industrie pour garantir la sécurité de tous les actifs stakés, offrant une tranquillité d'esprit aux participants.",
    stakingAdvantages02Item01Title: "Sécurité robuste: ",
    stakingAdvantages02Item01Description: "Cryptage avancé pour protéger les actifs stakés.",
    stakingAdvantages02Item02Title: "Augmentation de la valeur des tokens : ",
    stakingAdvantages02Item02Description: "La réduction de l'offre en circulation grâce au staking peut renforcer la rareté des jetons.",
    stakingAdvantages02Item03Title: "Engagement communautaire: ",
    stakingAdvantages02Item03Description: "Inciter les détenteurs de tokens conduit à une plus grande fidélité et participation.",
  },
  community: {
    guidelines: "Communauté",
    guidelinesSubtitle: "Directives",
    title: "Directives de la communauté MINTWEB3",
    welcomeMessage: "Bienvenue dans la communauté MINTWEB3!",
    purposeMessage: "Chez MINTWEB3, nous visons à construire un environnement florissant, inclusif et innovant qui permet aux individus et aux entreprises d'adopter les technologies Web 3.0.",
    participationMessage: "En participant à la communauté MINTWEB3, que ce soit sur notre plateforme, les médias sociaux ou tout forum associé, vous acceptez de respecter les directives suivantes.",
    coreValuesTitle: "Valeurs fondamentales",
    coreValuesDescription: "Nous attendons de tous les membres de la communauté qu'ils respectent les valeurs fondamentales suivantes:",
    coreValuesRespect: "Respect : Traitez tous les membres avec dignité et respect. Les désaccords sont naturels, mais la civilité est obligatoire.",
    coreValuesCollaboration: "Collaboration : Soutenez-vous mutuellement en partageant des connaissances, des compétences et des retours constructifs.",
    coreValuesIntegrity: "Intégrité : Préservez l'honnêteté et la transparence dans toutes les interactions.",
    coreValuesInnovation: "Innovation : Encouragez et célébrez la créativité, que ce soit sous forme d'idées, de contenu ou de solutions liées au Web 3.0.",
    respectfulCommunicationTitle: "Communication respectueuse et inclusive",
    respectfulCommunicationDescription: "Nous nous engageons à maintenir une communauté inclusive où toutes les voix peuvent être entendues. Cela signifie :",
    respectfulCommunicationNoHarassment: "Tolérance zéro pour le harcèlement : Le harcèlement, les abus, l'intimidation ou les menaces de quelque nature que ce soit ne seront pas tolérés.",
    respectfulCommunicationNoHateSpeech: "Pas de discours de haine : Le contenu ou les comportements qui promeuvent ou incitent à la violence, à la haine ou à la discrimination sont strictement interdits.",
    respectfulCommunicationConstructiveCriticism: "Seules les critiques constructives : Les retours doivent être respectueux et axés sur les solutions, pas sur les attaques personnelles.",
    prohibitedConductTitle: "Comportement interdit",
    prohibitedConductDescription: "Pour maintenir un environnement positif, les comportements suivants sont interdits:",
    prohibitedConductNoSpam: "Spam et promotion non sollicitée: Le contenu non pertinent ou répétitif n'est pas autorisé.",
    prohibitedConductNoImpersonation: "Usurpation d'identité: Il est interdit de se faire passer pour le personnel de MINTWEB3, les modérateurs ou d'autres utilisateurs.",
    prohibitedConductNoIllegalActivities: "Activités illégales: Le contenu qui promeut des activités illégales ne sera pas toléré.",
    prohibitedConductNoMaliciousBehavior: "Comportement malveillant: Ne perturbez pas la communauté par des piratages, des hameçonnages ou des logiciels malveillants.",
    intellectualPropertyTitle: "Protection de la propriété intellectuelle",
    intellectualPropertyDescription: "Nous respectons la propriété intellectuelle (PI) des autres et attendons que les membres de notre communauté en fassent de même:",
    intellectualPropertyNoCopyright: "Pas d'infraction au droit d'auteur: Ne postez pas de contenu qui enfreint les droits d'auteur ou les marques déposées.",
    intellectualPropertyOriginalContent: "Contenu original: Assurez-vous d'avoir la permission de partager le contenu ou que vous en possédez les droits.",
    intellectualPropertyAttribution: "Attribution: Donnez le crédit approprié aux auteurs et créateurs originaux.",
    securityPrivacyTitle: "Sécurité et Confidentialité",
    securityPrivacyDescription: "Votre sécurité et votre confidentialité sont nos priorités. Veuillez suivre ces pratiques:",
    securityPrivacyNoPersonalInfo: "Ne partagez pas d'informations personnelles : Évitez de partager des informations personnelles sensibles publiquement.",
    securityPrivacyProtectAccount: "Protégez votre compte : Utilisez des mots de passe forts et l'authentification à deux facteurs (2FA).",
    securityPrivacyReportIssues: "Signalez les problèmes de sécurité : Signalez immédiatement toute vulnérabilité à notre équipe d'assistance.",
    contentStandardsTitle: "Normes de contenu",
    contentStandardsDescription: "Pour que la communauté reste utile, pertinente et professionnelle, suivez ces normes de contenu:",
    contentStandardsRelevance: "Pertinence: Assurez-vous que toutes les contributions sont liées au Web 3.0, à la blockchain ou à la finance décentralisée.",
    contentStandardsClarityAccuracy: "Clarté et précision: Fournissez des informations claires, concises et précises. Les fausses informations ne seront pas tolérées.",
    contentStandardsNoInappropriateContent: "Pas de contenu NSFW ou inapproprié : Évitez de partager du contenu sexuellement explicite ou autre contenu inapproprié.",
    moderationEnforcementTitle: "Modération et exécution",
    moderationEnforcementDescription: "Nous prenons les violations au sérieux et nos modérateurs appliquent ces directives:",
    moderationEnforcementWarnings: "Avertissements: Les violations mineures peuvent entraîner un avertissement.",
    moderationEnforcementContentRemoval: "Suppression de contenu: Les publications qui enfreignent les directives peuvent être supprimées.",
    moderationEnforcementSuspensionBan: "Suspension ou ban: Les violations graves peuvent entraîner une interdiction.",
    moderationEnforcementAppeals: "Appels: Les utilisateurs peuvent faire appel s'ils croient que la modération était injuste.",
    reportingViolationsTitle: "Signalement des violations",
    reportingViolationsDescription: "Aidez-nous à garder la communauté en sécurité en signalant les violations. Les rapports sont confidentiels et les représailles ne sont pas tolérées.",
    thirdPartyLinksTitle: "Liens et contenu de tiers",
    thirdPartyLinksDescription: "MINTWEB3 n'est pas responsable du contenu de tiers lié dans la communauté. Utilisez les liens externes à votre propre discrétion.",
    updatesTitle: "Mises à jour des directives",
    updatesDescription: "Nous pouvons mettre à jour ces directives au besoin. La participation après les mises à jour indique l'acceptation des nouvelles règles.",
    contactTitle: "Contactez-nous",
    contactDescription: "Si vous avez des questions, n'hésitez pas à nous contacter.",
  },
  cookiePolicy: {
    titleCookie: "Cookie",
    titlePolicy: "Politique",
    titleFull: "Politique en matière de cookies",
    introHeader: "Politique en matière de cookies de MINT WEB3",
    introEffectiveDate: "Date d'effet: 18/10/2024",
    introParagraph: "Chez MINTWEB3, nous valorisons votre vie privée et nous nous efforçons d'être transparents sur la façon dont nous collectons et utilisons les données liées à votre expérience en ligne. Cette politique en matière de cookies explique ce que sont les cookies, comment nous les utilisons et vos options concernant leur utilisation sur notre plate-forme.",
    whatAreCookiesTitle: "Qu'est-ce que les cookies?",
    whatAreCookiesDescription: "Les cookies sont de petits fichiers texte qui sont placés sur votre ordinateur ou appareil mobile lorsque vous visitez des sites Web. Les cookies permettent aux sites Web de reconnaître votre appareil, d'améliorer votre expérience utilisateur et de stocker des préférences ou d'autres informations pour de futures visites.",
    whatAreCookiesTypesSessionCookies: "Cookies de session : cookies temporaires qui expirent lorsque vous fermez votre navigateur.",
    whatAreCookiesTypesPersistentCookies: "Cookies persistants : Ces cookies restent sur votre appareil pendant une période définie ou jusqu'à ce que vous les supprimiez.",
    whatAreCookiesTypesFirstPartyCookies: "Cookies tiers : Ces cookies sont placés par le site Web que vous visitez.",
    whatAreCookiesTypesThirdPartyCookies: "Cookies tiers : Ces cookies sont placés par des tiers (tels que des plateformes d'analyse ou de publicité) pour suivre votre activité en ligne sur différents sites Web.",
    whyUseCookiesTitle: "Pourquoi utilisons-nous des cookies ?",
    whyUseCookiesDescription: "MINTWEB3 utilise des cookies pour améliorer l'expérience utilisateur sur notre plate-forme. Plus précisément, nous utilisons des cookies aux fins suivantes :",
    whyUseCookiesPurposesEssentialCookiesTitle: "Cookies essentiels",
    whyUseCookiesPurposesEssentialCookiesDescription: "Ces cookies sont essentiels pour le fonctionnement de notre site Web et de nos services. Ils permettent des fonctions de base telles que la navigation entre les pages, l'accès aux zones sécurisées du site et garantissent le bon fonctionnement de nos services.",
    whyUseCookiesPurposesEssentialCookiesExample: "Exemple : cookies d'authentification pour vous garder connecté pendant votre session.",
    whyUseCookiesPurposesPerformanceCookiesTitle: "Cookies de performance et d'analyse",
    whyUseCookiesPurposesPerformanceCookiesDescription: "Les cookies de performance nous aident à analyser comment les visiteurs interagissent avec notre plate-forme. Nous utilisons ces données pour améliorer les performances du site Web, résoudre les problèmes techniques et optimiser l'expérience utilisateur.",
    whyUseCookiesPurposesPerformanceCookiesExample: "Exemple : Les cookies Google Analytics qui collectent des données sur le comportement des utilisateurs, y compris les pages visitées, le temps passé sur les pages et la source du trafic.",
    whyUseCookiesPurposesFunctionalityCookiesTitle: "Cookies de fonctionnalité",
    whyUseCookiesPurposesFunctionalityCookiesDescription: "Ces cookies permettent au site Web de se souvenir des choix que vous avez faits par le passé, comme vos préférences linguistiques, votre emplacement, ou d'autres paramètres personnalisés, améliorant l'expérience utilisateur en adaptant la plate-forme à vos besoins.",
    whyUseCookiesPurposesFunctionalityCookiesExample: "Exemple : Rappel de vos paramètres de langue préférés.",
    whyUseCookiesPurposesMarketingCookiesTitle: "Cookies de marketing et publicité",
    whyUseCookiesPurposesMarketingCookiesDescription: "Les cookies de marketing sont utilisés pour suivre les visiteurs à travers les sites Web. Leur but est d'afficher des publicités qui sont pertinentes et engageantes pour l'utilisateur individuel. Ces cookies peuvent être placés par nous ou par des partenaires tiers (tels que des réseaux publicitaires).",
    whyUseCookiesPurposesMarketingCookiesExample: "Exemple : Cookies qui suivent l'engagement avec nos campagnes publicitaires et nous aident à diffuser des publicités ciblées.",
    whyUseCookiesPurposesSocialMediaCookiesTitle: "Cookies des réseaux sociaux",
    whyUseCookiesPurposesSocialMediaCookiesDescription: "Ces cookies vous permettent de partager notre contenu sur des plateformes de médias sociaux comme Facebook, Twitter et LinkedIn, ou d'interagir avec du contenu intégré de ces services. Ces cookies peuvent suivre votre activité à travers les sites Web.",
    whyUseCookiesPurposesSocialMediaCookiesExample: "Exemple : Boutons de partage sur les articles de blog ou les pages de produits qui s'intègrent avec les réseaux sociaux.",
    cookieTypesTitle: "Quels types de cookies utilisons-nous?",
    cookieTypesDescription: "Voici un résumé des types de cookies utilisés sur notre plate-forme :",
    cookieTypesTableHeadersCookieType: "Type de cookie",
    cookieTypesTableHeadersPurpose: "But",
    cookieTypesTableHeadersDuration: "Durée",
    cookieTypesRowsStrictlyNecessaryType: "Cookies strictement nécessaires",
    cookieTypesRowsStrictlyNecessaryPurpose: "Nécessaire pour les fonctions essentielles du site Web, comme la connexion et la sécurisation de la plate-forme.",
    cookieTypesRowsStrictlyNecessaryDuration: "Session (Temporaire)",
    cookieTypesRowsPerformanceType: "Cookies de performance",
    cookieTypesRowsPerformancePurpose: "Analyse l'activité des utilisateurs pour améliorer les performances du site et l'expérience utilisateur.",
    cookieTypesRowsPerformanceDuration: "Persistant (Jusqu'à 2 ans)",
    cookieTypesRowsFunctionalityType: "Cookies fonctionnels",
    cookieTypesRowsFunctionalityPurpose: "Stocke les préférences des utilisateurs comme la langue et l'emplacement pour une expérience plus personnalisée.",
    cookieTypesRowsFunctionalityDuration: "Persistant (1 an)",
    cookieTypesRowsMarketingType: "Cookies de ciblage/marketing",
    cookieTypesRowsMarketingPurpose: "Suit l'activité des utilisateurs à des fins publicitaires et personnalise la diffusion des publicités sur les plateformes.",
    cookieTypesRowsMarketingDuration: "Persistant (Jusqu'à 6 mois)",
    cookieTypesRowsSocialType: "Cookies des médias sociaux",
    cookieTypesRowsSocialPurpose: "Facilite le partage de contenu et l'interaction avec les réseaux sociaux depuis notre plate-forme.",
    cookieTypesRowsSocialDuration: "Persistant (1 an)",
    thirdPartyCookiesTitle: "Cookies tiers",
    thirdPartyCookiesDescription: "Nous travaillons avec des fournisseurs de services tiers, y compris des partenaires d'analyse, de marketing et de médias sociaux, qui peuvent installer des cookies en notre nom. Ces tiers peuvent utiliser les informations collectées par les cookies à leurs propres fins, par exemple pour améliorer leurs propre services ou cibler la publicité.",
    thirdPartyCookiesExamplesGoogleAnalytics: "Google Analytics : suit les interactions des utilisateurs et les performances du site.",
    thirdPartyCookiesExamplesFacebookAds: "Facebook Ads : diffuse des publicités personnalisées en fonction de votre activité sur notre site Web.",
    thirdPartyCookiesExamplesSocialPlatforms: "Twitter, LinkedIn, etc. : pour le partage social et l'engagement.",
    thirdPartyCookiesNote: "Veuillez consulter les politiques de confidentialité et de cookies de ces tiers pour obtenir des informations détaillées sur la façon dont ils utilisent les cookies.",
    controlCookiesTitle: "Comment pouvez-vous contrôler les cookies?",
    controlCookiesDescription: "Vous avez le droit de gérer et de contrôler vos préférences en matière de cookies. Vous pouvez le faire de la manière suivante :",
    controlCookiesMethodsBrowserSettingsTitle: "Paramètres du navigateur",
    controlCookiesMethodsBrowserSettingsDescription: "La plupart des navigateurs Web vous permettent de contrôler les cookies via leurs paramètres. Vous pouvez choisir de bloquer ou de supprimer les cookies, ou d'être averti lorsqu'un cookie est installé sur votre appareil. Veuillez noter que la désactivation des cookies peut affecter la fonctionnalité de certaines parties de notre site Web.",
    controlCookiesMethodsBrowserSettingsLinksChrome: "Pour Google Chrome: (https://support.google.com/chrome/answer/95647)",
    controlCookiesMethodsBrowserSettingsLinksFirefox: "Pour Mozilla Firefox: (https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US)",
    controlCookiesMethodsBrowserSettingsLinksSafari: "Pour Safari: (https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac)",
    controlCookiesMethodsBrowserSettingsLinksEdge: "Pour Microsoft Edge: (https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d)",
    controlCookiesMethodsOptOutTitle: "Se désinscrire des cookies de tiers",
    controlCookiesMethodsOptOutDescription: "Vous pouvez vous désinscrire des cookies de marketing et de publicité de tiers en visitant leurs pages de désinscription :",
    controlCookiesMethodsOptOutLinksGoogleAds: "Préférences Google Ads: (https://myadcenter.google.com/home?sasb=true&ref=ad-settings)",
    controlCookiesMethodsOptOutLinksFacebookAds: "Préférences de publicité Facebook: (https://www.facebook.com/settings?tab=ads)",
    controlCookiesMethodsOptOutLinksYourChoices: "Vos choix en ligne: (https://www.youronlinechoices.com/)",
    controlCookiesMethodsCookieConsentBannerTitle: "Bannière de consentement aux cookies",
    controlCookiesMethodsCookieConsentBannerDescription: "Lors de votre première visite sur MINTWEB3, vous verrez une bannière de consentement aux cookies. Vous pouvez choisir d'accepter ou de refuser les cookies par l'intermédiaire de cette bannière, et gérer vos préférences à tout moment via les paramètres de confidentialité de notre site Web.",
    policyChangesTitle: "Modifications de cette politique",
    policyChangesDescription: "Nous pouvons mettre à jour cette politique en matière de cookies de temps à autre pour refléter les changements technologiques, le droit applicable ou nos pratiques. Lorsque nous apportons des changements importants, nous vous en informerons en affichant un avis en évidence sur notre site Web ou en vous informant directement par e-mail ou par d'autres canaux de communication.",
    contactTitle: "Contactez-nous",
    contactDescription: "Si vous avez des questions sur cette politique en matière de cookies ou sur l'utilisation que nous faisons des cookies, n'hésitez pas à nous contacter.",
  },
  termsOfService: {
    titlePart1: "Conditions de",
    titlePart2: "Service",
    mainTitle: "Conditions de Service de MINTWEB3",
    lastUpdated: "Dernière mise à jour : 18/10/2024",
    intro: "Bienvenue chez MINTWEB3 ('Entreprise', 'nous', 'notre'). Ces Conditions de Service régissent votre utilisation de notre site web, plateforme, et de tout service connexe fourni par MINTWEB3 (collectivement, les 'Services'). En accédant à ou en utilisant nos Services, vous acceptez de respecter et d'être lié par ces Conditions de Service (les 'Termes'). Si vous n'êtes pas d'accord avec ces Termes, vous ne devez pas accéder ou utiliser nos Services.",
    acceptanceOfTermsTitle: "Acceptation des termes",
    acceptanceOfTermsContent: "En utilisant nos Services, vous acceptez d'être lié par ces Termes, ainsi que par notre Politique de Confidentialité, Politique de Cookies, Accord d'Utilisateur, et tout autre document légal pouvant s'appliquer. Ces Termes s'appliquent à tous les utilisateurs, y compris mais sans limitation, aux visiteurs, utilisateurs enregistrés, clients, et contributeurs de contenu.",
    servicesProvidedTitle: "Services Fournis",
    servicesProvidedContent: "MINTWEB3 est une entreprise de technologie et de marketing qui se spécialise dans les solutions Web 3.0, offrant une large gamme de services, y compris mais sans limitation à:",
    servicesProvidedListItem1: "Création et développement de tokens sur divers réseaux blockchain.",
    servicesProvidedListItem2: "Développement et audit de contrats intelligents.",
    servicesProvidedListItem3: "Services de marketing pour ICO et STO, y compris la création de documents de plan, la gestion communautaire et le SEO.",
    servicesProvidedListItem4: "Intégrations de staking multi-chaîne et de portefeuilles.",
    servicesProvidedListItem5: "Services de consultation et de conformité pour les projets blockchain.",
    servicesProvidedNotice: "Nous nous réservons le droit de modifier, suspendre ou interrompre les Services à tout moment, avec ou sans préavis, et sans responsabilité envers vous.",
    eligibilityTitle: "Éligibilité",
    eligibilityContent: "Vous devez avoir au moins 18 ans ou l'âge légal de la majorité dans votre juridiction pour utiliser nos Services. En utilisant nos Services, vous déclarez avoir la capacité légale de conclure un contrat contraignant.",
    accountRegistrationTitle: "Inscription d'un compte",
    accountRegistrationContent: "Pour accéder à certaines fonctionnalités de nos Services, vous devrez peut-être vous inscrire à un compte. Vous vous engagez à :",
    accountRegistrationListItem1: "Fournir des informations précises, actuelles et complètes lors de l'inscription.",
    accountRegistrationListItem2: "Maintenir la confidentialité de vos identifiants de compte.",
    accountRegistrationListItem3: "Nous prévenir immédiatement si vous soupçonnez un accès non autorisé ou une utilisation de votre compte.",
    accountRegistrationResponsibility: "Vous êtes responsable de toutes les activités qui se déroulent sous votre compte, et nous ne sommes pas responsables de toute perte ou dommage résultant d'une utilisation non autorisée.",
    paymentTermsFeesAndChargesTitle: "Frais et Charges",
    paymentTermsFeesAndChargesContent: "Tous les frais et coûts associés aux Services seront clairement indiqués dans votre accord spécifique ou tels que listés sur notre site web. Le paiement est requis avant que les services soient rendus, sauf accord contraire par écrit. Les frais peuvent inclure, mais ne sont pas limités à :",
    paymentTermsFeesAndChargesListItem1: "Les frais de développement pour la création de token et de contrat intelligent.",
    paymentTermsFeesAndChargesListItem2: "Les frais de marketing pour les campagnes ICO, la gestion de la communauté et les services de RP.",
    paymentTermsFeesAndChargesListItem3: "Les frais de consultation pour la conformité, l'audit et l'élaboration de stratégies.",
    paymentTermsPaymentMethodsTitle: "Moyens de Paiement",
    paymentTermsPaymentMethodsContent: "MINTWEB3 accepte les paiements en crypto-monnaie et en devises fiat par carte de crédit ou virement bancaire. Les utilisateurs sont responsables de tous les frais de transaction, y compris les frais de réseau blockchain ou les coûts de conversion de devises.",
    paymentTermsLatePaymentsTitle: "Paiements en Retard",
    paymentTermsLatePaymentsContent: "Si le paiement n'est pas reçu à la date d'échéance, nous nous réservons le droit de suspendre ou de mettre fin à la fourniture des Services. Les paiements tardifs peuvent également entraîner des frais de retard comme spécifié dans votre accord.",
    intellectualPropertyOwnershipTitle: "Propriété du Contenu",
    intellectualPropertyOwnershipContent: "Sauf indication contraire, tous les matériaux, contenus et services fournis par MINTWEB3, y compris mais sans limitation, les logiciels, les graphiques, les textes et les droits de propriété intellectuelle, sont la propriété de MINTWEB3 ou de ses concédants de licence. Vous ne pouvez pas copier, modifier, distribuer, ou créer des œuvres dérivées basées sur ces matériaux sans notre permission écrite expresse.",
    intellectualPropertyLicenseTitle: "Licence d'Utilisation",
    intellectualPropertyLicenseContent: "Sous réserve de ces Termes, nous vous accordons une licence limitée, non exclusive, non transférable et révocable pour accéder et utiliser les Services pour vos seules affaires internes.",
    intellectualPropertyUserGeneratedContentTitle: "Contenu Généré par l'Utilisateur",
    intellectualPropertyUserGeneratedContentContent: "Vous conservez la propriété de tout contenu que vous soumettez à MINTWEB3. En soumettant du contenu, vous accordez à MINTWEB3 une licence mondiale, libre de droits et non exclusive, pour utiliser, reproduire, distribuer et afficher votre contenu dans le but de fournir et de promouvoir les Services.",
    confidentialityTitle: "Confidentialité",
    confidentialityContent: "Les deux parties conviennent de maintenir la confidentialité des informations propriétaires partagées lors de l'utilisation des Services. Ceci inclut, mais sans s'y limiter, les données techniques, les stratégies d'entreprise, les listes de clients et les détails du projet. Cette clause restera en vigueur même après la résiliation du contrat.",
    complianceLegalComplianceTitle: "Conformité Légale",
    complianceLegalComplianceContent: "MINTWEB3 opère sous le cadre juridique de Hong Kong, où elle a son siège. Vous êtes responsable de vous assurer que votre utilisation de nos Services est en conformité avec les lois de votre juridiction.",
    complianceAmlKycTitle: "Lutte contre le Blanchiment d'Argent (AML) et Connaissez Votre Client (KYC)",
    complianceAmlKycContent: "En fonction de la nature des Services, nous pouvons exiger la conformité aux règlements AML et KYC. Vous acceptez de fournir la documentation nécessaire pour vérifier votre identité et vos opérations commerciales lorsqu'elle est demandée.",
    terminationByYouTitle: "Par Vous",
    terminationByYouContent: "Vous pouvez résilier votre compte à tout moment en contactant le support client. Cependant, vous resterez responsable de tous les frais ou charges en suspens.",
    terminationByMintweb3Title: "Par MINTWEB3",
    terminationByMintweb3Content: "Nous pouvons résilier ou suspendre votre compte ou l'accès aux Services à notre seule discrétion, sans préavis, pour un comportement que nous jugeons violer ces Termes ou nuisible à d'autres utilisateurs, à nous, ou à des tiers. À la résiliation, tous les droits qui vous sont accordés en vertu de ces Termes cesseront.",
    limitationOfLiabilityTitle: "Limitation de Responsabilité",
    limitationOfLiabilityContent: "Dans la mesure permise par la loi, MINTWEB3 et ses affiliés, partenaires et employés ne seront pas responsables de tout dommage indirect, incident, punitif ou consécutif découlant de ou en relation avec l'utilisation ou l'incapacité d'utiliser les Services. Ceci inclut, mais sans s'y limiter, la perte de profits, l'interruption d'activité, ou la perte de données.",
    indemnificationTitle: "Indemnisation",
    indemnificationContent: "Vous acceptez d'indemniser, de défendre et de dégager de toute responsabilité MINTWEB3 et ses dirigeants, administrateurs, employés et agents vis-à-vis de toute réclamation, dommage, passif et dépense résultant de:",
    disputeResolutionTitle: "Résolution des Conflits",
    disputeResolutionContent: "En cas de litige découlant de ou lié à ces Termes, les parties conviennent d'abord de tenter une résolution par des négociations de bonne foi. Si les négociations échouent, le litige sera réglé par arbitrage à Hong Kong, conformément aux règles du Centre International d'Arbitrage de Hong Kong (HKIAC). La décision de l'arbitre sera définitive et contraignante.",
    governingLawTitle: "Loi Applicable",
    governingLawContent: "Ces Termes de Service seront régis et interprétés conformément aux lois de Hong Kong, sans avoir égard à ses conflits de principes de droit.",
    changesToTermsTitle: "Modifications des Conditions de Service",
    changesToTermsContent: "MINTWEB3 se réserve le droit de modifier ces Termes à tout moment. Les utilisateurs seront informés de tout changement matériel, et l'utilisation continue des Services après de tels changements constitue l'acceptation des Termes révisés.",
    contactUsTitle: "Contactez-nous",
    contactUsContent: "Pour toute question concernant ces Termes, veuillez nous contacter.",
  },
  userAgreement: {
    title: "Utilisateur",
    afterTitle: "Accord",
    cookiePolicy: "Politique des cookies de MINTWEB3",
    effectiveDate: "Date d'effet",
    welcomeMessage: "Bienvenue sur MINTWEB3 ! Cet accord d'utilisateur ('Accord') régit votre accès et votre utilisation de la plateforme, des produits et des services de MINTWEB3 (collectivement désignés comme les 'Services'). En vous inscrivant pour un compte ou en utilisant nos Services, vous acceptez de vous conformer et d'être lié par les termes et conditions énoncés dans cet Accord.",
    agreementMessage: "Si vous n'êtes pas d'accord avec l'une des dispositions énoncées ci-dessous, veuillez vous abstenir d'utiliser nos Services.",
    acceptanceOfTerms: "Acceptation des termes",
    acceptanceDescription: "En créant un compte, en accédant ou en utilisant une partie de la plateforme MINTWEB3, vous acceptez les termes énoncés dans cet Accord. Ceci comprend toutes les modifications futures ou les modifications que nous pouvons appliquer de temps en temps. Votre utilisation continue des Services après que ces changements aient été communiqués constitue votre acceptation des nouveaux termes.",
    eligibility: "Éligibilité",
    eligibilityRequirements: "Pour utiliser les Services de MINTWEB3, vous devez:",
    eligibilityAge: "Avoir au moins 18 ans ou l'âge de la majorité dans votre juridiction.",
    eligibilityLegalCapacity: "Avoir la capacité légale de conclure des accords contraignants.",
    eligibilityCompliance: "Conforme à toutes les lois et règlements applicables, y compris ceux liés aux cryptocurrencies, aux technologies blockchain, et aux avoirs numériques.",
    mintweb3Right: "MINTWEB3 se réserve le droit de refuser ou de résilier le service à tout utilisateur sans préavis, en particulier ceux violant cet Accord ou les lois applicables.",
    accountRegistration: "Inscription de compte",
    accountRegistrationDescription: "Pour accéder à certaines fonctionnalités de la plateforme MINTWEB3, les utilisateurs doivent s'inscrire pour un compte. Lors de l'inscription, vous acceptez de fournir :",
    accountRegistrationAccurateInfo: "Des informations précises et actuelles.",
    accountRegistrationSecurePassword: "Un mot de passe sûr et unique pour votre compte.",
    accountRegistrationUpdateDetails: "Des mises à jour rapides de vos détails de compte lorsqu'elles sont nécessaires.",
    accountResponsibility: "Vous êtes seul responsable de la confidentialité de vos identifiants de connexion à votre compte et de toutes les activités qui se déroulent sous votre compte. Si vous soupçonnez un accès non autorisé ou des violations de la sécurité, informez MINTWEB3 immédiatement à [insérer une adresse courriel de support].",
    servicesProvided: "Services fournis",
    servicesDescription: "MINTWEB3 offre une large gamme de solutions Web 3.0, y compris mais sans s'y limiter :",
    service1: "Création de jetons et développement de contrats intelligents.",
    service2: "Solutions de mise en commun multi-chaînes.",
    service3: "Systèmes de paiement alimentés par Web3.",
    service4: "Marketing et développement de stratégies d'ICO (Initial Coin Offering).",
    service5: "Intégration de portefeuilles tels que MetaMask, Trust Wallet, et WalletConnect.",
    servicesModification: "Nous nous efforçons de fournir des solutions sécurisées et de pointe adaptées à l'économie décentralisée. MINTWEB3 se réserve le droit de modifier, améliorer ou discontinuer tout aspect des Services à tout moment sans préavis préalable.",
    userResponsibilities: "Responsabilités de l'utilisateur",
    userResponsibilitiesDescription: "Lors de l'utilisation des Services de MINTWEB3, vous acceptez de :",
    userResponsibility1: "Utiliser la plateforme uniquement à des fins légales.",
    userResponsibility2: "Éviter les actions qui pourraient nuire à la plateforme, à d'autres utilisateurs, ou à des tiers.",
    userResponsibility3: "Se conformer à toutes les lois applicables, y compris les réglementations sur les crypto-monnaies.",
    userResponsibility4: "S'abstenir de télécharger, publier, ou partager aucun contenu illégal, nuisible, ou inapproprié.",
    userProhibitedActions: "Vous êtes interdit de :",
    userProhibitedAction1: "S'engager dans des activités frauduleuses, y compris le phishing, le blanchiment d'argent ou les arnaques.",
    userProhibitedAction2: "Hacker, faire du reverse-engineering, ou tenter de compromettre la sécurité de la plateforme.",
    userProhibitedAction3: "Usurper l'identité de toute personne ou entité ou de fausser votre affiliation à une personne ou entité.",
    intellectualProperty: "Propriété intellectuelle",
    intellectualPropertyDescription: "Tout le contenu et les matériaux sur la plateforme MINTWEB3, y compris mais sans s'y limiter, le texte, les graphiques, les logos, les dessins, les logiciels, et les marques, sont la propriété de MINTWEB3 ou de ses concédants et sont protégés par les lois sur la propriété intellectuelle.",
    intellectualPropertyRights: "En tant qu'utilisateur, vous conservez les droits sur tout contenu que vous soumettez, postez, ou téléchargez sur la plateforme. Cependant, en faisant cela, vous accordez à MINTWEB3 une licence non exclusive, mondiale et libre de droits d'utiliser, d'afficher, de distribuer, et de promouvoir votre contenu dans le cadre de nos Services.",
    paymentTerms: "Conditions de paiement",
    paymentDescription: "MINTWEB3 fournit ses services moyennant des frais, comme indiqué dans les accords de services individuels.",
    paymentAgreement: "En utilisant nos services payants, vous acceptez de :",
    paymentResponsibility1: "Payer tous les frais applicables rapidement.",
    paymentResponsibility2: "Assurez-vous de disposer de fonds suffisants pour les transactions.",
    paymentResponsibility3: "Accepter la responsabilité de toute taxe ou frais associés en fonction de votre location.",
    paymentRefundPolicy: "Aucun remboursement n'est prévu pour les services à moins qu'il ne soit expressément convenu par écrit par MINTWEB3. Tous les paiements sont définitifs et non réversibles, à moins qu'il n'y ait eu une erreur vérifiable de facturation.",
    securityAndPrivacy: "Sécurité et confidentialité",
    securityDescription: "Nous prenons au sérieux la confidentialité et la sécurité de nos utilisateurs. Veuillez consulter notre Politique de Confidentialité pour comprendre comment nous recueillons, utilisons, et protégeons vos informations personnelles.",
    securityRiskAcknowledgment: "Bien que nous nous efforçons de maintenir les normes de sécurité les plus élevées, aucun système n'est complètement à l'abri des risques. En utilisant les Services de MINTWEB3, vous reconnaissez et acceptez ces risques inhérents associés aux technologies Web 3.0, y compris la perte potentielle de fonds due à des hacks, des accès non autorisés, ou des pannes de réseau.",
    limitationOfLiability: "Limitation de responsabilité",
    limitationDescription: "MINTWEB3 ne sera pas tenu responsable de :",
    limitationLiability1: "Toute perte ou dommages, qu'ils soient directs, indirects, accessoires, ou consécutifs, résultant de l'utilisation ou de l'incapacité à utiliser notre plateforme.",
    limitationLiability2: "Perte d'actifs, de profits, d'opportunités d'affaires, ou de données, même si MINTWEB3 a été informé de la possibilité de tels dommages.",
    limitationLiability3: "Erreurs, retards, ou pannes dans le fonctionnement des réseaux de blockchain, des contrats intelligents, ou des intégrations tiers.",
    indemnityClause: "Vous acceptez d'indemniser MINTWEB3 et ses affiliés, dirigeants, et employés de toute plainte, perte, ou dommage résultant de votre violation de cet Accord ou de mauvaise utilisation des Services.",
    thirdPartyLinks: "Liens et Services tiers",
    thirdPartyLinksDescription: "La plateforme MINTWEB3 peut contenir des liens vers des sites web tiers, des outils, ou des services. Ceux-ci sont fournis pour votre commodité, et MINTWEB3 n'approuve, ne garantit, ou n'assume la responsabilité pour tout contenu ou service de tiers. Vous reconnaissez que vos interactions avec ces services tiers sont à vos propres risques et soumis aux termes et conditions des fournisseurs respectifs.",
    termination: "Résiliation",
    terminationDescription: "MINTWEB3 se réserve le droit de résilier ou de suspendre votre compte et votre accès aux Services à tout moment, avec ou sans motif, et sans préavis préalable.",
    disputeResolution: "Résolution des litiges",
    disputeResolutionDescription: "En cas de litiges découlant de cet Accord ou de votre utilisation des Services, vous acceptez de contacter d'abord MINTWEB3 pour tenter de résoudre le problème à l'amiable. Si une résolution ne peut être atteinte, les litiges seront régis par les lois de Hong Kong, et vous acceptez de vous soumettre à la juridiction exclusive de ses tribunaux.",
    modifications: "Modifications",
    modificationsDescription: "MINTWEB3 se réserve le droit de modifier cet Accord à tout moment. Toute modification sera postée sur notre plateforme, et votre utilisation continue des Services constitue l'acceptation de l'Accord révisé."
  },
}
