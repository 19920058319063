<script setup>
import { advertisersTechnology, advertisersCrypto, airdropServices, coinsMobile, airdropKeyBenefits } from '../assets/data/index';
import MarketingCard from '../components/base/MarketingCard.vue';
import Title from '../components/base/Title.vue';
import { useAccount } from '@/composables/account';
import { useContract } from '@/composables/contract';
import { useChain } from '@/composables/chain';
import { useData } from '@/composables/data';
import OutlinedBtn from '../components/base/OutlinedBtn.vue';
import SecondaryBtn from '../components/base/SecondaryBtn.vue';
import { OperationType, WriteContractStatus } from '@/utils/constants/enums';
import AdvertisersMarquee from '../components/AdvertisersMarquee.vue';
import CoinsMarquee from '../components/CoinsMarquee.vue';
import { Vue3Lottie } from 'vue3-lottie';
import Hero2 from '@/assets/hero-2.json';
import {
	copyToClipboard,
	formatNumber,
	hasClaimStarted,
	openModal
} from '@/utils/helpers';
import { formatEther } from 'viem';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useHead } from '@vueuse/head';
import DetailsCard from '@/components/base/DetailsCard.vue';
import DevelopedByComponent from '@/components/presale-widget/components/DevelopedByComponent.vue';

const { locale, t } = useI18n();
const { account } = useAccount();
const { selectedChain } = useChain();
const { dataSelectedNetwork, airdropBalance } = useData();
const {
	isLoading,
	operation,
	transactionHash,
	status,
	claimAirdrop,
	clearState
} = useContract();

const tokenSymbol = import.meta.env.VITE_PRESALE_TOKEN_SYMBOL;
const tokenAddress = import.meta.env.VITE_PRESALE_TOKEN_CONTRACT;

const userBalance = computed(() => {
	return formatNumber(formatEther(airdropBalance.value))
})

const hasTokensToClaim = computed(() => {
	return hasClaimStarted() && formatEther(airdropBalance.value) > 0
})

function openTransaction() {
	const url = selectedChain.value.blockExplorers.default.url + '/tx/' + transactionHash.value
	window.open(url, '_blank')
}

//Add gleam meta properties
useHead({
	meta: [
		{ property: 'og:url', content: 'https://gleam.io/sd3oK/mint-web3-giveaway' },
		{ property: 'og:title', content: 'Mint Web3 Giveaway' },
		{ property: 'og:image', content: 'https://images.gleamio.com/cdn-cgi/image/format=auto,metadata=none,fit=scale-down,quality=85,width=1080,onerror=redirect/https://user-assets.out.sh/user-assets/2242226/d9kOJhAbO9ARDLzP/mintairdrop.png' },
		{ property: 'twitter:card', content: 'summary_large_image' },
		{ property: 'twitter:image', content: 'https://user-assets.out.sh/user-assets/2242226/d9kOJhAbO9ARDLzP/mintairdrop.png' },
		{ property: 'og:description', content: 'This is the Mint Web 3 Giveaway, enjoy and share!' },
	]
})
</script>

<template>
	<main>
		<section>
			<div class="radial-background-left-mobile"></div>
			<div class="radial-background-right-mobile"></div>
			<CoinsMarquee class="image-mobile" :data="coinsMobile" :direction="'reverse'" />

			<div class="hero-2">
				<vue3-lottie class="lottie-image" :animation-data="Hero2" :loop="true" :autoplay="true"
					renderer="canvas" :rendererSettings="{
						preserveAspectRatio: 'none',
						clearCanvas: true,
						progressiveLoad: true,
						hideOnTransparent: true,
						imageSmoothing: false,
						dpr: 1,
					}" />
				<div class="hero-2-content ">
					<div class="min-vh-100 d-flex" style="flex-direction: column;justify-content: center; gap: 70px;"
						data-aos="fade-up">
						<div class="container">
							<div class="row  align-items-center justify-content-center text-center">
								<div class="col-md-10">
									<OutlinedBtn>
										<template #title>

											<div class="d-flex align-items-center gap-1">
												{{ $t('general.headerBtn01') }}
												<img src="/images/right-arrow.svg" alt="Right arrow" width="16"
													height="16" />
											</div>
										</template>
									</OutlinedBtn>
									<h1 class="display-4 text-white fw-bold my-4">
										<Title>
											<span class="text-white">{{ $t('general.web30') }}</span>
											<br />
											<template #after>{{ $t('viewAirdrop.headerTitle') }}</template>
										</Title>
									</h1>
									<p class="text-secondary-light mx-auto" style="max-width: 70%;">
										{{ $t('viewAirdrop.headerDescription') }}
									</p>
									<div class="mt-4">
										<SecondaryBtn :title="$t('general.headerBtn02')" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<CoinsMarquee class="image-mobile" :data="coinsMobile" />
		</section>

		<AdvertisersMarquee :dark="true" :borders="true" :data="advertisersTechnology" id="next-section" />

		<!-- ============================== -->
		<!-- AIRDROP WIDGET                 -->
		<!-- ============================== -->

		<div>
			<DetailsCard :gradient="true">

				<template #header>
					<h1 class="display-5 fw-bold ">
						<Title>
							{{ $t('viewAirdrop.airdropTitle01') }}<br /><template #after>{{
								$t('viewAirdrop.airdropTitle02') }}</template>
						</Title>
					</h1>
				</template>

				<template #body>
					<div class="fs-6 text-secondary-dark mt-4">
						<p>
							{{ $t('viewAirdrop.airdropDescription01') }}
						</p>
						<p>
							{{ $t('viewAirdrop.airdropDescription02') }}
						</p>

					</div>
				</template>

				<template #image>
					<div class="row justify-content-center px-2">
						<div class="border rounded p-1">
							<div class="mx-auto py-3 px-2 border rounded">
								<div
									class="px-0 w-100 d-flex flex-column align-items-center justify-content-center text-center">
									<p class="font-24 fw-bold text-capitalize mb-2">
										{{ $t('airdrop.claimTitle', { token: tokenSymbol }) }}
									</p>

									<p class="font-18 fw-medium mb-1">
										<span v-if="!hasClaimStarted()">
											{{ $t('airdrop.claimNotStartedDescription', { token: tokenSymbol }) }}
										</span>
										<span v-else="">
											{{ $t('airdrop.claimStartedDescription', { token: tokenSymbol }) }}
										</span>
									</p>

									<div v-if="hasClaimStarted()">
										<p class="font-14 mb-1 mt-2">
											{{ $t('airdrop.importToken', { token: tokenSymbol }) }}
											<img src="@/assets/icons/info.svg" width="18" height="18"
												alt="Import token info" class="ms-1 cursor-pointer"
												:class="dataSelectedNetwork === undefined ? 'd-none' : ''"
												style="padding-bottom: 2px"
												v-tooltip="$t('airdrop.importTokenDescription')" />
										</p>

										<p class="font-14 fw-medium cursor-pointer"
											:class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'"
											style="word-break: break-word" @click="copyToClipboard(tokenAddress)">
											{{ tokenAddress }}
											<img src="@/assets/icons/copy.svg" width="16" height="16" alt="Copy"
												class="ms-1 cursor-pointer"
												:class="dataSelectedNetwork === undefined ? 'd-none' : ''"
												style="padding-bottom: 2px" v-tooltip="$t('general.copy')"
												@click="copyToClipboard(tokenAddress)" />
										</p>

										<div class="mb-2 font-14 text-uppercase"
											:class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'">
											<span>{{ $t('airdrop.yourClaimable', { token: tokenSymbol }) }}: </span>
											<span>{{ userBalance }}</span>
											<img src="@/assets/icons/info.svg" width="20" height="20"
												alt="Claimable info" class="ms-2 cursor-pointer"
												:class="dataSelectedNetwork === undefined ? 'd-none' : ''"
												style="padding-bottom: 2px"
												v-tooltip="$t('airdrop.yourClaimableDescription')" />
										</div>
									</div>
								</div>

								<div v-if="!isLoading && status === ''">
									<div
										class="px-3 pb-3 my-3 w-100 d-flex flex-column align-items-center justify-content-center text-center">
										<div class="d-grid gap-2 w-100 align-items-center mt-4">
											<button v-if="!account.isConnected" type="button" class="btn btn-primary"
												@click="openModal">
												{{ $t('general.connect') }}
											</button>
											<button v-else type="button" class="btn btn-primary w-100"
												:class="[!hasTokensToClaim ? 'disabled' : '']" @click="claimAirdrop">
												{{ $t('general.claimTokens') }}
											</button>
										</div>
									</div>
								</div>

								<div v-else
									class="d-flex flex-column w-100 align-items-center justify-content-center gap-2 mt-4">
									<div v-if="isLoading" class="spinner-border text-primary m-2">
										<span class="visually-hidden">{{ $t('general.loading') }}</span>
									</div>

									<img v-if="status === WriteContractStatus.Success" src="@/assets/icons/success.svg"
										alt="Success icon" width="55" height="55" />
									<img v-else-if="status === WriteContractStatus.Error" src="@/assets/icons/error.svg"
										alt="Error icon" width="55" height="55" />

									<div v-if="status !== ''" class="fw-normal fs-6 mt-1 text-uppercase text-center"
										:class="status === WriteContractStatus.Error
											? 'text-danger'
											: status === WriteContractStatus.Success
												? 'text-success'
												: 'text-secondary'
											">
										{{ $t('status.' + status.description) }}
									</div>

									<div v-if="status === WriteContractStatus.NeedConfirmation">
										<p class="font-14 mb-3 text-center">
											{{ $t('airdrop.claimNeedConfirmation', { token: tokenSymbol }) }}
										</p>
									</div>

									<div v-if="status === WriteContractStatus.WaitingTransaction">
										<p class="font-14 mb-3 text-center">
											{{ $t('airdrop.claimWaitingTransaction') }}
										</p>
									</div>

									<div v-if="status === WriteContractStatus.Success || status === WriteContractStatus.Error"
										class="w-100">
										<div v-if="status === WriteContractStatus.Success">
											<p class="font-14 mb-3 text-center">
												{{ $t('airdrop.claimSuccess', { token: tokenSymbol }) }}
											</p>
										</div>
										<p v-else-if="transactionHash" class="font-14 mb-3 text-center text-danger">
											{{ $t('airdrop.confirmationError') }}
										</p>
										<p v-else class="font-14 mb-3 text-center text-danger">
											{{ $t('airdrop.errorDescription') }}
										</p>

										<RouterLink v-if="operation === OperationType.Claim && transactionHash"
											:to="{ path: `/${locale}/staking` }" class="btn btn-primary w-100">
											{{ $t('general.stakeNow') }}
										</RouterLink>
										<div class="d-flex flex-column flex-lg-row w-100 gap-2 mt-2">
											<button v-if="transactionHash" type="button" class="btn btn-primary w-100"
												@click="openTransaction">
												{{ $t('general.viewTransaction') }}
											</button>
											<button type="button" class="btn btn-primary w-100" @click="clearState">
												{{ $t('airdrop.startAgain') }}
											</button>
										</div>
									</div>
								</div>

								<DevelopedByComponent />
							</div>
						</div>
					</div>
				</template>
			</DetailsCard>
		</div>

		<!-- ============================== -->
		<!-- AIRDROP GAME                   -->
		<!-- ============================== -->

		<div class="position-relative">
			<!-- gradiant bgs start-->
			<div class="radial-background-right"></div>
			<!-- gradiant bgs-end -->
			<div class="container py-4">
				<div v-for="(item, idx) in airdropServices(t).slice(0, 1)" :key="idx" class="mt-5">
					<MarketingCard :dark="true" :item="item" />
				</div>
				<div class="d-flex justify-content-center my-5">
					<a href="https://t.me/MintWeb3AirdropBot/Game" target="_blank" rel="noopener noreferrer"
						class="my-5 text-white align-center btn btn-primary btn-lg rounded-pill text-end">
						<img src="/images/telegram.svg" alt="Telegram logo" width="48" height="48" />
						{{ $t('viewAirdrop.airdropPlayButton') }}
					</a>
				</div>
			</div>
		</div>

		<!-- ============================== -->
		<!-- GIVEAWAY WIDGET                -->
		<!-- ============================== -->

		<div>
			<DetailsCard :gradient="true" :centerAlign="false">
				<template #header>
					<h1 class="display-5 fw-bold">
						<Title>
							{{ $t('viewAirdrop.giveawayTitle01') }}<br /><template #after>{{
								$t('viewAirdrop.giveawayTitle02') }}</template>
						</Title>
					</h1>
				</template>

				<template #body>

					<div class="fs-6 text-secondary-dark">
						{{ $t('viewAirdrop.giveawayDescription') }}
					</div>

					<div class="fw-bold fs-4 mt-3">
						{{ $t('viewAirdrop.giveawayKeyBenefits') }}
					</div>

					<div v-for="(item, idx) in airdropKeyBenefits(t)" :key="idx"
						class="d-flex align-items-center gap-2 mt-2">
						<img width="14" height="14" src="/images/bullet.svg" alt="Bullet" />
						<div class="text-secondary-dark fs-6">{{ item }}</div>
					</div>

				</template>

				<template #image>
					<a class="e-widget no-button" href="https://gleam.io/3IdHh/mint-web3-giveaway" rel="nofollow">
						{{ $t('viewAirdrop.giveawayWidget') }}
					</a>
					<component src="https://widget.gleamjs.io/e.js" :is="'script'" :async=true></component>
				</template>

			</DetailsCard>
		</div>

		<div>
			<AdvertisersMarquee :dark="true" :borders="true" :title="$t('general.multiNetwork')"
				:data="advertisersCrypto" />
		</div>

	</main>
</template>
