<script setup>
const props = defineProps(['title'])

import { ref } from "vue";

// Create a ref for the next section
const nextSection = ref(null);

// Define the function to scroll smoothly to the next section
const scrollToNextSection = () => {
    const section = document.getElementById("next-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
};
</script>

<template>
    <button class="btn p-3 text-sm rounded-pill text-black secondary-btn" @click="scrollToNextSection">
        {{ props.title }}
    </button>
</template>

<style scoped>
.secondary-btn {
    background: linear-gradient(94.64deg, #D4D4D3 5.38%, #F7F7EE 88.99%) !important;
}
</style>