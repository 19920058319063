<script setup>
import { advertisersTechnology, advertisersCrypto, coinsMobile, ourServices, indexAboutItems, indexExpertise } from '../assets/data/index';
import DetailsCard from '../components/base/DetailsCard.vue';
import DetailsList from '../components/base/DetailsList.vue';
import OutlinedBtn from '../components/base/OutlinedBtn.vue';
import SecondaryBtn from '../components/base/SecondaryBtn.vue';
import Title from '../components/base/Title.vue';
import AdvertisersMarquee from '../components/AdvertisersMarquee.vue';
import CoinsMarquee from '../components/CoinsMarquee.vue';
import { Vue3Lottie } from 'vue3-lottie';
import Hero2 from '@/assets/hero-2.json';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
    <main>
        <section>
            <div class="radial-background-left-mobile"></div>
            <div class="radial-background-right-mobile"></div>
            <CoinsMarquee class="image-mobile" :data="coinsMobile" :direction="'reverse'" />

            <div class="hero-2">
                <vue3-lottie class="lottie-image" :animation-data="Hero2" :loop="true" :autoplay="true"
                    renderer="canvas" :rendererSettings="{
                        preserveAspectRatio: 'none',
                        clearCanvas: true,
                        progressiveLoad: true,
                        hideOnTransparent: true,
                        imageSmoothing: false,
                        dpr: 1,
                    }" />
                <div class="hero-2-content ">
                    <div class="min-vh-100 d-flex" style="flex-direction: column;justify-content: center; gap: 70px;"
                        data-aos="fade-up">
                        <div class="container">
                            <div class="row  align-items-center justify-content-center text-center">
                                <div class="col-md-10">
                                    <OutlinedBtn>
                                        <template #title>
                                            <div class="d-flex align-items-center gap-1">
                                                {{ $t('general.headerBtn01') }}
                                                <img src="/images/right-arrow.svg" alt="Right arrow" width="16"
                                                    height="16" />
                                            </div>
                                        </template>
                                    </OutlinedBtn>
                                    <h1 class="display-2 text-white fw-bold my-4">
                                        <Title>
                                            <span class="text-white">{{ $t('general.web30') }}</span>
                                            <br />
                                            <template #after>{{ $t('viewIndex.development') }}</template>
                                        </Title>
                                    </h1>
                                    <p class="text-secondary-light mx-auto" style="max-width: 70%;">
                                        {{ $t('viewIndex.headerSubText') }}
                                    </p>
                                    <div class="mt-4">
                                        <SecondaryBtn :title="$t('general.headerBtn02')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CoinsMarquee class="image-mobile" :data="coinsMobile" />
        </section>

        <AdvertisersMarquee :dark="true" :borders="true" :data="advertisersTechnology" id="next-section"/>

        <!-- ============================== -->
        <!-- ABOUT US SECTION               -->
        <!-- ============================== -->

        <div>
            <DetailsCard :gradient="true">
                <template #header>
                    <h1 class="display-5 fw-bold">
                        <Title>
                            <template #before>{{ $t('viewIndex.aboutUs01') }}</template>{{ $t('viewIndex.aboutUs02') }}
                        </Title>
                    </h1>
                </template>

                <template #body>
                    <div class="fs-6 text-secondary-dark">
                        {{ $t('viewIndex.aboutUsText') }}
                    </div>

                    <div class="fw-bold fs-4 mt-3">
                        {{ $t('viewIndex.ourMission') }}
                    </div>

                    <div v-for="(item, idx) in indexAboutItems(t)" :key="idx" class="d-flex align-items-center gap-2 mt-2">
                        <img width="14" height="14" src="/images/bullet.svg" alt="Bullet" />
                        <div class="text-secondary-dark fs-6">{{ item }}</div>
                    </div>
                </template>

                <template #image>
                    <img style="width: 100%;" src="/images/about.webp" alt="About" />
                </template>
            </DetailsCard>

        </div>

        <!-- ============================== -->
        <!-- DISCOVER OUR SOLUTIONS SECTION -->
        <!-- ============================== -->

        <div class="container py-container">
            <div class="text-white display-5 fw-bold text-center" data-aos="fade-up">
                <Title>
                    {{ $t('viewIndex.discoverOurSolutions01') }}
                    <template #after>
                        {{ $t('viewIndex.discoverOurSolutions02') }}
                    </template>
                </Title>
            </div>
            <div class="py-container">
                <DetailsList :secondary="true" :data="ourServices(t)" />
            </div>
        </div>

        <!-- ============================== -->
        <!-- WHY CHOOSE SECTION             -->
        <!-- ============================== -->

        <div class="bg-white position-relative">
            <div class="radial-background-left"></div>
            <div class="radial-background-right"></div>
            <div class="container py-container ">
                <div data-aos="fade-up">
                    <h1 class="display-5 fw-bold text-center">
                        <Title>
                            {{ $t('viewIndex.whyChoose01') }} <template #after>{{ $t('viewIndex.whyChoose02') }}
                            </template>
                        </Title>
                    </h1>

                    <p class="fs-6 text-secondary-dark my-4 text-center mx-auto" style="width: 70%;">{{
                        $t('viewIndex.whyChooseText') }}
                    </p>
                </div>

                <div class="main-section row mt-5 mx-2" data-aos="fade-up">
                    <div class="col-12 col-md-6 text-center my-auto" style="position: relative;">
                        <img style="height: 400px;width: 60%; height: 60%;" src="/images/chooseus.webp" alt="Choose us" />
                    </div>
                    <div class="col-12 col-md-6 px-0 left-border">
                        <div class="px-2 bg-primary bottom-boder d-flex align-items-center top-right-border-radius py-4"
                            data-aos="fade-up">
                            <img src="/images/bullet-white.svg" alt="Bullet" width="20" height="20" />
                            <div class="flex-grow-1 text-center fs-6">{{ $t('viewIndex.expertiseWeb3') }}</div>
                        </div>
                        <div v-for="(item, idx) in indexExpertise(t)" :class="idx < indexExpertise(t).length - 1 ? 'bottom-boder' : ''"
                            class="px-2   d-flex align-items-center py-4" data-aos="fade-up">
                            <img src="/images/bullet.svg" alt="Bullet" width="20" height="20" />
                            <div class="flex-grow-1 text-center">{{ item }}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div>
            <AdvertisersMarquee :dark="true" :borders="true" :title="$t('general.multiNetwork')" :data="advertisersCrypto" />
        </div>
    </main>
</template>

<style scoped>
.main-section {
    border: 1px solid black;
    border-radius: 18px;
}

.bottom-boder {
    border-bottom: 1px solid black;
}

.left-border {
    border-left: 1px solid black;
}

.top-right-border-radius {
    border-top-right-radius: 16px;
}

.radial-background-left {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(30% 50% at 50% 50%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    transform: translate(-50%, -50%);
    z-index: 0;
    pointer-events: none;
    opacity: 40%;
}

.radial-background-right {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(40% 50% at 100% 50%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    transform: translateY(-50%);
    z-index: 0;
    pointer-events: none;
    opacity: 40%;
}
</style>