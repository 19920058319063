<script setup>
import { formatEther } from 'viem'
import { computed, onUnmounted } from 'vue'
import { chains } from '@/plugins/walletconnect'
import { useAccount } from '@/composables/account'
import { useChain } from '@/composables/chain'
import { useData } from '@/composables/data'
import { formatNumber, getChainSymbol, openModal } from '@/utils/helpers'
import { useI18n } from 'vue-i18n'
import DevelopedByComponent from './DevelopedByComponent.vue'
import CountdownComponent from './CountdownComponent.vue'

const { locale } = useI18n()
const { account } = useAccount()
const { selectedChain, update } = useChain()
const { dataSelectedNetwork, userTotalBalance, userUnstakedBalance, stakerDetails, stopFetching } =
  useData()

const tokenSymbol = import.meta.env.VITE_PRESALE_TOKEN_SYMBOL
const claimStartTime = Number(import.meta.env.VITE_CLAIM_START_TIME)

const totalBalance = computed(() => {
  return formatNumber(formatEther(userTotalBalance.value), 0, 0)
})

const unstakedBalance = computed(() => {
  return formatNumber(formatEther(userUnstakedBalance.value), 0, 0)
})

const stakedBalance = computed(() => {
  return formatNumber(formatEther(stakerDetails.value.amount), 0, 0)
})

onUnmounted(() => {
  stopFetching()
})
</script>

<template>
  <div class="border rounded p-1">
    <div class="mx-auto py-3 px-2 border rounded">
      <div class="px-0 w-100 d-flex flex-column align-items-center justify-content-center text-center">
        <p class="font-24 fw-bold text-capitalize mb-1">
          {{ $t('presaleOver.presaleOverTitle', { token: tokenSymbol }) }}
        </p>

        <p class="font-15 fw-medium mb-1">
          {{ $t('presaleOver.presaleOverDescription', { token: tokenSymbol }) }}
        </p>

        <div class="border rounded w-100 p-2 my-2">
          <div class="font-15 fw-medium lh-2">
            <span>{{ $t('presaleOver.untilClaimStart') }}</span>
          </div>

          <CountdownComponent :isLoading="dataSelectedNetwork === undefined" :endTime="claimStartTime" />
        </div>

        <div class="my-2 font-14 text-uppercase" :class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'">
          <span>{{ $t('presale.yourPurchased', { token: tokenSymbol }) }}: </span>
          <span>{{ totalBalance }}</span>
          <img src="@/assets/icons/info.svg" width="20" height="20" alt="Purchased info" class="ms-2 cursor-pointer"
            :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
            v-tooltip="$t('presale.yourPurchasedDescription')" />
        </div>

        <div class="mb-2 font-14 text-uppercase" :class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'">
          <span>{{ $t('presale.yourStakeable', { token: tokenSymbol }) }}: </span>
          <span>{{ unstakedBalance }}</span>
          <img src="@/assets/icons/info.svg" width="20" height="20" alt="Unstaked info" class="ms-2 cursor-pointer"
            :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
            v-tooltip="$t('presale.yourStakeableDescription')" />
        </div>

        <div class="mb-2 font-14 text-uppercase" :class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'">
          <span>{{ $t('presale.yourStaked', { token: tokenSymbol }) }}: </span>
          <span>{{ stakedBalance }}</span>
          <img src="@/assets/icons/info.svg" width="20" height="20" alt="Stake info" class="ms-2 cursor-pointer"
            :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
            v-tooltip="$t('presale.yourStakedDescription')" />
        </div>
      </div>

      <div class="d-flex w-100 align-items-center justify-content-center gap-2 mt-3">
        <div class="dropdown">
          <button type="button"
            class="btn w-100 border py-2 px-0 px-2 dropdown-toggle d-flex justify-content-between align-items-center"
            data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
            <img :src="`/coins-icons/${getChainSymbol(selectedChain.id)}.svg`"
              :alt="`${getChainSymbol(selectedChain.id)} logo`" width="22" height="22" class="me-2" />
            <span class="text-truncate">{{ selectedChain.name }}</span>
          </button>

          <ul class="dropdown-menu">
            <li v-for="chain in chains" :key="chain.id">
              <button class="dropdown-item d-flex align-items-center ps-2"
                :class="chain.id === selectedChain.id ? 'active' : ''" :aria-current="chain.id === selectedChain.id"
                type="button" @click="update(chain.id)">
                <img :src="`/coins-icons/${getChainSymbol(chain.id)}.svg`"
                  :alt="`${getChainSymbol(selectedChain.id)} logo`" loading="lazy" width="22" height="22"
                  class="me-2" />
                {{ chain.name }}
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div class="d-grid w-100 align-items-center my-4">
        <button v-if="!account.isConnected" type="button" class="btn btn-primary" @click="openModal">
          {{ $t('general.connect') }}
        </button>
        <!-- <RouterLink v-else :to="{ path: `/${locale}/staking` }" class="btn btn-primary w-100">
          {{ $t('general.stakeNow') }}
        </RouterLink> -->
      </div>

      <DevelopedByComponent />
    </div>
  </div>
</template>
