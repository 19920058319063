<script setup>
import { useI18n } from 'vue-i18n'
import DevelopedByComponent from './DevelopedByComponent.vue'
import CountdownComponent from './CountdownComponent.vue'

const { locale } = useI18n()

const presaleStartTime = Number(import.meta.env.VITE_PRESALE_START_TIME)
</script>

<template>
  <div class="border rounded p-1">
    <div class="mx-auto py-3 px-2 border rounded">
      <div class="px-0 w-100 d-flex flex-column align-items-center justify-content-center text-center">
        <p class="font-24 fw-bold text-capitalize mb-1">
          Coming Soon
        </p>

        <p class="font-15 fw-medium mb-1">
          Stay tuned, the presale will start shortly.
        </p>

        <div class="border rounded w-100 p-2 my-2">
          <div class="font-15 fw-medium lh-2">
            <span>Until presale begins</span>
          </div>

          <CountdownComponent :endTime="presaleStartTime" />
        </div>
      </div>

      <DevelopedByComponent />
    </div>
  </div>
</template>
