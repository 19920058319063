<script setup>
import Title from '../components/base/Title.vue';
import Calendly from '@/components/Calendly.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
    <main>
        <section class="hero-3">
            <div class="hero" style="position: absolute !important;width: 100%;top: 0;left: 0;z-index: 0;">

            </div>

            <div class="d-flex mt-5" style="flex-direction: column;justify-content: center; min-height: 40vh;"
                data-aos="fade-up">
                <div class="container">
                    <div class="row  align-items-center justify-content-center text-center">
                        <div class="col-md-10">
                            <h1 class="display-2 text-white fw-bold my-4">
                                <Title>
                                    <span class="text-white me-2">{{ $t('viewContact.header01') }}</span>
                                    <template #after>{{ $t('viewContact.header02') }}</template>
                                </Title>
                            </h1>
                            <p class="text-secondary-light mx-auto" style="max-width: 70%;">{{
                                $t('viewContact.description') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container position-relative">
                <div class="contact-form col-12 col-lg-8 p-4 rounded-4" data-aos="fade-up">
                    <div class="d-flex justify-content-center align-items-center mb-4 fs-2">
                        {{ $t('viewContact.schedule') }}
                    </div>

                    <div class="d-flex justify-content-center align-items-center my-5">
                        <div class="social-icons">
                            <div class="mx-3 btn btn-dark rounded-circle">
                                <a href="https://t.me/MintWeb3io" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/telegram.svg" alt="Telegram logo" width="64" height="64" />
                                </a>
                            </div>
                            <!-- <div class="mx-3 btn btn-dark rounded-circle">
                                <a href="https://wa.me/351911906292" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/whatsapp.svg" alt="Whatsapp logo" width="64" height="64" />
                                </a>
                            </div> -->
                            <div class="mx-3 btn btn-dark rounded-circle">
                                <a href="mailto:support@mintweb3.io" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/mail.svg" alt="Email logo" width="64" height="64" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <Calendly />
                </div>
            </div>
        </section>
    </main>
</template>

<style scoped>
.contact-form {
    color: white;
    /* max-width: 60%; */
    position: relative;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.contact-form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1px;
    background: linear-gradient(90.47deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.0) 100%);
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    pointer-events: none;
    z-index: 1;
}

.btn-dark {
    width: 64px;
    height: 64px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #2a2a2a;
    border: none;
    font-size: 1.2rem;
}

.btn-dark:hover {
    background-color: #3a3a3a;
}

.social-icons {
    display: flex;
    gap: 10px;
}
</style>