export const de = {
  general: {
    submit: "Einreichen",
    connect: "Verbinden",
    buy: "Kaufen",
    buyAndStake: "Kaufen und besetzen",
    buyWithCard: "Mit Karte kaufen",
    stake: "Besitzen",
    stakeNow: "Jetzt besitzen",
    claimTokens: "Beanspruchen",
    claimAndStake: "Beanspruchen und besitzen",
    claimRewards: "Belohnungen beanspruchen",
    becomeAnAffiliate: "Ein Affiliate werden!",
    loading: "Laden...",
    viewTransaction: "Transaktion ansehen",
    close: "Schließen",
    copy: "Kopieren",
    days: "Tag | Tage",
    hours: "Stunde | Stunden",
    minutes: "Minute | Minuten",
    seconds: "Sekunde | Sekunden",
    lastRound: "Letzte Runde",
    developedBy: "Entwickelt von",
    headerBtn01: "Begleiten Sie uns, freie Welt",
    headerBtn02: "Entdecken Sie diese Welten",
    web30: "WEB 3.0",
    multiNetwork: "Multi Netzwerk",
    socials: "Soziale Netzwerke",
  },
  navbar: {
    home: "Zuhause",
    presale: "Vorverkauf",
    staking: "Staking",
    airdrop: "Airdrop / Giveaway",
    marketing: "Marketing",
    contact: "Kontakt",
  },
  footer: {
    tittle01: "Vollständige Und",
    tittle02: "Innovative",
    tittle03: "Lösungen Für",
    tittle04: "Ihr Web 3.0",
    tittle05: "Projekt",
    quickLinks: "Schnell Links",
    legal: "Rechtlich",
    termsOfServices: "Nutzungsbedingungen",
    cookiePolicy: "Cookie-Richtlinie",
    userAgreement: "Benutzervereinbarung",
    communityGuideline: "Gemeinschaftsrichtlinien",
  },
  presale: {
    howToBuy: "Wie man kauft",
    presaleOpen: "{token} Vorverkauf ist jetzt offen",
    stage: "Stufe",
    untilPriceIncrease: "Bis zur Preiserhöhung",
    untilPriceIncreaseDescription:
      "Der Preis wird steigen, wenn der Timer endet oder wenn der in USD erzielte Betrag das Ziel erreicht.",
    nextPrice: "Nächster Preis",
    usdtRaised: "USDT gesammelt",
    yourPurchased: "Ihr gekaufter {token}",
    yourPurchasedDescription: "Gesamt gekaufte Tokens mit verbundener Adresse auf ausgewählter Kette",
    yourStakeable: "Ihr einsetzbarer {token}",
    yourStakeableDescription:
      "Gesamt nicht eingesetzte Tokens mit verbundener Adresse auf ausgewählter Kette. Sie können Ihre Tokens nun auf der Staking-Seite einsetzen.",
    yourStaked: "Ihr eingesetzter {token}",
    yourStakedDescription: "Gesamt eingesetzte Tokens mit verbundener Adresse auf ausgewählter Kette."
  },
  swap: {
    balance: "Kontostand",
    youPay: "{token} Sie zahlen",
    youReceive: "{token} Sie erhalten",
    lessThanMinimumError: "Die zu kaufende Menge von { token } muss größer als { min } sein.",
    exceedsMaxError: "Die zu kaufende Menge von { token } muss kleiner als { max } sein.",
    notEnoughBalanceError: "Stellen Sie sicher, dass Sie genug Guthaben zum Kaufen haben.",
    needApprovalDescription:
      "Um {token} mit {usdToken} zu kaufen, müssen Sie uns zuerst die Berechtigung geben, auf das {usdToken} in Ihrer Brieftasche zuzugreifen. Sie müssen möglicherweise die Wallet-App überprüfen, wenn Sie mobil sind.",
    waitingApprovalDescription:
      "Die Genehmigung läuft, Sie werden die Transaktion in Kürze bestätigen können.",
    needConfirmationDescription:
      "Um {tokenSymbol} Tokens zu kaufen, bestätigen Sie bitte die Transaktion in Ihrer Brieftasche. Sie müssen möglicherweise die Wallet-App überprüfen, wenn Sie mobil sind.",
    waitingTransactionDescription:
      "{amount} Tokens werden gekauft, sobald die Transaktion bestätigt ist.",
    openingWertModal: "Warten Sie, während der Zahlungsbildschirm geöffnet wird.",
    waitingWertPayment:
      "Um {token} mit Karte zu kaufen, fahren Sie fort, indem Sie Ihre Informationen auf dem Zahlungsbildschirm eingeben.",
    waitingWertConfirmation:
      "{amount} Tokens werden gekauft, sobald der Zahlungsanbieter Ihre Zahlung bestätigt.",
    successDescription: "{amount} Tokens wurden erfolgreich gekauft!",
    confirmationError:
      "Wir konnten Ihre Transaktion nicht bestätigen, überprüfen Sie bitte, ob sie in Ihrer Brieftasche steckt.",
    errorDescription: "Etwas ist schief gelaufen, bitte versuchen Sie es erneut.",
    startAgain: "Nochmal beginnen",
    notEnoughTokens: "Nicht genug {token}? Jetzt kaufen oder tauschen!",
    buyOrSwapTokens: "Tokens kaufen oder tauschen"
  },
  presaleOver: {
    presaleOverTitle: "{token} Vorverkauf ist vorbei",
    presaleOverDescription:
      "Warten Sie, bis die Erhebung beginnt, um Ihre {token} Tokens zu fordern und zu verhandeln. In der Zwischenzeit können Sie Ihre gekauften Tokens einsetzen und Belohnungen verdienen.",
    untilClaimStart: "Bis zum Beginn der Forderung"
  },
  claim: {
    claimOpen: "{token} Anspruch ist jetzt aktiv",
    claimOpenDescription: "Jetzt können Sie Ihren {token} beanspruchen und staken, um Belohnungen zu verdienen.",
    importToken:
      "Importieren Sie das {token} Token in Ihre Geldbörse, indem Sie die unten angegebene Adresse verwenden, um Ihre beanspruchten Token zu sehen.",
    importTokenDescription:
      "Wenn Sie nicht wissen, wie das geht, suchen Sie nach 'Wie importiere ich benutzerdefinierte Token' in der Geldbörse, die Sie verwenden.",
    yourClaimable: "Ihr anspruchsberechtigter {token}",
    yourClaimableDescription:
      "Gesamtzahl der Tokens, die mit der verbundenen Adresse beansprucht werden können. Sie können Ihre Token auf Ihre Geldbörse beanspruchen oder direkt staken.",
    yourStaked: "Ihr gestakter {token}",
    yourStakedDescription: "Gesamte gestakte Token mit verbundener Adresse.",
    buyNowOnExchange: "Kaufen Sie {token} jetzt auf {exchange}",
    stakeNeedConfirmation:
      "Um Ihre {token} Tokens zu staken, bestätigen Sie bitte die Transaktion in Ihrer Geldbörse. Sie müssen möglicherweise die Wallet-App prüfen, wenn Sie mobil sind. Dies wird alle Ihre anspruchsberechtigten {token} Tokens staken. Tokens werden für {lockDays} @.lower:Tage gesperrt sein, bis Sie abheben können.",
    claimNeedConfirmation:
      "Um Ihre {token} Token zu beanspruchen, bestätigen Sie bitte die Transaktion in Ihrer Geldbörse. Sie müssen möglicherweise die Wallet-App prüfen, wenn Sie mobil sind. Dies beansprucht alle Ihre anspruchsberechtigten {token} Tokens auf Ihre verbundene Geldbörse.",
    stakeWaitingTransaction: "Die Token werden gestaking, sobald die Transaktion bestätigt ist.",
    claimWaitingTransaction: "Die Token werden beansprucht, sobald die Transaktion bestätigt ist.",
    stakeSuccess: "Token wurden erfolgreich gestaked!",
    claimSuccess:
      "Token wurden erfolgreich beansprucht! Denken Sie daran, um Ihren {token} Saldo in Ihrer Geldbörse zu sehen, müssen Sie die Token-Adresse oben importieren.",
    confirmationError:
      "Wir konnten Ihre Transaktion nicht bestätigen, bitte prüfen Sie, ob sie in Ihrer Geldbörse feststeckt.",
    errorDescription: "Etwas ist schief gelaufen, bitte versuchen Sie es erneut.",
    startAgain: "Nochmal anfangen"
  },
  staking: {
    welcome: "Willkommen zum {token} Staking",
    distributionDescription:
      "Die Verteilung der {tokenName} Token-Belohnungen erfolgt mit einer Rate von {rewardsPerDay} {tokenSymbol} pro Tag und wird über {duration} Jahr verteilt.",
    withdrawStakedTokens: "Eingezahlte Tokens abheben",
    stakedBalance: "Eingezahlter Saldo",
    stakeableBalance: "Einzahlbarer Saldo",
    totalRewards: "Gesamtbelohnungen",
    claimNotStarted:
      "Die Antragsperiode beginnt, sobald der Vorverkauf endet. Bleiben Sie dran, um mit dem Einfordern Ihrer Belohnungen zu beginnen, sobald der Vorverkauf abgeschlossen ist.",
    totalStaked: "Gesamt eingezahlt",
    percentageOfPool: "Ihr % des Pools",
    currentRewards: "Aktuelle Belohnungen",
    estimatedRewards: "Geschätzte Belohnungen",
    rewardRateDynamic: "Belohnungsrate ist dynamisch",
    monthlyRewards: "Monatlich = Belohnungen % / 12",
    dailyRewards: "Täglich = Belohnungen % / 365",
    stakeNow: "Einzahlen Sie jetzt Ihre {token}",
    claimNow: "Fordern Sie jetzt Ihre {token} ein",
    withdrawNow: "Heben Sie jetzt Ihre {token} ab",
    fillAmount:
      "Geben Sie den Betrag von {token} ein, den Sie einzahlen möchten, und bestätigen Sie die Transaktion, um mit dem Sammeln von Belohnungen zu beginnen.",
    amount: "Menge",
    notEnoughBalanceError: "Stellen Sie sicher, dass Sie genug Guthaben zum Einzahlen haben.",
    needApprovalDescription:
      "Um {token} einzuzahlen, müssen Sie uns zuerst die Berechtigung geben, auf die {token} in Ihrer Wallet zuzugreifen. Sie müssen möglicherweise die Wallet-App überprüfen, wenn Sie auf einem Mobilgerät sind.",
    waitingApprovalDescription:
      "Die Autorisierung läuft, Sie können die Transaktion in Kürze bestätigen.",
    stakeNeedConfirmation:
      "Um Ihre {token} Tokens einzuzahlen, bestätigen Sie bitte die Transaktion in Ihrer Wallet. Sie müssen eventuell die Wallet-App überprüfen, wenn Sie auf einem Mobilgerät sind. Dies wird all Ihre {token} Tokens einzahlen. Die Tokens werden für {lockDays} @.lower: Tage gesperrt, nachdem der Einlösungsprozess beginnt.",
    claimNeedConfirmation:
      "Um Ihre {token} Tokens einzufordern, bestätigen Sie bitte die Transaktion in Ihrer Wallet. Sie müssen eventuell die Wallet-App überprüfen, wenn Sie auf einem Mobilgerät sind. Dies wird all Ihre {token} Belohnungen geltend machen.",
    withdrawNeedConfirmation:
      "Um Ihre eingezahlten {token} Tokens abzuheben, bestätigen Sie bitte die Transaktion in Ihrer Wallet. Sie müssen eventuell die Wallet-App überprüfen, wenn Sie auf einem Mobilgerät sind. Dies wird all Ihre eingezahlten {token} Tokens abheben.",
    stakeWaitingTransaction: "Die Tokens werden eingezahlt, sobald die Transaktion bestätigt ist.",
    claimWaitingTransaction: "Belohnungen werden eingefordert, sobald die Transaktion bestätigt ist.",
    withdrawWaitingTransaction: "Tokens werden abgehoben, sobald die Transaktion bestätigt ist.",
    stakeSuccess: "Die Tokens wurden erfolgreich eingezahlt!",
    claimSuccess: "Die Tokens wurden erfolgreich eingefordert!",
    withdrawSuccess: "Die Tokens wurden erfolgreich abgehoben!",
    confirmationError:
      "Wir konnten Ihre Transaktion nicht bestätigen, bitte überprüfen Sie, ob sie in Ihrer Wallet feststeckt.",
    errorDescription: "Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
    tryAgain: "Erneut versuchen"
  },
  airdrop: {
    claimTitle: "{token} Airdrop-Anspruch",
    claimNotStartedDescription:
      "Der Anspruchszeitraum für den Airdrop beginnt, sobald der Vorverkauf endet. Bleiben Sie dran, um nach Abschluss des Vorverkaufs Ihre Belohnungen zu beanspruchen.",
    claimStartedDescription: "Sie können nun Ihren abgeworfenen {token} einfordern, um Belohnungen zu verdienen.",
    importToken:
      "Importieren Sie das {token} Token in Ihre Brieftasche, indem Sie die untenstehende Adresse verwenden, um Ihre eingeforderten Tokens zu sehen.",
    importTokenDescription:
      "Wenn Sie nicht wissen, wie das geht, suchen Sie nach 'So importieren Sie benutzerdefinierte Tokens' in dem Wallet, den Sie verwenden.",
    yourClaimable: "Ihr einholbares {token}",
    yourClaimableDescription:
      "Gesamte Tokens, die während des Airdrops erhalten wurden und mit der verbundenen Adresse gefordert werden können.",
    claimNeedConfirmation:
      "Um Ihre {token} Tokens einzufordern, bestätigen Sie bitte die Transaktion in Ihrer Wallet. Möglicherweise müssen Sie die Wallet-App überprüfen, wenn Sie mobil sind. Dies fordert alle Ihre einholbaren {token} Tokens zu Ihrer verbundenen Wallet ein.",
    claimWaitingTransaction: "Die Token werden beansprucht, sobald die Transaktion bestätigt ist.",
    claimSuccess:
      "Die Tokens wurden erfolgreich eingefordert! Denken Sie daran, dass Sie die Token-Adresse oben importieren müssen, um Ihren {token} Saldo in Ihrer Brieftasche zu sehen.",
    confirmationError:
      "Wir konnten Ihre Transaktion nicht bestätigen, bitte prüfen Sie, ob sie in Ihrer Brieftasche steckt.",
    errorDescription: "Etwas ist schief gelaufen, bitte versuchen Sie es erneut.",
    startAgain: "Noch einmal starten"
  },
  affiliate: {
    affiliateName: "Affiliate-Name",
    affiliateNameDescription:
      "Der Affiliate-Name wird zur Identifizierung verwendet und um Ihren Affiliate-Link zu generieren",
    parentAffiliateName: "Name des übergeordneten Affiliates",
    affiliateCreated: "Affiliate erfolgreich erstellt!",
    affiliateLink: "Ihr Affiliate-Link",
    affiliateLinkDescription: "Teilen Sie Ihren Affiliate-Link mit Ihrer Community, um Belohnungen für Ihre Empfehlungen zu erhalten.",
    balance: "Guthaben",
    available: "Verfügbar",
    withdrawn: "Abgehoben",
    total: "Gesamt",
    transactions: "Transaktionen",
    transactionHash: "Transaktions-Hash",
    transactionAmount: "Betrag",
    affiliatePercentage: "Affiliate-Prozentsatz",

  },
  status: {
    NEED_APPROVAL: "Genehmigung benötigt",
    WAITING_APPROVAL: "Warte auf Bestätigung der Genehmigung",
    NEED_CONFIRMATION: "Benötigt Bestätigung",
    WAITING_TRANSACTION: "Warte auf Bestätigung der Transaktion",
    OPENINIG_WERT_MODAL: "Öffne Bezahlbildschirm",
    WAITING_WERT_PAYMENT: "Zahlung ausstehend",
    WAITING_WERT_CONFIRMATION: "Warte auf Zahlungsbestätigung",
    SUCCESS: "Erfolg",
    ERROR: "Fehler"
  },
  viewIndex: {
    aboutItems01: "Den Zugang zum Web 3.0 demokratisieren, indem innovative, skalierbare und sichere Blockchain-Lösungen bereitgestellt werden.",
    aboutItems02: "Wir schlagen eine Brücke zwischen traditionellen Unternehmen und der dezentralisierten Wirtschaft.",
    aboutItems03: "Wir garantieren eine nahtlose Integration und maximalen Nutzen für unsere Kunden.",
    development: "ENTWICKLUNG",
    headerSubText: "Die Entwicklung von Web 3.0 erstellt dezentrale Apps (dApps) mit der Blockchain für benutzerkontrollierte Daten und sichere Peer-to-Peer-Interaktionen.",
    aboutUs01: "Über",
    aboutUs02: "Uns",
    aboutUsText: "Bei MintWeb3 ermöglichen wir Unternehmen und Einzelpersonen fortschrittliche Web 3.0-Lösungen. Von der Token-Entwicklung bis zum vollständigen ICO-Management und Marketing sind wir hier, um Ihnen zu helfen, das Potenzial des dezentralen Internets zu nutzen. Mit Sitz in Hongkong, einem weltweit führenden Zentrum für Finanzen und Technologie, bieten wir unübertroffene Expertise in Blockchain, Kryptowährung und digitalen Zahlungen.",
    ourMission: "Unsere Mission",
    discoverOurSolutions01: "Entdecken",
    discoverOurSolutions02: "Unsere Lösungen",
    ourServices01Ttitle: "Token-Entwicklung",
    ourServices01Description: "Individuelle Token-Entwicklung auf Blockchain-Netzwerken wie Ethereum, Binance Smart Chain, Solana und anderen.",
    ourServices02Ttitle: "Münz-Entwicklung",
    ourServices02Description: "Entwicklung von RWA, Meme-Münzen, Layer-1 und Layer-2, AI und anderen themenbezogenen Kryptowährungen.",
    ourServices03Ttitle: "Wallet-Integration",
    ourServices03Description: "Integration sicherer digitaler Wallets wie MetaMask und Trust Wallet für das Management von Kryptowährungen.",
    ourServices04Ttitle: "Erstellung von Whitepapers",
    ourServices04Description: "Wir brainstormen mit unseren Blockchain-Experten, um die beste Möglichkeit zur Präsentation Ihres Projekts zu ermitteln.",
    ourServices05Ttitle: "Staking Multi-Chain Lösung",
    ourServices05Description: "Multi-Chain-Staking-Lösungen, die Belohnungen für Token-Inhaber ermöglichen.",
    ourServices06Ttitle: "Smart Contract Entwicklung",
    ourServices06Description: "Entwicklung von Smart Contracts für Ihren Token. Wir bauen auf ERC20, BEP2, TRON usw.",
    ourServices07Ttitle: "ICO Landing Page",
    ourServices07Description: "Design und Entwicklung von ICO und Meme-Münzen, mit vollständiger Integration für ihren Start.",
    ourServices08Ttitle: "Partnerprogramm",
    ourServices08Description: "Die Hinzufügung eines Partnerprogramms erhöht die Reichweite Ihrer Marke, generiert passive Verkäufe und stärkt strategische Marketingpartnerschaften.",
    ourServices09Ttitle: "Airdrop und Giveaways",
    ourServices09Description: "Airdrops und Giveaways steigern die Sichtbarkeit der Marke, ziehen neue Benutzer an und fördern die Beteiligung, was eine positive Aufmerksamkeit erzeugt.",
    ourServices10Ttitle: "Compliance und Audit",
    ourServices10Description: "Regulatorische Compliance und Sicherheitsaudits, um die Legalität und Sicherheit von Projekten zu gewährleisten.",
    ourServices11Ttitle: "Beratung & Legal",
    ourServices11Description: "Wir bieten Beratung zur Unternehmensgründung, Lizenzen, regulatorische Compliance und Audits für Smart Contracts.",
    whyChoose01: "Warum",
    whyChoose02: "MintWeb3 wählen?",
    whyChooseText: "Bei MintWeb3 verstehen wir, dass das Starten und Skalieren eines Web 3.0-Projekts eine einzigartige Kombination aus technischem Know-how, innovativem Marketing und strategischer Planung erfordert.",
    expertiseWeb3: "Expertise in Web3 Technologien",
    expertise01: "Erprobte Marketingstrategien für ICOs",
    expertise02: "Individuelle Lösungen",
    expertise03: "Beratung für Fintechs",
    expertise04: "Globale Reichweite und Netzwerk",
    expertise05: "Innovation am Puls der Zeit",
  },
  viewAirdrop: {
    headerTitle: "AIRDROP / GIVEAWAY",
    headerDescription: "Ermöglichen Sie nahtlose Kampagnen, bei denen Benutzer an Airdrops teilnehmen, Aufgaben erledigen, Belohnungen beanspruchen und die Gemeinschaft vergrößern.",
    airdropTitle01: "Web3-betrieben",
    airdropTitle02: "Airdrops",
    airdropDescription01: "Unser Airdrop-Widget bietet eine nahtlose und sichere Lösung zur Verteilung von Tokens an Ihre Gemeinschaft. Benutzer können ihre Wallets einfach verbinden, sei es MetaMask, Trust Wallet oder andere, um ihre Airdrop-Belohnungen direkt über das Widget zu beanspruchen. Diese benutzerfreundliche Integration stellt sicher, dass der Airdrop-Prozess reibungslos und effizient verläuft und den Reibungspunkt für Teilnehmer reduziert.",
    airdropDescription02: "Einmal verbunden, können Benutzer ihre Tokens schnell beanspruchen, ohne komplexe Verfahren zu durchlaufen, was zu einer höheren Beteiligung und Interaktion in Ihrer Kampagne führt. Das Widget ist vollständig anpassbar, um Ihrem Branding und Tokenomics zu entsprechen und bietet ein konsistentes Erlebnis auf verschiedenen Plattformen. Ob für Promotionsveranstaltungen, Community-Building oder Token-Launches, unser Airdrop-Widget unterstützt die Nutzerakquisition und fördert langfristiges Wachstum.",
    airdropPlayButton: "Spiele Unser Spiel Auf Telegram",
    airdropServices01Title: "Airdrop Gaming",
    airdropServices01Description: "Unsere Airdrop Telegram Game Lösung bietet eine interaktive und ansprechende Möglichkeit, Tokens über ein Click-to-Earn-Spiel zu verteilen. Benutzer können direkt in Ihrer Telegram-Gruppe teilnehmen, indem sie einfache Aufgaben erledigen, wie das Klicken auf Links, das Beantworten von Fragen oder das Ausführen von Aktionen, die Airdrop-Belohnungen freischalten. Dieser spielerische Ansatz fördert nicht nur eine höhere Beteiligung, sondern erhöht auch die Interaktion und Begeisterung in der Gemeinde. Es ist ein effektives Werkzeug, um Ihre Telegram-Community zu vergrößern und Benutzer für ihr Engagement zu belohnen. Die Lösung ist einfach einzurichten und integriert sich nahtlos mit Telegram, wodurch für alle Teilnehmer eine lustige und lohnende Erfahrung garantiert wird.",
    airdropServices01Item01Title: "Erhöhte Beteiligung: ",
    airdropServices01Item01Description: "Gamifiziert den Airdrop-Prozess und fördert so eine stärkere Beteiligung.",
    airdropServices01Item02Title: "Einfache Telegram-Integration: ",
    airdropServices01Item02Description: "Vernetzt nahtlos mit Ihrer bestehenden Telegram-Gemeinschaft.",
    airdropServices01Item03Title: "Fördert Gemeinschaftswachstum: ",
    airdropServices01Item03Description: "Gewinnt neue Benutzer und behält bestehende Teilnehmer mit unterhaltsamen Belohnungen.",
    giveawayTitle01: "Web3-betrieben",
    giveawayTitle02: "Giveaway",
    giveawayDescription: "Unser Giveaway-Widget bietet eine einfache und ansprechende Möglichkeit für Projekte, Token-Giveaways zu hosten und ihre Gemeinde zu belohnen. Vollständig anpassbar, kann das Widget in Ihre Website oder Plattform integriert werden und ermöglicht es Benutzern, durch die Erledigung von einfachen Aufgaben wie dem Folgen von Social-Media-Konten, dem Teilen von Inhalten oder dem Empfehlen von Freunden teilzunehmen. Die Teilnehmer können ihren Fortschritt und ihre Einträge in Echtzeit verfolgen, was den Prozess transparent und interaktiv macht. Das Widget ist für einen nahtlosen Einsatz konzipiert und garantiert ein reibungsloses Erlebnis für Administratoren und Benutzer. Es unterstützt die Steigerung der Markensichtbarkeit, das Wachstum Ihrer Gemeinschaft und die Anregung der Benutzerbeteiligung. Ob für Marketingkampagnen, Promotionen oder Community-Building, unser Giveaway-Widget ist das perfekte Tool zur Steigerung der Interaktion und Anziehung neuer Benutzer.",
    giveawayKeyBenefits: "Hauptvorteile",
    giveawayWidget: "Web3-Mint-Giveaway",
    giveawayKeyBenefits01: "Fördert aktive Beteiligung, indem Benutzer für die Erledigung von Aufgaben belohnt werden und dadurch tiefere Verbindungen und Treue innerhalb Ihrer Gemeinschaft aufgebaut werden.",
    giveawayKeyBenefits02: "Integrieren Sie das Giveaway-Widget einfach in Ihre Plattform, mit vollständig anpassbaren Optionen, um sie an Ihr Branding und Ihre Ziele anzupassen.",
    giveawayKeyBenefits03: "Die Teilnehmer können ihren Fortschritt in Echtzeit verfolgen, was Transparenz und Vertrauen während des gesamten Giveaway-Prozesses sichert.",
  },
  viewContact: {
    header01: "Kontakt",
    header02: "Uns",
    description: "Nehmen Sie Kontakt mit uns auf für Anfragen, Feedback oder Unterstützung. Wir sind hier, um Ihnen zu helfen!",
    schedule: "Vereinbaren Sie ein Treffen oder kontaktieren Sie uns!",
  },
  viewMarketing: {
    headerTitle: "MARKETING",
    headerDescription: "Web 3.0-Marketing nutzt dezentrale Plattformen, NFTs und Blockchain, um transparente, personalisierte und gemeinschaftszentrierte Kampagnen zu erstellen.",
    web3MarketingHeader01: "Erstellen Sie eine",
    web3MarketingHeader02: "Gemeinschaft",
    web3MarketingDescription01: "In der schnelllebigen Welt von Web3 ist es nicht genug, einfach ein Projekt zu entwickeln – Sie benötigen eine umfassende Marketingstrategie, um Aufmerksamkeit zu erregen, Vertrauen aufzubauen und Engagement zu fördern. Bei MINTWEB3 bieten wir eine vollständige Palette von Web3-Marketingdienstleistungen an, die den Erfolg Ihres Projekts gewährleisten, ob Sie ein ICO starten, einen Token-Vorverkauf verwalten oder eine Community um Ihre dezentrale Anwendung (dApp) aufbauen.",
    web3MarketingDescription02: "Unser Team verbindet tiefgreifendes Branchenwissen mit innovativen Marketingtechniken, um Ihrem Web3-Projekt die Sichtbarkeit und Glaubwürdigkeit zu geben, die es braucht, um auf dem heutigen wettbewerbsintensiven Markt zu bestehen.",
    web3ServicesHeader01: "Marketing",
    web3ServicesHeader02: "Dienstleistungen",
    marketing01Title: "Optimierung von Whitepapers",
    marketing01Description: "Das Whitepaper ist der Pfeiler jedes Web3-Projekts und dient als detailliertes Dokument, das die Technologie, Vision und das Geschäftsmodell hinter Ihrem Projekt erklärt. Bei MintWeb3 helfen wir Ihnen, Ihr Whitepaper zu entwickeln oder zu optimieren, um die Ziele und den Wert Ihres Projekts klar an Investoren und die Community zu kommunizieren.",
    marketing01Item01Title: "Klarheit und Präzision: ",
    marketing01Item01Description: "Wir stellen sicher, dass Ihr Whitepaper leicht zu verstehen ist und gleichzeitig die notwendigen technischen Details abdeckt.",
    marketing01Item02Title: "Fesselndes Storytelling: ",
    marketing01Item02Description: "Wir verwenden klare, prägnante Sprache, um sowohl technische als auch nicht-technische Zuhörer zu erreichen, und präsentieren Ihr Projekt auf eine Weise, die Vertrauen aufbaut.",
    marketing02Title: "Gestaltung der ICO-Landingpage",
    marketing02Description: "Eine gut gestaltete Landingpage ist entscheidend, um das Interesse der Investoren während eines ICO oder Vorverkaufs zu wecken. Unser Team erstellt konvertierungsstarke Landingpages, die die Vorteile Ihres Projekts hervorheben, die Glaubwürdigkeit Ihres Teams unter Beweis stellen und es den Besuchern leicht machen, teilzunehmen.",
    marketing02Item01Title: "Benutzerfreundliches Design: ",
    marketing02Item01Description: "Unsere Landingpages sind visuell ansprechend und einfach zu navigieren, mit klaren Handlungsaufforderungen.",
    marketing02Item02Title: "Optimiert für Konversionen: ",
    marketing02Item02Description: "Wir konzentrieren uns auf die Erstellung von Landingpages, die Anmeldungen, Token-Käufe und Community-Engagement fördern.",
    marketing03Title: "ICO-Management",
    marketing03Description: "Das Management eines ICO erfordert eine sorgfältige Planung und Ausführung. MinWeb3 bietet vollständige ICO-Managementdienste an, die Ihnen helfen, jeden Aspekt Ihres Token-Verkaufs zu planen und durchzuführen, von Marketing und Investorenpflege bis hin zum Management des Fundraising-Prozesses.",
    marketing03Item01Title: "Fundraising-Strategie: ",
    marketing03Item01Description: "Wir helfen Ihnen, einen strategischen Ansatz für Ihr ICO zu entwickeln, realistische Ziele zu setzen und ein effektives Tokenomics-Modell zu entwerfen.",
    marketing03Item02Title: "End-to-End-Unterstützung: ",
    marketing03Item02Description: "Wir kümmern uns um alles, vom Pre-Sale bis zur Token-Distribution, und sorgen für einen reibungslosen und konformen ICO-Prozess.",
    marketing04Title: "Hilfe bei der Listing auf Börsen",
    marketing04Description: "Es ist wichtig, dass Ihr Token auf großen Kryptowährungsbörsen gelistet wird. Wir helfen Ihnen dabei, Ihr Token auf Top-Tier-Börsen zu listen, um nach Abschluss des ICO Liquidität und Sichtbarkeit für Ihr Token zu gewährleisten.",
    marketing04Item01Title: "Börsenforschung: ",
    marketing04Item01Description: "Wir ermitteln die besten Börsen für Ihr Token, basierend auf Ihrem Zielpublikum und Ihrer Tokenomics.",
    marketing04Item02Title: "Antragstellung und Verhandlung: ",
    marketing04Item02Description: "Wir übernehmen den Bewerbungsprozess und verhandeln in Ihrem Auftrag, um günstige Listing-Bedingungen zu sichern.",
    marketing05Title: "ICO-Listing auf beliebten Plattformen",
    marketing05Description: "Neben Börsenlistings helfen wir dabei, Ihr ICO auf prominenten ICO-Tracking- und -Listing-Plattformen zu listen, um Ihrem Projekt eine breite Sichtbarkeit bei Investoren und Enthusiasten zu bieten.",
    marketing05Item01Title: "Sichtbarkeit: ",
    marketing05Item01Description: "Wir helfen Ihnen, einen strategischen Ansatz für Ihr ICO zu entwickeln, realistische Ziele zu setzen und ein effektives Tokenomics-Modell zu entwerfen.",
    marketing05Item02Title: "Vertrauen und Engagement: ",
    marketing05Item02Description: "Das Listing auf namhaften Plattformen hilft, das Vertrauen potenzieller Investoren und Gemeinschaften aufzubauen.",
    marketing06Title: "Community-Management",
    marketing06Description: "Den Aufbau und die Verwaltung einer lebendigen, engagierten Gemeinschaft ist entscheidend für den langfristigen Erfolg jedes Web3-Projekts. Wir helfen Ihnen, Gemeinschaften auf Schlüsselplattformen wie Telegram, Tik Tok, X, Instagram und Discord zu verwalten, damit Ihre Unterstützer informiert, engagiert und begeistert von Ihrem Projekt bleiben.",
    marketing06Item01Title: "Community-Wachstum: ",
    marketing06Item01Description: "Wir helfen, Ihre Community durch aktive Beteiligung, Wettbewerbe und Content-Erstellung zu vergrößern.",
    marketing06Item02Title: "24/7 Moderation: ",
    marketing06Item02Description: "Wir übernehmen den Bewerbungsprozess und verhandeln in Ihrem Auftrag, um günstige Listing-Bedingungen zu sichern.",
    marketing07Title: "Website-Audit und SEO",
    marketing07Description: "Die Website Ihres Projekts ist oft der erste Kontakt Punkt für potenzielle Investoren und Nutzer. Wir führen ein detailliertes Audit Ihrer Website durch, um sicherzustellen, dass sie für Suchmaschinen (SEO) optimiert ist und eine reibungslose Benutzererfahrung bietet.",
    marketing07Item01Title: "SEO-Optimierung: ",
    marketing07Item01Description: "Wir implementieren SEO-Best-Practices, um sicherzustellen, dass Ihre Website höher in Suchmaschinen eingestuft wird und organischen Traffic anzieht.",
    marketing07Item02Title: "Verbesserung der Benutzererfahrung (UX): ",
    marketing07Item02Description: "Wir beurteilen und verbessern die Benutzerfreundlichkeit Ihrer Website, um es den Besuchern leichter zu machen, zu navigieren und Maßnahmen zu ergreifen.",
    marketing08Title: "Copywriting für Web3-Projekte",
    marketing08Description: "Inhalt ist der Schlüssel, um ein starkes Narrativ um Ihr Web3-Projekt zu bauen. Unser Team erfahrener Autoren erstellt hochwertige Inhalte für jede Phase Ihres Projekts, von Whitepapers und Blogposts bis hin zu Social Media-Updates und Pressemitteilungen.",
    marketing08Item01Title: "Engaging Content: ",
    marketing08Item01Description: "Wir verfassen klare, überzeugende Inhalte, die den Wert Ihres Projekts einem breiten Publikum kommunizieren.",
    marketing08Item02Title: "Konsistenz über alle Kanäle hinweg: ",
    marketing08Item02Description: "Von Ihrer Website bis hin zu Ihren Social Media-Konten sorgen wir für eine einheitliche Botschaft und Tonfall auf allen Plattformen.",
    marketing09Title: "Erstellung von Grafik- und Videoinhalt",
    marketing09Description: "Visueller Inhalt ist unerlässlich, um Aufmerksamkeit zu erregen und komplexe Ideen schnell zu vermitteln. Unser kreatives Team gestaltet Grafiken, Videos und Animationen, die Ihr Projekt hervorheben und Ihre Vision effektiv kommunizieren.",
    marketing09Item01Title: "Explainer Videos: ",
    marketing09Item01Description: "Wir erstellen fesselnde Explainer-Videos, die die Technologie und Vorteile Ihres Projekts erläutern.",
    marketing09Item02Title: "Grafikdesign: ",
    marketing09Item02Description: "Von der Erstellung von Logos bis hin zu Bannerwerbung erstellen wir visuelle Assets, die die Sichtbarkeit und den Reiz Ihrer Marke erhöhen.",
    marketing10Title: "Stickersets und GIFs",
    marketing10Description: "Steigern Sie das Engagement auf Social Media und Community-Plattformen mit lustigen, markenspezifischen Stickersets und GIFs, die bei Ihrem Publikum Anklang finden. Diese Assets sind perfekt, um die Sichtbarkeit während Airdrops, Giveaways und Community-Events zu erhöhen.",
    marketing10Item01Title: "Benutzerdefinierte Sticker: ",
    marketing10Item01Description: "Wir erstellen einzigartige Stickersets, die die Persönlichkeit und das Branding Ihres Projekts widerspiegeln.",
    marketing10Item02Title: "Promotionale GIFs: ",
    marketing10Item02Description: "Teilbare GIFs, die auf Social Media-Plattformen eingesetzt werden können, um die Sichtbarkeit Ihres Projekts zu erhöhen.",
    marketing11Title: "Promotionale Banner",
    marketing11Description: "Unser Designteam erstellt auffällige Banner für Websites, Blogs und Social Media. Diese Banner sind darauf ausgelegt, Schlüsselaspekte Ihres Projekts zu fördern, wie z.B. bevorstehende Veranstaltungen, Token-Verkäufe oder neue Features.",
    marketing11Item01Title: "Hochwertiges Design: ",
    marketing11Item01Description: "Unsere Banner sind visuell auffällig und darauf ausgelegt, Aufmerksamkeit zu erregen.",
    marketing11Item02Title: "Zielgerichtete Botschaften: ",
    marketing11Item02Description: "Jedes Banner vermittelt eine klare, aktionsorientierte Botschaft, die das Interesse und Engagement fördert.",
    marketing12Title: "Influencer und virales Marketing",
    marketing12Description: "Nutzen Sie die Macht von Influencern und viralen Inhalten, um die Reichweite Ihres Projekts zu erhöhen. Wir arbeiten mit führenden Krypto-Influencern zusammen und implementieren virale Marketingkampagnen, die Aufmerksamkeit für Ihr Projekt erzeugen.",
    marketing12Item01Title: "Zusammenarbeit mit Influencern: ",
    marketing12Item01Description: "Wir bringen Sie mit führenden Influencern in der Krypto-Welt in Kontakt, um Ihr Projekt ihrem Publikum zu präsentieren.",
    marketing12Item02Title: "Virale Marketingkampagnen: ",
    marketing12Item02Description: "Wir erstellen Inhalte, die darauf ausgelegt sind, viral zu gehen und maximale Aufmerksamkeit und Engagement zu erzeugen.",
  },
  viewPayments: {
    headerTitle: "VORVERKAUF",
    headerDescription: "MintWebs Crypto Toolkit vereinfacht Starts mit automatisierten Funktionen, Sicherheit und anpassbaren Lösungen für erfolgreiche Vorverkäufe.",
    paymentsTitle01: "Web3-Powered",
    paymentsTitle02: "Zahlungen",
    paymentsDescription01: "MintWebs Web3 Payment Widget ist ein leistungsstarkes Werkzeug, das speziell entwickelt wurde, um Vorverkaufsprozesse für Token-Projekte zu optimieren. Mit vollständiger Multi-Währungs-Unterstützung können Benutzer problemlos in verschiedenen Kryptowährungen bezahlen und so die Reichweite Ihres Projekts auf ein globales Publikum ausdehnen. Die integrierte Swap-Funktionalität ermöglicht es Anlegern, nahtlos Vermögenswerte zu konvertieren, was die Teilnahme am Vorverkauf unabhängig vom Token-Besitz erleichtert.",
    paymentsDescription02: "Unsere Lösung beinhaltet auch eingebaute Affiliate-Marketing-Funktionen, die es Projekten ermöglichen, Benutzer für die Generierung von Traffic und das Gewinnen neuer Teilnehmer zu belohnen. Dies fördert das Wachstum und Engagement der Community und steigert den Erfolg des Vorverkaufs. Das Widget gibt Projekten volle Kontrolle über Vorverkaufsphasen, erlaubt dynamische Anpassungen und bietet ein anpassbares Erlebnis, das sich verschiedenen Fundraising-Strategien anpasst.",
    paymentsDescription03: "Darüber hinaus unterstützt MintWebs Web3 Payment Widget Kartenbezahlungen und beseitigt somit Barrieren für Nicht-Krypto-Nutzer, an Token-Verkäufen teilzunehmen. Durch die Vereinfachung des Zahlungsprozesses stellen Sie sicher, dass jeder teilnehmen kann, unabhängig von seiner Erfahrung mit Kryptowährungen.",
    paymentsDescription04: "Mit robuster Sicherheit, nahtloser Integration und benutzerfreundlichen Funktionen ist MintWebs Web3 Payment Widget die All-in-One-Lösung, um Ihren Vorverkauf zu optimieren, die Zugänglichkeit zu erhöhen und das Wachstum Ihres Projekts voranzutreiben. Es ist das perfekte Werkzeug für jede Web3-Initiative, die Effizienz und Inklusivität maximieren möchte.",
    multiWalletTittle01: "Multi-Wallet",
    multiWalletTittle02: "Integration",
    multiWalletDescription: "MintWebs Multi-Wallet-Integration bietet Benutzern die Flexibilität, mehrere Wallets während Vorverkäufen zu verbinden und zu verwalten, einschließlich beliebter Optionen wie MetaMask, Trust Wallet und andere. Diese nahtlose Integration stellt sicher, dass Teilnehmer problemlos zwischen Wallets wechseln können, was den Transaktionsprozess vereinfacht. Mit dieser Funktion verbessern wir die Zugänglichkeit für ein breiteres Publikum und erleichtern sowohl kryptoerfahrenen Benutzern als auch Neulingen die Teilnahme an Vorverkäufen.",
    multiWalletBenefitsTitle: "Schlüsselfunktionen",
    multiWalletBenefits01: "Vereinfachtes Wallet-Management;",
    multiWalletBenefits02: "Breite Wallet-Kompatibilität;",
    multiWalletBenefits03: "Erhöhte Benutzerzugänglichkeit;",
    coinLaunchTittle01: "Münzstart",
    coinLaunchTittle02: "Toolkit",
    coinLaunchDescription01: "MintWebs Coin Launch Toolkit ist speziell für den Start von Meme-Münzen, mit Real-World-Assets (RWA) besicherten Tokens und mehr konzipiert. Es vereinfacht die Token-Erstellung, das Vorverkaufsmanagement und den Einsatz von Smart Contracts mit maßgeschneiderten Funktionen, die auf einzigartige Anwendungsfälle zugeschnitten sind. Ob Sie eine lustige Meme-Münze oder einen seriösen mit RWA besicherten Token starten, unser Toolkit gewährleistet eine sichere, effiziente Ausführung.",
    coinLaunchDescription02: "Mit Optionen für Distribution, Anlegertracking und Skalierbarkeit hilft Ihnen MintWeb, Ihre Vision zum Leben zu erwecken, während Transparenz und Sicherheit gewahrt bleiben.",
    coinLaunchItems01: "Münzen erhalten",
    coinLaunchItems02: "Münzenkauf-Integration",
    coinLaunchItems03: "Staking Multichain",
    coinLaunchItems04: "Entwicklung von Smart Contracts",
    coinLaunchItems05: "Token beanspruchen",
    coinLaunchItems06: "Erstellung von Whitepapers",
  },
  viewStaking: {
    headerTitle: "STAKING",
    headerDescription: "Wir bieten anpassbare, sichere, Multi-Chain-Unterstützung, flexible Modelle, um das Community-Engagement und das Token-Wachstum zu steigern.",
    stakingAdvantagesTitle01: "Staking",
    stakingAdvantagesTitle02: "Vorteile",
    stakingAdvantages01Title: "Hauptmerkmale",
    stakingAdvantages01Description: "Mint Web3 Staking-Dienstleistungen bieten eine sichere, anpassbare Lösung für Token-Projekte, die die Benutzerbeteiligung erhöhen und langfristiges Halten fördern möchten. Unser Staking-Widget kann leicht in Ihre Plattform integriert werden und bietet einen nahtlosen Service für Benutzer, um in mehreren Blockchain-Netzwerken zu staken. Das Widget ist vollständig anpassbar und ermöglicht eine Anpassung an Ihre Marke und Tokenomics. Durch das Angebot flexibler Staking-Modelle wie Einzel-Token und Liquiditäts-Pool-Staking hilft MintWeb3, ein Ökosystem zu schaffen, das Ihren spezifischen Zielen und Belohnungsstrukturen entspricht.",
    stakingAdvantages01Item01Title: "Multi-Chain Staking: ",
    stakingAdvantages01Item01Description: "Staking über verschiedene Blockchain-Netzwerke für eine größere Reichweite.",
    stakingAdvantages01Item02Title: "Anpassbares Widget: ",
    stakingAdvantages01Item02Description: "Passen Sie die Staking-Schnittstelle an Ihre Marke und Tokenomics an.",
    stakingAdvantages01Item03Title: "Benutzerfreundliche Oberfläche: ",
    stakingAdvantages01Item03Description: "Nahtlose Integration für ein intuitives Staking-Erlebnis.",
    stakingAdvantages02Title: "Hauptvorteile",
    stakingAdvantages02Description: "Durch die Reduzierung des umlaufenden Angebots durch Staking können Projekte Knappheit erzeugen, was sich positiv auf den Wert des Tokens auswirken kann. Dies fördert eine engagiertere und treue Gemeinschaft und unterstützt das langfristige Wachstum Ihres Ökosystems. Wir verwenden branchenführende Verschlüsselungsprotokolle, um die Sicherheit aller gestakten Vermögenswerte zu gewährleisten und den Teilnehmern Ruhe zu bieten.",
    stakingAdvantages02Item01Title: "Robuste Sicherheit: ",
    stakingAdvantages02Item01Description: "Fortgeschrittene Verschlüsselung zum Schutz von gestakten Vermögenswerten.",
    stakingAdvantages02Item02Title: "Erhöhter Token-Wert: ",
    stakingAdvantages02Item02Description: "Reduzierung des Umlaufangebots durch Staking kann Token-Knappheit erhöhen.",
    stakingAdvantages02Item03Title: "Community Engagement: ",
    stakingAdvantages02Item03Description: "Die Anreizschaffung für Token-Inhaber führt zu größerer Loyalität und Teilnahme.",
  },
  community: {
    guidelines: "Gemeinschaft",
    guidelinesSubtitle: "Richtlinien",
    title: "MINTWEB3 Gemeinschaftsrichtlinien",
    welcomeMessage: "Willkommen in der MINTWEB3-Gemeinschaft!",
    purposeMessage: "Bei MINTWEB3 streben wir danach, eine blühende, inklusive und innovative Umgebung zu schaffen, die Einzelpersonen und Unternehmen ermutigt, Web 3.0-Technologien einzusetzen.",
    participationMessage: "Indem Sie an der MINTWEB3-Gemeinschaft teilnehmen - ob auf unserer Plattform, in sozialen Medien oder in zugehörigen Foren - stimmen Sie zu, die folgenden Richtlinien einzuhalten.",
    coreValuesTitle: "Kernwerte",
    coreValuesDescription: "Wir erwarten, dass alle Gemeinschaftsmitglieder die folgenden Kernwerte einhalten:",
    coreValuesRespect: "Respekt: Behandeln Sie alle Mitglieder mit Würde und Respekt. Meinungsverschiedenheiten sind natürlich, aber Zivilität ist obligatorisch.",
    coreValuesCollaboration: "Zusammenarbeit: Unterstützen Sie einander durch den Austausch von Wissen, Fähigkeiten und konstruktivem Feedback.",
    coreValuesIntegrity: "Integrität: Bewahren Sie Ehrlichkeit und Transparenz in allen Interaktionen.",
    coreValuesInnovation: "Innovation: Fördern und feiern Sie Kreativität, sei es in Form von Ideen, Inhalten oder Lösungen im Zusammenhang mit Web 3.0.",
    respectfulCommunicationTitle: "Respektvolle und inklusive Kommunikation",
    respectfulCommunicationDescription: "Wir sind bestrebt, eine inklusive Gemeinschaft zu erhalten, in der alle Stimmen gehört werden können. Das bedeutet:",
    respectfulCommunicationNoHarassment: "Keine Toleranz gegenüber Belästigung: Belästigung, Missbrauch, Mobbing oder Drohungen jeglicher Art werden nicht toleriert.",
    respectfulCommunicationNoHateSpeech: "Keine Hassreden: Inhalte oder Verhaltensweisen, die Gewalt, Hass oder Diskriminierung fördern oder anstacheln, sind strengstens verboten.",
    respectfulCommunicationConstructiveCriticism: "Nur konstruktive Kritik: Feedback muss respektvoll sein und sich auf Lösungen konzentrieren, nicht auf persönliche Angriffe.",
    prohibitedConductTitle: "Verbotenes Verhalten",
    prohibitedConductDescription: "Um eine positive Umgebung zu erhalten, sind die folgenden Verhaltensweisen verboten:",
    prohibitedConductNoSpam: "Spam und unerbetene Werbung: Irrelevante oder sich wiederholende Inhalte sind nicht erlaubt.",
    prohibitedConductNoImpersonation: "Täuschung: Es ist verboten, sich als MINTWEB3-Mitarbeiter, Moderatoren oder andere Benutzer auszugeben.",
    prohibitedConductNoIllegalActivities: "Illegale Aktivitäten: Inhalte, die illegale Aktivitäten fördern, werden nicht toleriert.",
    prohibitedConductNoMaliciousBehavior: "Schädliches Verhalten: Stören Sie die Gemeinschaft nicht durch Hacking, Phishing oder Malware.",
    intellectualPropertyTitle: "Schutz des geistigen Eigentums",
    intellectualPropertyDescription: "Wir respektieren das geistige Eigentum (IP) anderer und erwarten, dass unsere Gemeinschaftsmitglieder dasselbe tun:",
    intellectualPropertyNoCopyright: "Keine Urheberrechtsverletzungen: Veröffentlichen Sie keine Inhalte, die Urheberrechte oder Markenrechte verletzen.",
    intellectualPropertyOriginalContent: "Originalinhalt: Stellen Sie sicher, dass Sie die Berechtigung haben, Inhalte zu teilen, oder dass Sie die Rechte daran besitzen.",
    intellectualPropertyAttribution: "Zuschreibung: Geben Sie den ursprünglichen Autoren und Schöpfern das verdiente Lob.",
    securityPrivacyTitle: "Sicherheit und Privatsphäre",
    securityPrivacyDescription: "Ihre Sicherheit und Privatsphäre sind unsere oberste Priorität. Bitte befolgen Sie diese Praktiken:",
    securityPrivacyNoPersonalInfo: "Teilen Sie keine persönlichen Informationen: Vermeiden Sie die öffentliche Weitergabe sensibler persönlicher Informationen.",
    securityPrivacyProtectAccount: "Schützen Sie Ihr Konto: Verwenden Sie starke Passwörter und die Zwei-Faktor-Authentifizierung (2FA).",
    securityPrivacyReportIssues: "Melden Sie Sicherheitsprobleme: Melden Sie sofortige Schwachstellen an unser Support-Team.",
    contentStandardsTitle: "Inhaltsstandards",
    contentStandardsDescription: "Um die Gemeinschaft hilfreich, relevant und professionell zu halten, befolgen Sie diese Inhaltsstandards:",
    contentStandardsRelevance: "Relevanz: Stellen Sie sicher, dass alle Beiträge mit Web 3.0, Blockchain oder dezentraler Finanzierung in Verbindung stehen.",
    contentStandardsClarityAccuracy: "Klarheit und Genauigkeit: Stellen Sie klare, prägnante und korrekte Informationen bereit. Falschinformationen werden nicht toleriert.",
    contentStandardsNoInappropriateContent: "Keine NSFW- oder unangemessenen Inhalte: Vermeiden Sie die Weitergabe sexuell expliziten oder anderweitig unangemessenen Inhalts.",
    moderationEnforcementTitle: "Moderation und Durchsetzung",
    moderationEnforcementDescription: "Wir nehmen Verstöße ernst und unsere Moderatoren setzen diese Richtlinien durch:",
    moderationEnforcementWarnings: "Warnungen: Geringfügige Verstöße können zu einer Warnung führen.",
    moderationEnforcementContentRemoval: "Inhaltsentfernung: Beiträge, die gegen die Richtlinien verstoßen, können entfernt werden.",
    moderationEnforcementSuspensionBan: "Suspendierung oder Verbot: Schwerwiegende Verstöße können zu einem Verbot führen.",
    moderationEnforcementAppeals: "Berufungen: Benutzer können Berufung einlegen, wenn sie glauben, dass die Moderation unfair war.",
    reportingViolationsTitle: "Melden von Verstößen",
    reportingViolationsDescription: "Helfen Sie uns, die Gemeinschaft sicher zu halten, indem Sie Verstöße melden. Berichte sind vertraulich und Vergeltung wird nicht toleriert.",
    thirdPartyLinksTitle: "Links und Inhalte von Drittanbietern",
    thirdPartyLinksDescription: "MINTWEB3 ist nicht verantwortlich für Inhalte von Drittanbietern, die innerhalb der Gemeinschaft verlinkt sind. Verwenden Sie externe Links nach eigenem Ermessen.",
    updatesTitle: "Updates der Richtlinien",
    updatesDescription: "Wir können diese Richtlinien nach Bedarf aktualisieren. Die Teilnahme nach Updates zeigt die Akzeptanz der neuen Regeln an.",
    contactTitle: "Kontaktiere uns",
    contactDescription: "Wenn Sie Fragen haben, kontaktieren Sie uns bitte.",
  },
  cookiePolicy: {
    titleCookie: "Cookie",
    titlePolicy: "Richtlinie",
    titleFull: "Cookie-Richtlinie",
    introHeader: "MINT WEB3 Cookie-Richtlinie",
    introEffectiveDate: "Gültig ab: 18/10/2024",
    introParagraph: "Bei MINTWEB3 schätzen wir Ihre Privatsphäre und bemühen uns um Transparenz in Bezug auf unsere Datenerfassung und -nutzung. Diese Cookie-Richtlinie erklärt, was Cookies sind, wie wir sie verwenden und welche Optionen Sie haben, wenn es um ihre Benutzung auf unserer Plattform geht.",
    whatAreCookiesTitle: "Was sind Cookies?",
    whatAreCookiesDescription: "Cookies sind kleine Textdateien, die auf Ihrem Computer oder Mobilgerät platziert werden, wenn Sie Websites besuchen. Cookies ermöglichen es Websites, Ihr Gerät zu erkennen, Ihre Benutzererfahrung zu verbessern und Einstellungen oder andere Informationen für zukünftige Besuche zu speichern.",
    whatAreCookiesTypesSessionCookies: "Session-Cookies: Temporäre Cookies, die ablaufen, wenn Sie Ihren Browser schließen.",
    whatAreCookiesTypesPersistentCookies: "Persistente Cookies: Diese Cookies bleiben für einen festgelegten Zeitraum auf Ihrem Gerät oder bis Sie sie löschen.",
    whatAreCookiesTypesFirstPartyCookies: "First-Party-Cookies: Diese werden von der von Ihnen besuchten Website gesetzt.",
    whatAreCookiesTypesThirdPartyCookies: "Third-Party-Cookies: Diese werden von Dritten (wie Analyse- oder Werbeplattformen) gesetzt, um Ihre Online-Aktivität auf verschiedenen Websites zu verfolgen.",
    whyUseCookiesTitle: "Warum verwenden wir Cookies?",
    whyUseCookiesDescription: "MINTWEB3 verwendet Cookies, um die Benutzererfahrung auf unserer Plattform zu verbessern. Konkret verwenden wir Cookies für folgende Zwecke:",
    whyUseCookiesPurposesEssentialCookiesTitle: "Wesentliche Cookies",
    whyUseCookiesPurposesEssentialCookiesDescription: "Diese Cookies sind für die Funktion unserer Website und Dienstleistungen von entscheidender Bedeutung. Sie ermöglichen grundlegende Funktionen wie die Navigation zwischen Seiten, den Zugriff auf sichere Bereiche der Website und die reibungslose Funktionalität unserer Dienstleistungen.",
    whyUseCookiesPurposesEssentialCookiesExample: "Beispiel: Authentifizierungscookies, die Sie während Ihrer Sitzung angemeldet halten.",
    whyUseCookiesPurposesPerformanceCookiesTitle: "Performance- und Analyse-Cookies",
    whyUseCookiesPurposesPerformanceCookiesDescription: "Performance-Cookies helfen uns zu analysieren, wie Besucher mit unserer Plattform interagieren. Diese Daten verwenden wir, um die Leistung der Website zu verbessern, technische Probleme zu beheben und die Benutzererfahrung zu optimieren.",
    whyUseCookiesPurposesPerformanceCookiesExample: "Beispiel: Google Analytics-Cookies, die Daten über das Benutzerverhalten sammeln, einschließlich der besuchten Seiten, der auf Seiten verbrachten Zeit und der Herkunft des Traffic.",
    whyUseCookiesPurposesFunctionalityCookiesTitle: "Funktionalitäts-Cookies",
    whyUseCookiesPurposesFunctionalityCookiesDescription: "Diese Cookies ermöglichen es der Website, von Ihnen in der Vergangenheit gemachte Auswahlmöglichkeiten, wie Ihre Sprachpräferenzen, Standort oder andere personalisierte Einstellungen, zu speichern, und verbessern die Benutzererfahrung, indem sie die Plattform auf Ihre Bedürfnisse anpassen.",
    whyUseCookiesPurposesFunctionalityCookiesExample: "Beispiel: Erinnerung an Ihre bevorzugten Spracheinstellungen.",
    whyUseCookiesPurposesMarketingCookiesTitle: "Marketing- und Werbe-Cookies",
    whyUseCookiesPurposesMarketingCookiesDescription: "Marketing-Cookies werden verwendet, um Besucher über Websites hinweg zu verfolgen. Ihr Zweck besteht darin, Anzeigen anzuzeigen, die für den einzelnen Benutzer relevant und ansprechend sind. Diese Cookies können von uns oder von Drittpartnern (wie Werbenetzwerken) gesetzt werden.",
    whyUseCookiesPurposesMarketingCookiesExample: "Beispiel: Cookies, die das Engagement mit unseren Werbekampagnen verfolgen und uns helfen, gezielte Anzeigen zu liefern.",
    whyUseCookiesPurposesSocialMediaCookiesTitle: "Social-Media-Cookies",
    whyUseCookiesPurposesSocialMediaCookiesDescription: "Diese Cookies ermöglichen es Ihnen, unsere Inhalte auf Social-Media-Plattformen wie Facebook, Twitter und LinkedIn zu teilen oder mit eingebetteten Inhalten dieser Dienste zu interagieren. Diese Cookies können Ihre Aktivität auf verschiedenen Websites verfolgen.",
    whyUseCookiesPurposesSocialMediaCookiesExample: "Beispiel: Teilen-Buttons auf Blogposts oder Produktseiten, die sich mit Social-Media-Netzwerken integrieren.",
    cookieTypesTitle: "Welche Arten von Cookies verwenden wir?",
    cookieTypesDescription: "Nachfolgend finden Sie eine Übersicht über die auf unserer Plattform verwendeten Cookie-Typen:",
    cookieTypesTableHeadersCookieType: "Art des Cookies",
    cookieTypesTableHeadersPurpose: "Zweck",
    cookieTypesTableHeadersDuration: "Dauer",
    cookieTypesRowsStrictlyNecessaryType: "Unbedingt notwendige Cookies",
    cookieTypesRowsStrictlyNecessaryPurpose: "Erforderlich für wesentliche Website-Funktionen, wie das Einloggen und die Absicherung der Plattform.",
    cookieTypesRowsStrictlyNecessaryDuration: "Sitzung (Temporär)",
    cookieTypesRowsPerformanceType: "Performance-Cookies",
    cookieTypesRowsPerformancePurpose: "Analysiert die Benutzeraktivität, um die Performance der Seite und das Benutzererlebnis zu verbessern.",
    cookieTypesRowsPerformanceDuration: "Persistent (Bis zu 2 Jahre)",
    cookieTypesRowsFunctionalityType: "Funktions-Cookies",
    cookieTypesRowsFunctionalityPurpose: "Speichert Benutzereinstellungen wie Sprache und Standort für ein personalisierteres Erlebnis.",
    cookieTypesRowsFunctionalityDuration: "Persistent (1 Jahr)",
    cookieTypesRowsMarketingType: "Targeting/Marketing-Cookies",
    cookieTypesRowsMarketingPurpose: "Verfolgt die Benutzeraktivität für Werbezwecke und personalisiert die Werbeausspielung über Plattformen hinweg.",
    cookieTypesRowsMarketingDuration: "Persistent (Bis zu 6 Monate)",
    cookieTypesRowsSocialType: "Social-Media-Cookies",
    cookieTypesRowsSocialPurpose: "Ermöglicht das Teilen von Inhalten und die Interaktion mit sozialen Medien von unserer Plattform aus.",
    cookieTypesRowsSocialDuration: "Persistent (1 Jahr)",
    thirdPartyCookiesTitle: "Cookies von Drittanbietern",
    thirdPartyCookiesDescription: "Wir arbeiten mit Drittanbietern zusammen, einschließlich Analyse-, Marketing- und Social-Media-Partnern, die Cookies in unserem Auftrag setzen. Diese Dritten können die durch Cookies gesammelten Informationen für ihre eigenen Zwecke verwenden, wie zum Beispiel die Verbesserung ihrer Dienstleistungen oder das Anpassen von Werbung.",
    thirdPartyCookiesExamplesGoogleAnalytics: "Google Analytics: Verfolgt Benutzerinteraktionen und Website-Leistung.",
    thirdPartyCookiesExamplesFacebookAds: "Facebook Ads: Liefert personalisierte Anzeigen basierend auf Ihrer Aktivität auf unserer Website.",
    thirdPartyCookiesExamplesSocialPlatforms: "Twitter, LinkedIn, usw.: Für das Teilen und Interagieren auf sozialen Plattformen.",
    thirdPartyCookiesNote: "Bitte beachten Sie die Datenschutz- und Cookie-Richtlinien dieser Dritten für detaillierte Informationen darüber, wie sie Cookies verwenden.",
    controlCookiesTitle: "Wie können Sie Cookies steuern?",
    controlCookiesDescription: "Sie haben das Recht, Ihre Cookie-Einstellungen zu verwalten und zu kontrollieren. Sie können dies auf folgende Weisen tun:",
    controlCookiesMethodsBrowserSettingsTitle: "Browsereinstellungen",
    controlCookiesMethodsBrowserSettingsDescription: "Die meisten Webbrowser ermöglichen es Ihnen, Cookies über ihre Einstellungen zu steuern. Sie können wählen, ob Sie Cookies blockieren oder löschen oder informiert werden möchten, wenn ein Cookie auf Ihrem Gerät gesetzt wird. Bitte beachten Sie, dass das Deaktivieren von Cookies die Funktionalität bestimmter Teile unserer Website beeinträchtigen kann.",
    controlCookiesMethodsBrowserSettingsLinksChrome: "Für Google Chrome: (https://support.google.com/chrome/answer/95647)",
    controlCookiesMethodsBrowserSettingsLinksFirefox: "Für Mozilla Firefox: (https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen)",
    controlCookiesMethodsBrowserSettingsLinksSafari: "Für Safari: (https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac)",
    controlCookiesMethodsBrowserSettingsLinksEdge: "Für Microsoft Edge: (https://support.microsoft.com/de-de/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d)",
    controlCookiesMethodsOptOutTitle: "Widerspruchsrecht gegen Cookies von Drittanbietern",
    controlCookiesMethodsOptOutDescription: "Sie können sich von marketing- und werbebezogenen Cookies von Drittanbietern abmelden, indem Sie ihre Opt-Out-Seiten besuchen:",
    controlCookiesMethodsOptOutLinksGoogleAds: "Google Anzeigen Präferenzen: (https://myadcenter.google.com/home?sasb=true&ref=ad-settings)",
    controlCookiesMethodsOptOutLinksFacebookAds: "Facebook Werbepräferenzen: (https://www.facebook.com/settings?tab=ads)",
    controlCookiesMethodsOptOutLinksYourChoices: "Ihre Online-Präferenzen: (https://www.youronlinechoices.com/)",
    controlCookiesMethodsCookieConsentBannerTitle: "Cookie-Zustimmungsbanner",
    controlCookiesMethodsCookieConsentBannerDescription: "Bei Ihrem ersten Besuch bei MINTWEB3 sehen Sie ein Banner zur Zustimmung zu Cookies. Sie können über dieses Banner wählen, ob Sie Cookies akzeptieren oder ablehnen und Ihre Präferenzen jederzeit über die Datenschutzeinstellungen unserer Website verwalten.",
    policyChangesTitle: "Änderungen dieser Richtlinie",
    policyChangesDescription: "Wir können diese Cookie-Richtlinie von Zeit zu Zeit aktualisieren, um Veränderungen in der Technologie, dem geltenden Recht oder unseren Praktiken Rechnung zu tragen. Wenn wir wesentliche Änderungen vornehmen, werden wir Sie informieren, indem wir eine prominente Benachrichtigung auf unserer Website veröffentlichen oder Sie direkt per E-Mail oder über andere Kommunikationskanäle informieren.",
    contactTitle: "Kontaktieren Sie uns",
    contactDescription: "Wenn Sie Fragen zu dieser Cookie-Richtlinie oder zu unserer Verwendung von Cookies haben, können Sie uns gerne kontaktieren.",
  },
  termsOfService: {
    titlePart1: "Allgemeine Geschäfts",
    titlePart2: "bedingungen",
    mainTitle: "MINTWEB3 Allgemeine Geschäftsbedingungen",
    lastUpdated: "Zuletzt aktualisiert: 18/10/2024",
    intro: "Willkommen bei MINTWEB3 ('Unternehmen', 'wir', 'uns', 'unser'). Diese Allgemeinen Geschäftsbedingungen regeln Ihre Nutzung unserer Webseite, Plattform und aller damit verbundenen Dienstleistungen, die von MINTWEB3 angeboten werden (zusammenfassend die 'Dienstleistungen'). Durch den Zugriff auf oder die Nutzung unserer Dienstleistungen erklären Sie sich damit einverstanden, diesen Allgemeinen Geschäftsbedingungen ('AGB') zu entsprechen und durch sie gebunden zu sein. Wenn Sie mit diesen AGB nicht einverstanden sind, dürfen Sie unsere Dienstleistungen nicht nutzen oder darauf zugreifen.",
    acceptanceOfTermsTitle: "Annahme der Geschäftsbedingungen",
    acceptanceOfTermsContent: "Durch die Nutzung unserer Dienstleistungen erklären Sie sich damit einverstanden, an diese AGB, sowie an unsere Datenschutzrichtlinie, Cookie-Richtlinie, Nutzungsvereinbarung und alle anderen möglicherweise geltenden Rechtsdokumente gebunden zu sein. Diese AGB gelten für alle Benutzer, einschließlich, aber nicht beschränkt auf Besucher, registrierte Nutzer, Kunden und Inhaltsbeiträger.",
    servicesProvidedTitle: "Erbrachte Dienstleistungen",
    servicesProvidedContent: "MINTWEB3 ist ein Technologie- und Marketingunternehmen, das sich auf Web 3.0 Lösungen spezialisiert hat und ein breites Spektrum an Dienstleistungen anbietet, einschließlich, aber nicht beschränkt auf:",
    servicesProvidedListItem1: "Erstellung und Entwicklung von Token auf verschiedenen Blockchain-Netzwerken.",
    servicesProvidedListItem2: "Entwicklung und Auditierung von Smart Contracts.",
    servicesProvidedListItem3: "Marketingdienstleistungen für ICOs und STOs, einschließlich Erstellung von Whitepapers, Community-Management und SEO.",
    servicesProvidedListItem4: "Multi-Chain-Staking und Wallet-Integrationen.",
    servicesProvidedListItem5: "Beratungs- und Compliance-Dienstleistungen für Blockchain-Projekte.",
    servicesProvidedNotice: "Wir behalten uns das Recht vor, die Dienstleistungen jederzeit zu ändern, auszusetzen oder einzustellen, mit oder ohne Vorankündigung und ohne Haftung Ihnen gegenüber.",
    eligibilityTitle: "Zulassungskriterien",
    eligibilityContent: "Sie müssen mindestens 18 Jahre alt oder das gesetzliche Volljährigkeitsalter in Ihrem Zuständigkeitsbereich sein, um unsere Dienstleistungen nutzen zu können. Durch die Nutzung unserer Dienstleistungen versichern Sie, dass Sie rechtlich in der Lage sind, einen bindenden Vertrag einzugehen.",
    accountRegistrationTitle: "Kontoregistrierung",
    accountRegistrationContent: "Um bestimmte Funktionen unserer Dienstleistungen nutzen zu können, müssen Sie möglicherweise ein Konto registrieren. Sie erklären sich damit einverstanden:",
    accountRegistrationListItem1: "Während der Registrierung genaue, aktuelle und vollständige Informationen bereitzustellen.",
    accountRegistrationListItem2: "Die Vertraulichkeit Ihrer Kontozugangsdaten zu wahren.",
    accountRegistrationListItem3: "Uns sofort zu benachrichtigen, wenn Sie einen unbefugten Zugriff oder eine unbefugte Nutzung Ihres Kontos vermuten.",
    accountRegistrationResponsibility: "Sie sind für alle Aktivitäten verantwortlich, die unter Ihrem Konto stattfinden, und wir haften nicht für Verluste oder Schäden, die aus unbefugter Nutzung entstehen.",
    paymentTermsFeesAndChargesTitle: "Gebühren und Kosten",
    paymentTermsFeesAndChargesContent: "Alle Gebühren und Kosten im Zusammenhang mit den Dienstleistungen werden in Ihrer speziellen Vereinbarung oder auf unserer Webseite klar dargestellt. Die Bezahlung ist vor Erbringung der Dienstleistungen erforderlich, es sei denn, es wurde schriftlich anders vereinbart. Gebühren können unter anderem enthalten:",
    paymentTermsFeesAndChargesListItem1: "Entwicklungskosten für die Erstellung von Token und Smart Contracts.",
    paymentTermsFeesAndChargesListItem2: "Marketinggebühren für ICO-Kampagnen, Community-Management und PR-Dienstleistungen.",
    paymentTermsFeesAndChargesListItem3: "Beratungsgebühren für Compliance, Auditing und Strategieentwicklung.",
    paymentTermsPaymentMethodsTitle: "Zahlungsmethoden",
    paymentTermsPaymentMethodsContent: "MINTWEB3 akzeptiert Zahlungen in Kryptowährungen und Fiat-Währungen per Kreditkarte oder Banküberweisung. Die Nutzer sind für alle Transaktionsgebühren verantwortlich, einschließlich Blockchain-Netzwerkgebühren oder Kosten für Währungsumrechnungen.",
    paymentTermsLatePaymentsTitle: "Verspätete Zahlungen",
    paymentTermsLatePaymentsContent: "Wenn die Zahlung nicht bis zum Fälligkeitsdatum eingegangen ist, behalten wir uns das Recht vor, die Bereitstellung der Dienstleistungen auszusetzen oder zu beenden. Verspätete Zahlungen können auch eine Verspätungsgebühr nach sich ziehen, wie in Ihrer Vereinbarung angegeben.",
    intellectualPropertyOwnershipTitle: "Eigentum an Inhalten",
    intellectualPropertyOwnershipContent: "Sofern nicht anders angegeben, gehören alle von MINTWEB3 bereitgestellten Materialien, Inhalte und Dienstleistungen, einschließlich, aber nicht beschränkt auf Software, Grafiken, Texte und geistige Eigentumsrechte, MINTWEB3 oder seinen Lizenzgebern. Sie dürfen diese Materialien nicht kopieren, modifizieren, verteilen oder abgeleitete Werke erstellen, ohne unsere ausdrückliche schriftliche Genehmigung.",
    intellectualPropertyLicenseTitle: "Nutzungslizenz",
    intellectualPropertyLicenseContent: "Unter Vorbehalt dieser AGB erteilen wir Ihnen eine beschränkte, nicht exklusive, nicht übertragbare und widerrufliche Lizenz zum Zugriff auf und zur Nutzung der Dienstleistungen nur für Ihre internen Geschäftszwecke.",
    intellectualPropertyUserGeneratedContentTitle: "Vom Nutzer generierte Inhalte",
    intellectualPropertyUserGeneratedContentContent: "Sie behalten das Eigentum an allen Inhalten, die Sie bei MINTWEB3 einreichen. Durch die Einreichung von Inhalten gewähren Sie MINTWEB3 eine weltweite, gebührenfreie und nicht exklusive Lizenz zur Nutzung, Vervielfältigung, Verbreitung und Darstellung Ihres Inhalts zum Zwecke der Bereitstellung und Bewerbung der Dienstleistungen.",
    confidentialityTitle: "Vertraulichkeit",
    confidentialityContent: "Beide Parteien verpflichten sich zur Wahrung der Vertraulichkeit von proprietären Informationen, die im Rahmen der Nutzung der Dienstleistungen ausgetauscht werden. Dies beinhaltet unter anderem technische Daten, Geschäftsstrategien, Kundenlisten und Projektdetails. Diese Klausel bleibt auch nach Beendigung des Vertrags in Kraft.",
    complianceLegalComplianceTitle: "Rechtliche Konformität",
    complianceLegalComplianceContent: "MINTWEB3 arbeitet unter dem Rechtsrahmen von Hongkong, wo es seinen Hauptsitz hat. Sie sind dafür verantwortlich, dass Ihre Nutzung unserer Dienstleistungen den Gesetzen Ihres Zuständigkeitsbereichs entspricht.",
    complianceAmlKycTitle: "Anti-Geldwäscherei (AML) und Know Your Customer (KYC)",
    complianceAmlKycContent: "Abhängig von der Art der Dienstleistungen, können wir die Einhaltung von AML und KYC Vorschriften verlangen. Sie stimmen zu, die notwendigen Dokumente zur Überprüfung Ihrer Identität und Ihrer Geschäftsabwicklung bereitzustellen, wenn angefordert.",
    terminationByYouTitle: "Durch Sie",
    terminationByYouContent: "Sie können Ihr Konto jederzeit kündigen, indem Sie den Kundenservice kontaktieren. Sie bleiben jedoch für eventuell ausstehende Gebühren oder Kosten verantwortlich.",
    terminationByMintweb3Title: "Durch MINTWEB3",
    terminationByMintweb3Content: "Wir können Ihr Konto oder den Zugang zu den Dienstleistungen nach unserem alleinigen Ermessen, ohne Vorankündigung, wegen Verhaltens kündigen oder aussetzen, von dem wir glauben, dass es gegen diese AGB verstößt oder für andere Nutzer, uns oder Dritte übles spielt. Nach der Kündigung enden alle Ihnen auf Grundlage dieser AGB gewährten Rechte.",
    limitationOfLiabilityTitle: "Haftungsbeschränkung",
    limitationOfLiabilityContent: "In größtmöglichem gesetzlich zulässigem Umfang haften MINTWEB3 und seine verbundenen Unternehmen, Partner und Mitarbeiter nicht für indirekte, zufällige, strafende oder Folgeschäden, die aus der Nutzung oder Unfähigkeit zur Nutzung der Dienstleistungen resultieren. Dies beinhaltet unter anderem den Verlust von Gewinnen, Geschäftsunterbrechung oder Datenverlust.",
    indemnificationTitle: "Freistellung",
    indemnificationContent: "Sie stimmen zu, MINTWEB3 und seine Geschäftsführer, Direktoren, Mitarbeiter und Agenten von und gegen alle Ansprüche, Schäden, Verbindlichkeiten und Kosten zu verteidigen, schadlos zu halten und zu entschädigen, die aus folgenden Gründen entstehen:",
    disputeResolutionTitle: "Streitbeilegung",
    disputeResolutionContent: "Im Falle eines Streits, der aus diesen AGB oder im Zusammenhang mit ihnen entsteht, verpflichten sich die Parteien, zuerst eine Lösung durch Verhandlungen in gutem Glauben zu suchen. Wenn die Verhandlungen scheitern, wird der Streit durch Schiedsverfahren in Hongkong beigelegt, und zwar nach den Regeln des Hongkong International Arbitration Centre (HKIAC). Die Entscheidung des Schiedsrichters ist endgültig und bindend.",
    governingLawTitle: "Anzuwendendes Recht",
    governingLawContent: "Diese Allgemeinen Geschäftsbedingungen unterliegen dem Recht von Hongkong und sind in Übereinstimmung damit auszulegen, ohne Berücksichtigung seiner Kollisionsnormen.",
    changesToTermsTitle: "Änderungen der Geschäftsbedingungen",
    changesToTermsContent: "MINTWEB3 behält sich das Recht vor, diese AGB jederzeit zu ändern. Die Nutzer werden über jede wesentliche Änderung informiert, und die fortgesetzte Nutzung der Dienstleistungen nach solchen Änderungen gilt als Akzeptanz der überarbeiteten AGB.",
    contactUsTitle: "Kontaktieren Sie uns",
    contactUsContent: "Für Fragen zu diesen AGB kontaktieren Sie uns bitte.",
  },
  userAgreement: {
    title: "Benutzer",
    afterTitle: "Vereinbarung",
    cookiePolicy: "MINTWEB3-Cookie-Richtlinie",
    effectiveDate: "Gültigkeitsdatum",
    welcomeMessage: "Willkommen bei MINTWEB3! Diese Benutzervereinbarung ('Vereinbarung') regelt Ihren Zugang und Ihre Nutzung der Plattform, Produkte und Dienstleistungen von MINTWEB3 (gemeinsam als 'Dienste' bezeichnet). Durch die Registrierung für ein Konto oder die Nutzung unserer Dienste stimmen Sie zu, die in dieser Vereinbarung festgelegten Bedingungen einzuhalten und daran gebunden zu sein.",
    agreementMessage: "Wenn Sie nicht mit einer der unten aufgeführten Bestimmungen einverstanden sind, bitten wir Sie, unsere Dienste nicht zu nutzen.",
    acceptanceOfTerms: "Akzeptanz der Bedingungen",
    acceptanceDescription: "Indem Sie ein Konto erstellen, auf die MINTWEB3-Plattform zugreifen oder sie in irgendeiner Weise nutzen, stimmen Sie den in dieser Vereinbarung dargelegten Bedingungen zu. Dies beinhaltet alle zukünftigen Änderungen oder Modifikationen, die wir von Zeit zu Zeit umsetzen können. Ihre fortgesetzte Nutzung der Dienste nach einer solchen Änderung stellt Ihre Zustimmung zu den neuen Bedingungen dar.",
    eligibility: "Berechtigung",
    eligibilityRequirements: "Um die Dienste von MINTWEB3 zu nutzen, müssen Sie:",
    eligibilityAge: "Mindestens 18 Jahre alt sein oder das in Ihrem Land geltende Volljährigkeitsalter erreicht haben.",
    eligibilityLegalCapacity: "Die rechtliche Fähigkeit besitzen, verbindliche Vereinbarungen einzugehen.",
    eligibilityCompliance: "Alle geltenden Gesetze und Vorschriften einhalten, einschließlich derer, die sich auf Kryptowährungen, Blockchain-Technologien und digitale Vermögenswerte beziehen.",
    mintweb3Right: "MINTWEB3 behält sich das Recht vor, einem Benutzer den Dienst zu verweigern oder zu beenden, insbesondere bei Verstößen gegen diese Vereinbarung oder anwendbare Gesetze.",
    accountRegistration: "Kontoregistrierung",
    accountRegistrationDescription: "Um bestimmte Funktionen der MINTWEB3-Plattform nutzen zu können, müssen Benutzer ein Konto registrieren. Während der Registrierung stimmen Sie zu, Folgendes zur Verfügung zu stellen:",
    accountRegistrationAccurateInfo: "Präzise und aktuelle Informationen.",
    accountRegistrationSecurePassword: "Ein sicheres und einzigartiges Passwort für Ihr Konto.",
    accountRegistrationUpdateDetails: "Schnelle Aktualisierungen Ihrer Kontodetails, wenn dies notwendig ist.",
    accountResponsibility: "Sie sind allein verantwortlich für die Wahrung der Vertraulichkeit Ihrer Kontozugangsdaten und für alle Aktivitäten, die unter Ihrem Konto stattfinden. Bei Verdacht auf unberechtigten Zugriff oder Sicherheitsverstöße informieren Sie MINTWEB3 sofort unter [insert support email].",
    servicesProvided: "Erbrachte Dienstleistungen",
    servicesDescription: "MINTWEB3 bietet eine breite Palette von Web 3.0-Lösungen an, einschließlich, aber nicht beschränkt auf:",
    service1: "Token-Erstellung und Smart Contract-Entwicklung.",
    service2: "Multi-Chain-Staking-Lösungen.",
    service3: "Web3-betriebene Zahlungssysteme.",
    service4: "ICO- (Initial Coin Offering) Marketing und Strategie-Entwicklung.",
    service5: "Integration von Wallets wie MetaMask, Trust Wallet und WalletConnect.",
    servicesModification: "Wir bemühen uns, sichere und fortschrittliche Lösungen anzubieten, die auf die dezentrale Wirtschaft zugeschnitten sind. MINTWEB3 behält sich das Recht vor, jeden Aspekt der Dienste zu ändern, zu verbessern oder einzustellen, jederzeit ohne vorherige Ankündigung.",
    userResponsibilities: "Benutzerverantwortlichkeiten",
    userResponsibilitiesDescription: "Bei der Nutzung der Dienste von MINTWEB3 stimmen Sie zu:",
    userResponsibility1: "Die Plattform nur für rechtlich zulässige Zwecke zu nutzen.",
    userResponsibility2: "Handlungen zu unterlassen, die der Plattform, anderen Benutzern oder Dritten schaden könnten.",
    userResponsibility3: "Alle geltenden Gesetze einzuhalten, inklusive Kryptowährungs-Regularien.",
    userResponsibility4: "Sich zu enthalten, rechtswidrige, schädliche oder unangemessene Inhalte hochzuladen, zu posten oder zu teilen.",
    userProhibitedActions: "Folgendes ist Ihnen untersagt:",
    userProhibitedAction1: "Betrügerische Aktivitäten wie Phishing, Geldwäsche oder Betrug zu betreiben.",
    userProhibitedAction2: "Hacking, Reverse-Engineering oder den Versuch, die Sicherheit der Plattform zu kompromittieren.",
    userProhibitedAction3: "Eine Person oder Einheit zu imitieren oder fälschlicherweise Ihre Zugehörigkeit zu einer Person oder Einheit darzustellen.",
    intellectualProperty: "Geistiges Eigentum",
    intellectualPropertyDescription: "Alle Inhalte und Materialien auf der MINTWEB3-Plattform, einschließlich, aber nicht beschränkt auf Texte, Grafiken, Logos, Designs, Software und Markenzeichen, sind Eigentum von MINTWEB3 oder seinen Lizenzgebern und durch geistige Eigentumsrechte geschützt.",
    intellectualPropertyRights: "Als Benutzer behalten Sie die Rechte an allen Inhalten, die Sie auf der Plattform einreichen, posten oder hochladen. Indem Sie dies tun, gewähren Sie jedoch MINTWEB3 eine nicht exklusive, weltweite, gebührenfreie Lizenz zur Nutzung, Darstellung, Verbreitung und Bewerbung Ihrer Inhalte im Rahmen unserer Dienste.",
    paymentTerms: "Zahlungsbedingungen",
    paymentDescription: "MINTWEB3 stellt seine Dienste gegen eine Gebühr bereit, wie in den einzelnen Servicevereinbarungen ausgeführt.",
    paymentAgreement: "Mit der Nutzung unserer kostenpflichtigen Dienstleistungen stimmen Sie zu:",
    paymentResponsibility1: "Alle anfallenden Gebühren rechtzeitig zu bezahlen.",
    paymentResponsibility2: "Für ausreichende Mittel für Transaktionen zu sorgen.",
    paymentResponsibility3: "Die Verantwortung für etwaige damit verbundenen Steuern oder Gebühren je nach Ihrem Standort zu übernehmen.",
    paymentRefundPolicy: "Rückerstattungen für jegliche Dienstleistungen werden nicht angeboten, es sei denn, dies wurde ausdrücklich schriftlich von MINTWEB3 vereinbart. Alle Zahlungen sind endgültig und nicht umkehrbar, sofern nicht ein nachweisbarer Fehler bei der Abrechnung vorliegt.",
    securityAndPrivacy: "Sicherheit und Datenschutz",
    securityDescription: "Wir nehmen den Datenschutz und die Sicherheit unserer Benutzer ernst. Bitte lesen Sie unsere Datenschutzrichtlinie, um zu verstehen, wie wir Ihre persönlichen Daten sammeln, verwenden und schützen.",
    securityRiskAcknowledgment: "Obwohl wir bemüht sind, höchste Sicherheitsstandards aufrechtzuerhalten, ist kein System vollständig immun gegen Risiken. Mit der Nutzung der Dienste von MINTWEB3 erkennen Sie diese inhärenten Risiken im Zusammenhang mit Web 3.0-Technologien an und akzeptieren diese, einschließlich des potenziellen Verlusts von Geldern aufgrund von Hacks, unberechtigtem Zugriff oder Netzwerkausfällen.",
    limitationOfLiability: "Haftungsbeschränkung",
    limitationDescription: "MINTWEB3 haftet nicht für:",
    limitationLiability1: "Verluste oder Schäden, ob direkt, indirekt, zufällig oder folglich, die aus der Nutzung oder der Unfähigkeit zur Nutzung unserer Plattform resultieren.",
    limitationLiability2: "Verlust von Vermögenswerten, Gewinn, Geschäftschancen oder Daten, selbst wenn MINTWEB3 auf die Möglichkeit solcher Schäden hingewiesen wurde.",
    limitationLiability3: "Fehler, Verzögerungen oder Funktionsstörungen von Blockchain-Netzwerken, intelligenten Verträgen oder Integrationen von Drittanbietern.",
    indemnityClause: "Sie stimmen zu, MINTWEB3 und seine verbundenen Unternehmen, leitende Angestellte und Mitarbeiter von jeglichen Ansprüchen, Verlusten oder Schäden, die sich aus Ihrer Verletzung dieser Vereinbarung oder dem Missbrauch der Dienste ergeben, freizustellen.",
    thirdPartyLinks: "Links und Dienste von Drittanbietern",
    thirdPartyLinksDescription: "Die MINTWEB3-Plattform kann Links zu Websites, Tools oder Diensten von Drittanbietern enthalten. Diese werden zu Ihrer Bequemlichkeit zur Verfügung gestellt und MINTWEB3 übernimmt keine Garantie oder Verantwortung für Inhalte oder Dienste von Dritten. Sie erkennen an, dass Ihre Interaktionen mit solchen Diensten von Drittanbietern auf eigenes Risiko erfolgen und den Bedingungen des jeweiligen Anbieters unterliegen.",
    termination: "Kündigung",
    terminationDescription: "MINTWEB3 behält sich das Recht vor, Ihr Konto und den Zugang zu den Diensten jederzeit, mit oder ohne Grund, und ohne vorherige Ankündigung zu sperren oder zu kündigen.",
    disputeResolution: "Streitbeilegung",
    disputeResolutionDescription: "Im Falle von Streitigkeiten, die sich aus dieser Vereinbarung oder Ihrer Nutzung der Dienste ergeben, stimmen Sie zu, sich zuerst an MINTWEB3 zu wenden, um den Vorfall informell zu klären. Wenn keine Einigung erzielt werden kann, unterliegen Streitigkeiten den Gesetzen von Hongkong, und Sie stimmen zu, sich der ausschließlichen Gerichtsbarkeit seiner Gerichte zu unterwerfen.",
    modifications: "Änderungen",
    modificationsDescription: "MINTWEB3 behält sich das Recht vor, diese Vereinbarung jederzeit zu ändern. Alle Änderungen werden auf unserer Plattform veröffentlicht und Ihre fortgesetzte Nutzung der Dienste stellt die Annahme der überarbeiteten Vereinbarung dar."
  },
}
