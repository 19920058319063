import { onMounted, ref, watch } from 'vue'
import { wagmiConfig } from '@/plugins/walletconnect'
import { useUserStore } from '@/stores/user'
import { hasClaimStarted } from '@/utils/helpers'
import { getAccount, getBalance, watchAccount } from '@wagmi/core'
import { useToken } from './token'
import { Modal } from 'bootstrap'

const account = ref(getAccount(wagmiConfig))
const accountBalances = ref(new Map())
let unwatch

export function useAccount() {
  const { tokenList } = useToken()
  const userStore = useUserStore()

  // if (account.value === undefined) {
  //   account.value = ref(getAccount(wagmiConfig))
  // }

  // watch(account, async () => {
  //   console.log('ACCOUNT', account.value)
  //   await getBalances()
  // })

  async function getBalances() {
    //Clean account balances when disconnect
    if (!account.value.isConnected) {
      accountBalances.value = new Map()
      return
    }

    //Get only presale token balance if presale is over
    if (hasClaimStarted()) {
      accountBalances.value.set(
        import.meta.env.VITE_PRESALE_TOKEN_SYMBOL,
        await getBalance(wagmiConfig, {
          address: account.value.address,
          token: import.meta.env.VITE_PRESALE_TOKEN_CONTRACT
        })
      )

      //Get balance of all accepted tokens if presale is open
    } else {
      if (tokenList.value) {
        tokenList.value.forEach(async (token) => {
          accountBalances.value.set(
            token.id,
            await getBalance(wagmiConfig, {
              address: account.value.address,
              token: token.contract
            })
          )
        })
      }
    }
  }

  //Get account updates events (disconnect, network update, account change...)
  onMounted(() => {
    if (!unwatch) {
      unwatch = watchAccount(wagmiConfig, {
        onChange(data) {
          account.value = data
          getBalances()

          //If account is connect and
          if (account.value.isConnected) {
            const modalEl = document.getElementById('affiliateModal')
            const modal = Modal.getInstance(modalEl)

            if (modal?._isShown || (userStore.user?.address && (userStore.user?.address !== account.value.address))) {
              userStore.findUser(account.value.address)
            }
          } else if (userStore.user) {
            userStore.clearState()
          }
        }
      })
    }
  })

  return { account, accountBalances, getBalances }
}
