<script setup>
import Title from '../../components/base/Title.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
    <main>
        <section class="hero-3">
            <div class="hero" style="position: absolute !important;width: 100%;top: 0;left: 0;z-index: 0;">
            </div>
            <div class="d-flex" style="flex-direction: column;justify-content: center; min-height: 50vh;"
                data-aos="fade-up">
                <div class="container">
                    <div class="row  align-items-center justify-content-center text-center">
                        <div class="col-md-10 ">
                            <h1 class="display-2 text-white fw-bold my-4">
                                <Title>
                                    <span class="text-white me-2">{{ t('community.guidelines') }}</span>
                                    <br />
                                    <template #after>{{ t('community.guidelinesSubtitle') }}</template>
                                </Title>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container position-relative">
                <div class="fs-4 fw-bold text-white" data-aos="fade-up">
                    {{ t('community.title') }}
                </div>

                <div class="text-white mt-2" data-aos="fade-up">
                    <p>{{ t('community.welcomeMessage') }}</p>
                    <p>{{ t('community.purposeMessage') }}</p>
                    <p>{{ t('community.participationMessage') }}</p>

                    <ol class="mb-5">
                        <li>
                            <p class="fs-6 my-2">{{ t('community.coreValuesTitle') }}</p>
                            <p>{{ t('community.coreValuesDescription') }}</p>
                            <ul>
                                <li>{{ t('community.coreValuesRespect') }}</li>
                                <li>{{ t('community.coreValuesCollaboration') }}</li>
                                <li>{{ t('community.coreValuesIntegrity') }}</li>
                                <li>{{ t('community.coreValuesInnovation') }}</li>
                            </ul>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.respectfulCommunicationTitle') }}</p>
                            <p>{{ t('community.respectfulCommunicationDescription') }}</p>
                            <ul>
                                <li>{{ t('community.respectfulCommunicationNoHarassment') }}</li>
                                <li>{{ t('community.respectfulCommunicationNoHateSpeech') }}</li>
                                <li>{{ t('community.respectfulCommunicationConstructiveCriticism') }}</li>
                            </ul>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.prohibitedConductTitle') }}</p>
                            <p>{{ t('community.prohibitedConductDescription') }}</p>
                            <ul>
                                <li>{{ t('community.prohibitedConductNoSpam') }}</li>
                                <li>{{ t('community.prohibitedConductNoImpersonation') }}</li>
                                <li>{{ t('community.prohibitedConductNoIllegalActivities') }}</li>
                                <li>{{ t('community.prohibitedConductNoMaliciousBehavior') }}</li>
                            </ul>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.intellectualPropertyTitle') }}</p>
                            <p>{{ t('community.intellectualPropertyDescription') }}</p>
                            <ul>
                                <li>{{ t('community.intellectualPropertyNoCopyright') }}</li>
                                <li>{{ t('community.intellectualPropertyOriginalContent') }}</li>
                                <li>{{ t('community.intellectualPropertyAttribution') }}</li>
                            </ul>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.securityPrivacyTitle') }}</p>
                            <p>{{ t('community.securityPrivacyDescription') }}</p>
                            <ul>
                                <li>{{ t('community.securityPrivacyNoPersonalInfo') }}</li>
                                <li>{{ t('community.securityPrivacyProtectAccount') }}</li>
                                <li>{{ t('community.securityPrivacyReportIssues') }}</li>
                            </ul>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.contentStandardsTitle') }}</p>
                            <p>{{ t('community.contentStandardsDescription') }}</p>
                            <ul>
                                <li>{{ t('community.contentStandardsRelevance') }}</li>
                                <li>{{ t('community.contentStandardsClarityAccuracy') }}</li>
                                <li>{{ t('community.contentStandardsNoInappropriateContent') }}</li>
                            </ul>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.moderationEnforcementTitle') }}</p>
                            <p>{{ t('community.moderationEnforcementDescription') }}</p>
                            <ul>
                                <li>{{ t('community.moderationEnforcementWarnings') }}</li>
                                <li>{{ t('community.moderationEnforcementContentRemoval') }}</li>
                                <li>{{ t('community.moderationEnforcementSuspensionBan') }}</li>
                                <li>{{ t('community.moderationEnforcementAppeals') }}</li>
                            </ul>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.reportingViolationsTitle') }}</p>
                            <p>{{ t('community.reportingViolationsDescription') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.thirdPartyLinksTitle') }}</p>
                            <p>{{ t('community.thirdPartyLinksDescription') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.updatesTitle') }}</p>
                            <p>{{ t('community.updatesDescription') }}</p>
                        </li>

                        <li>
                            <p class="fs-6 my-2">{{ t('community.contactTitle') }}</p>
                            <p>{{ t('community.contactDescription') }}</p>
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    </main>
</template>


<style scoped>
.border-bottom {
    border-bottom: 1px solid white;
    opacity: 0.16;
}
</style>