
export const advertisersTechnology = [
  { id: 1, name: "Firebase", logo: "/advertisers/firebase.svg" },
  { id: 2, name: "ChainStack", logo: "/advertisers/chainstack.svg" },
  { id: 3, name: "Github", logo: "/advertisers/github.svg" },
  { id: 4, name: "Python", logo: "/advertisers/python.svg" },
  { id: 5, name: "Flutter", logo: "/advertisers/flutter.svg" },
  { id: 6, name: "React Native", logo: "/advertisers/react-native.svg" },
  { id: 7, name: "Solidity", logo: "/advertisers/solidity.svg" },
  { id: 8, name: "Morails", logo: "/advertisers/morails.svg" },
  { id: 9, name: "Firebase", logo: "/advertisers/firebase.svg" },
  { id: 10, name: "ChainStack", logo: "/advertisers/chainstack.svg" },
  { id: 11, name: "Github", logo: "/advertisers/github.svg" },
  { id: 12, name: "Python", logo: "/advertisers/python.svg" },
  { id: 13, name: "Flutter", logo: "/advertisers/flutter.svg" },
  { id: 14, name: "React Native", logo: "/advertisers/react-native.svg" },
  { id: 15, name: "Solidity", logo: "/advertisers/solidity.svg" },
  { id: 16, name: "Node", logo: "/advertisers/node.svg" },
  { id: 17, name: "Morails", logo: "/advertisers/morails.svg" },
];

export const advertisersCrypto = [
  { id: 1, name: "Ethereum", logo: "/advertisers/ethereum.svg" },
  { id: 2, name: "Arbitrum", logo: "/advertisers/arbitrum.svg" },
  { id: 3, name: "Polygon", logo: "/advertisers/polygon.svg" },
  { id: 4, name: "Binance", logo: "/advertisers/binance.svg" },
  { id: 5, name: "Base", logo: "/advertisers/base.svg" },
  { id: 6, name: "Optimism", logo: "/advertisers/optimism.svg" },
  { id: 7, name: "Ethereum", logo: "/advertisers/ethereum.svg" },
  { id: 8, name: "Arbitrum", logo: "/advertisers/arbitrum.svg" },
  { id: 9, name: "Polygon", logo: "/advertisers/polygon.svg" },
  { id: 10, name: "Binance", logo: "/advertisers/binance.svg" },
  { id: 11, name: "Base", logo: "/advertisers/base.svg" },
  { id: 12, name: "Optimism", logo: "/advertisers/optimism.svg" },
];

export const coinsMobile = [
  { id: 1, name: "Bitcoin", logo: "/coins/btc.webp" },
  { id: 2, name: "Tether", logo: "/coins/usdt.webp" },
  { id: 3, name: "Ton", logo: "/coins/ton.webp" },
  { id: 4, name: "Optimism", logo: "/coins/op.webp" },
  { id: 5, name: "Sol", logo: "/coins/sol.webp" },
  { id: 6, name: "Shiba", logo: "/coins/shiba.webp" },
  { id: 7, name: "USDC", logo: "/coins/usdc.webp" },
  { id: 8, name: "Binance", logo: "/coins/bnb.webp" },
  { id: 9, name: "Polygon", logo: "/coins/pol.webp" },
  { id: 10, name: "Cardano", logo: "/coins/ada.webp" },
];

export const indexAboutItems = (i18n) => {
  return [i18n('viewIndex.aboutItems01'), i18n('viewIndex.aboutItems02'), i18n('viewIndex.aboutItems03')];
};

export const indexExpertise = (i18n) => {
  return [i18n('viewIndex.expertise01'), i18n('viewIndex.expertise02'), i18n('viewIndex.expertise03'), i18n('viewIndex.expertise04'), i18n('viewIndex.expertise05')];
};

export const ourServices = (i18n) => {
  return [
    {
      id: "01",
      title: i18n('viewIndex.ourServices01Ttitle'),
      description: i18n('viewIndex.ourServices01Description'),
    },
    {
      id: "02",
      title: i18n('viewIndex.ourServices02Ttitle'),
      description: i18n('viewIndex.ourServices02Description'),
    },
    {
      id: "03",
      title: i18n('viewIndex.ourServices03Ttitle'),
      description: i18n('viewIndex.ourServices03Description'),
    },
    {
      id: "04",
      title: i18n('viewIndex.ourServices04Ttitle'),
      description: i18n('viewIndex.ourServices04Description'),
    },
    {
      id: "05",
      title: i18n('viewIndex.ourServices05Ttitle'),
      description: i18n('viewIndex.ourServices05Description'),
    },
    {
      id: "06",
      title: i18n('viewIndex.ourServices06Ttitle'),
      description: i18n('viewIndex.ourServices06Description'),
    },
    {
      id: "07",
      title: i18n('viewIndex.ourServices07Ttitle'),
      description: i18n('viewIndex.ourServices07Description'),
    },
    {
      id: "08",
      title: i18n('viewIndex.ourServices08Ttitle'),
      description: i18n('viewIndex.ourServices08Description'),
    },
    {
      id: "09",
      title: i18n('viewIndex.ourServices09Ttitle'),
      description: i18n('viewIndex.ourServices09Description'),
    },
    {
      id: "10",
      title: i18n('viewIndex.ourServices10Ttitle'),
      description: i18n('viewIndex.ourServices10Description'),
    },
    {
      id: "11",
      title: i18n('viewIndex.ourServices11Ttitle'),
      description: i18n('viewIndex.ourServices11Description'),
    }
  ];
};

export const airdropServices = (i18n) => {
  return [
    {
      title: i18n('viewAirdrop.airdropServices01Title'),
      description: i18n('viewAirdrop.airdropServices01Description'),
      img: "/images/airdrop.webp",
      reverse: true,
      items: [
        {
          title: i18n('viewAirdrop.airdropServices01Item01Title'),
          description: i18n('viewAirdrop.airdropServices01Item01Description'),
        },
        {
          title: i18n('viewAirdrop.airdropServices01Item02Title'),
          description: i18n('viewAirdrop.airdropServices01Item02Description'),
        },
        {
          title: i18n('viewAirdrop.airdropServices01Item03Title'),
          description: i18n('viewAirdrop.airdropServices01Item03Description'),
        },
      ],
    }
  ];
};

export const airdropKeyBenefits = (i18n) => {
  return [i18n('viewAirdrop.giveawayKeyBenefits01'), i18n('viewAirdrop.giveawayKeyBenefits02'), i18n('viewAirdrop.giveawayKeyBenefits03')]
};

export const marketingServices = (i18n) => {
  return [
    {
      title: i18n('viewMarketing.marketing01Title'),
      description: i18n('viewMarketing.marketing01Description'),
      img: "/images/whitepaper.webp",
      reverse: false,
      items: [
        {
          title: i18n('viewMarketing.marketing01Item01Title'),
          description: i18n('viewMarketing.marketing01Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing01Item02Title'),
          description: i18n('viewMarketing.marketing01Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing02Title'),
      description: i18n('viewMarketing.marketing02Description'),
      img: "/images/ico-landing.webp",
      reverse: true,
      items: [
        {
          title: i18n('viewMarketing.marketing02Item01Title'),
          description: i18n('viewMarketing.marketing02Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing02Item02Title'),
          description: i18n('viewMarketing.marketing02Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing03Title'),
      description: i18n('viewMarketing.marketing03Description'),
      img: "/images/ico-management.webp",
      reverse: false,
      items: [
        {
          title: i18n('viewMarketing.marketing03Item01Title'),
          description: i18n('viewMarketing.marketing03Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing03Item02Title'),
          description: i18n('viewMarketing.marketing03Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing04Title'),
      description: i18n('viewMarketing.marketing04Description'),
      img: "/images/exchange-listing.webp",
      reverse: true,
      items: [
        {
          title: i18n('viewMarketing.marketing04Item01Title'),
          description: i18n('viewMarketing.marketing04Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing04Item02Title'),
          description: i18n('viewMarketing.marketing04Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing05Title'),
      description: i18n('viewMarketing.marketing05Description'),
      img: "/images/ico-platforms.webp",
      reverse: false,
      items: [
        {
          title: i18n('viewMarketing.marketing05Item01Title'),
          description: i18n('viewMarketing.marketing05Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing05Item02Title'),
          description: i18n('viewMarketing.marketing05Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing06Title'),
      description: i18n('viewMarketing.marketing06Description'),
      img: "/images/community-management.webp",
      reverse: true,
      items: [
        {
          title: i18n('viewMarketing.marketing06Item01Title'),
          description: i18n('viewMarketing.marketing06Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing06Item02Title'),
          description: i18n('viewMarketing.marketing06Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing07Title'),
      description: i18n('viewMarketing.marketing07Description'),
      img: "/images/web-audit.svg",
      reverse: false,
      items: [
        {
          title: i18n('viewMarketing.marketing07Item01Title'),
          description: i18n('viewMarketing.marketing07Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing07Item02Title'),
          description: i18n('viewMarketing.marketing07Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing08Title'),
      description: i18n('viewMarketing.marketing08Description'),
      img: "/images/copywriting.webp",
      reverse: true,
      items: [
        {
          title: i18n('viewMarketing.marketing08Item01Title'),
          description: i18n('viewMarketing.marketing08Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing08Item02Title'),
          description: i18n('viewMarketing.marketing08Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing09Title'),
      description: i18n('viewMarketing.marketing09Description'),
      img: "/images/video-creation.svg",
      reverse: false,
      items: [
        {
          title: i18n('viewMarketing.marketing09Item01Title'),
          description: i18n('viewMarketing.marketing09Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing09Item02Title'),
          description: i18n('viewMarketing.marketing09Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing10Title'),
      description: i18n('viewMarketing.marketing10Description'),
      img: "/images/stickers.webp",
      reverse: true,
      items: [
        {
          title: i18n('viewMarketing.marketing10Item01Title'),
          description: i18n('viewMarketing.marketing10Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing10Item02Title'),
          description: i18n('viewMarketing.marketing10Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing11Title'),
      description: i18n('viewMarketing.marketing11Description'),
      img: "/images/promotion-banner.webp",
      reverse: false,
      items: [
        {
          title: i18n('viewMarketing.marketing11Item01Title'),
          description: i18n('viewMarketing.marketing11Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing11Item02Title'),
          description: i18n('viewMarketing.marketing11Item02Description'),
        },
      ],
    },
    {
      title: i18n('viewMarketing.marketing12Title'),
      description: i18n('viewMarketing.marketing12Description'),
      img: "/images/viral-marketing.webp",
      reverse: true,
      items: [
        {
          title: i18n('viewMarketing.marketing12Item01Title'),
          description: i18n('viewMarketing.marketing12Item01Description'),
        },
        {
          title: i18n('viewMarketing.marketing12Item02Title'),
          description: i18n('viewMarketing.marketing12Item02Description'),
        },
      ],
    },
  ];
};

export const coinLaunchItems = (i18n) => {
  return [
    {
      title: i18n('viewPayments.coinLaunchItems01'),
      icon: "/images/income-down.svg",
    },
    {
      title: i18n('viewPayments.coinLaunchItems02'),
      icon: "/images/income-up.svg",
    },
    {
      title: i18n('viewPayments.coinLaunchItems03'),
      icon: "/images/stake.svg",
    },
    {
      title: i18n('viewPayments.coinLaunchItems04'),
      icon: "/images/smart-contract.svg",
    },
    {
      title: i18n('viewPayments.coinLaunchItems05'),
      icon: "/images/token.svg",
    },
    {
      title: i18n('viewPayments.coinLaunchItems06'),
      icon: "/images/whitepaper.svg",
    },
  ];
};

export const stakingServices = (i18n) => {
  return [
    {
      title: i18n('viewStaking.stakingAdvantages01Title'),
      description: i18n('viewStaking.stakingAdvantages01Description'),
      img: "/images/staking-features.webp",
      reverse: false,
      items: [
        {
          title: i18n('viewStaking.stakingAdvantages01Item01Title'),
          description: i18n('viewStaking.stakingAdvantages01Item01Description'),
        },
        {
          title: i18n('viewStaking.stakingAdvantages01Item02Title'),
          description: i18n('viewStaking.stakingAdvantages01Item02Description'),
        },
        {
          title: i18n('viewStaking.stakingAdvantages01Item03Title'),
          description: i18n('viewStaking.stakingAdvantages01Item03Description'),
        },
      ],
    },
    {
      title: i18n('viewStaking.stakingAdvantages02Title'),
      description: i18n('viewStaking.stakingAdvantages02Description'),
      img: "/images/staking-benefits.webp",
      reverse: true,
      items: [
        {
          title: i18n('viewStaking.stakingAdvantages02Item01Title'),
          description: i18n('viewStaking.stakingAdvantages02Item01Description'),
        },
        {
          title: i18n('viewStaking.stakingAdvantages02Item02Title'),
          description: i18n('viewStaking.stakingAdvantages02Item02Description'),
        },
        {
          title: i18n('viewStaking.stakingAdvantages02Item03Title'),
          description: i18n('viewStaking.stakingAdvantages02Item03Description'),
        },
      ],
    }
  ];
};

export const paymentsDetails = (i18n) => {
  return [i18n('viewPayments.multiWalletBenefits01'), i18n('viewPayments.multiWalletBenefits02'), i18n('viewPayments.multiWalletBenefits03')]
};

export const vestingDetails = (i18n) => {
  return [i18n('viewPayments.vestingBenefits01'), i18n('viewPayments.vestingBenefits02'), i18n('viewPayments.vestingBenefits03')]
};