<script setup>
import { formatEther } from 'viem'
import { computed, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { chains } from '@/plugins/walletconnect'
import { formatCurrencyUSD, formatNumber, getChainSymbol } from '@/utils/helpers'
import { useData } from '@/composables/data'
import { useChain } from '@/composables/chain'
import { useToken } from '@/composables/token'
import DevelopedByComponent from './DevelopedByComponent.vue'
import { TokenType } from '@/utils/constants/enums'
import { tokens } from '@/utils/constants/tokens'
import CountdownComponent from './CountdownComponent.vue'
import SwapComponent from './SwapComponent.vue'

const { selectedChain, update } = useChain()
const { tokenList, selectedToken } = useToken()
const { t } = useI18n()

const {
  dataSelectedNetwork,
  usdRaised,
  usdRaisedTarget,
  userTotalBalance,
  userUnstakedBalance,
  currentRoundDetails,
  roundPrices,
  stakerDetails,
  stopFetching
} = useData()

const tokenSymbol = import.meta.env.VITE_PRESALE_TOKEN_SYMBOL
const howToBuyUrl = import.meta.env.VITE_HOW_TO_BUY_URL

const tokenPrice = computed(() => {
  return formatCurrencyUSD(formatEther(currentRoundDetails.value.currentPrice), 2, 8)
})

const nextTokenPrice = computed(() => {
  return roundPrices.value.length === Number(currentRoundDetails.value.currentRoundNumber + 1n)
    ? t('general.lastRound')
    : formatCurrencyUSD(
      formatEther(roundPrices.value[currentRoundDetails.value.currentRoundNumber + 1n] ?? 0n),
      2,
      8
    )
})

const usdRaisedFormatted = computed(() => {
  return formatCurrencyUSD(formatEther(usdRaised.value), 2, 2)
})

const usdRaisedTargetFormatted = computed(() => {
  return formatCurrencyUSD(formatEther(usdRaisedTarget.value), 2, 2)
})

const progress = computed(() => {
  return usdRaisedTarget.value > 0 ? (usdRaised.value * 100n) / usdRaisedTarget.value : 0
})

const totalBalance = computed(() => {
  return formatNumber(formatEther(userTotalBalance.value), 0, 0)
})

const unstakedBalance = computed(() => {
  return formatNumber(formatEther(userUnstakedBalance.value), 0, 0)
})

const stakedBalance = computed(() => {
  return formatNumber(formatEther(stakerDetails.value.amount), 0, 0)
})

async function buyWithCard() {
  const cardToken = tokens.find((token) => token.enabled && token.type === TokenType.Card)
  if (!cardToken) return

  const updated = await update(cardToken.networkId)
  if (updated) selectedToken.value = cardToken
}

onUnmounted(() => {
  stopFetching()
})
</script>

<template>
  <div class="border border-primary rounded p-1">
    <div class="mx-auto py-3 px-2 border border-primary rounded">
      <div class="px-0 w-100 d-flex flex-column align-items-center justify-content-center text-center">

        <a :href="howToBuyUrl" target="_blank" class="mb-2 btn btn-outline-primary btn-sm">
          {{ $t('presale.howToBuy') }}
          <span class="badge bg-primary rounded-circle">?</span>
          <!-- <img src="@/assets/icons/help3.svg" alt="How to buy help" width="22" class="ms-1" /> -->
        </a>

        <p class="font-24 fw-bold text-capitalize mb-1">
          {{ $t('presale.presaleOpen', { token: tokenSymbol }) }}
        </p>

        <span class="font-13" :class="dataSelectedNetwork === undefined ? 'shimmer' : ''">{{ $t('presale.stage') }}: {{
          currentRoundDetails.currentRoundNumber + 1n }} /
          {{ roundPrices.length }}
        </span>

        <div class="border rounded w-100 p-2 my-2">
          <div class="font-15 fw-medium lh-2">
            <span>{{ $t('presale.untilPriceIncrease') }}</span>
            <img src="@/assets/icons/info.svg" width="18" height="18" alt="Until price icrease"
              class="ms-2 cursor-pointer" :class="dataSelectedNetwork === undefined ? 'd-none' : ''"
              style="padding-bottom: 2px" v-tooltip="$t('presale.untilPriceIncreaseDescription')" />
          </div>
          <div class="font-13" :class="dataSelectedNetwork === undefined ? 'shimmer' : ''">
            ({{ $t('presale.nextPrice') }}: {{ nextTokenPrice }})
          </div>

          <CountdownComponent :isLoading="dataSelectedNetwork === undefined"
            :endTime="currentRoundDetails.roundFinalTime" />

          <div class="progress w-100 text center" role="progressbar" aria-label="Progress to increment round"
            :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"
            :class="dataSelectedNetwork === undefined ? 'shimmer' : ''">
            <div class="progress-bar" :class="dataSelectedNetwork === undefined ? 'd-none' : ''" :style="{
              width: progress + '%'
            }">
              {{ progress }}%
            </div>
          </div>

          <div class="mt-2 mb-1 font-15 fw-medium text-uppercase"
            :class="dataSelectedNetwork === undefined ? 'shimmer' : ''">
            <span>{{ $t('presale.usdtRaised') }}: </span>
            <span>{{ usdRaisedFormatted }}</span>
            <span> / {{ usdRaisedTargetFormatted }}</span>
          </div>
        </div>

        <div class="my-1 font-12 text-uppercase" :class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'">
          <span>{{ $t('presale.yourPurchased', { token: tokenSymbol }) }}: </span>
          <span>{{ totalBalance }}</span>
          <img src="@/assets/icons/info.svg" width="20" height="20" alt="Purchased info" class="ms-2 cursor-pointer"
            :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
            v-tooltip="$t('presale.yourPurchasedDescription')" />
        </div>

        <div class="mb-1 font-12 text-uppercase" :class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'">
          <span>{{ $t('presale.yourStakeable', { token: tokenSymbol }) }}: </span>
          <span>{{ unstakedBalance }}</span>
          <img src="@/assets/icons/info.svg" width="20" height="20" alt="Unstaked info" class="ms-2 cursor-pointer"
            :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
            v-tooltip="$t('presale.yourStakeableDescription')" />
        </div>

        <div class="mb-1 font-12 text-uppercase" :class="dataSelectedNetwork === undefined ? 'shimmer' : 'w-100'">
          <span>{{ $t('presale.yourStaked', { token: tokenSymbol }) }}: </span>
          <span>{{ stakedBalance }}</span>
          <img src="@/assets/icons/info.svg" width="20" height="20" alt="Stake info" class="ms-2 cursor-pointer"
            :class="dataSelectedNetwork === undefined ? 'd-none' : ''" style="padding-bottom: 2px"
            v-tooltip="$t('presale.yourStakedDescription')" />
        </div>
      </div>

      <div class="px-3 my-2 w-100 d-flex flex-column align-items-center justify-content-center text-center">
        <div class="font-14 fw-bold text-center pb-2 mb-2 w-100"
          :class="dataSelectedNetwork === undefined ? 'shimmer' : 'hr-lines'">
          1 {{ tokenSymbol }} = {{ tokenPrice }}
        </div>

        <div class="d-flex w-100 align-items-center justify-content-center gap-2">
          <div class="dropdown col-6">
            <button type="button"
              class="btn w-100 border py-2 px-0 px-2 dropdown-toggle d-flex justify-content-between align-items-center"
              data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
              <img :src="`/coins-icons/${getChainSymbol(selectedChain.id)}.svg`"
                :alt="`${getChainSymbol(selectedChain.id)} logo`" width="22" height="22" class="me-2" />
              <span class="text-truncate text-black">{{ selectedChain.name }}</span>
            </button>

            <ul class="dropdown-menu">
              <li v-for="chain in chains" :key="chain.id">
                <button class="dropdown-item d-flex align-items-center ps-2"
                  :class="chain.id === selectedChain.id ? 'active' : ''" :aria-current="chain.id === selectedChain.id"
                  type="button" @click="update(chain.id)">
                  <img :src="`/coins-icons/${getChainSymbol(chain.id)}.svg`" :alt="`${getChainSymbol(chain.id)} logo`"
                    loading="lazy" width="22" height="22" class="me-2" />
                  {{ chain.name }}
                </button>
              </li>
            </ul>
          </div>

          <div class="dropdown col-6">
            <button type="button"
              class="btn w-100 border py-2 px-0 px-2 dropdown-toggle d-flex justify-content-between align-items-center"
              data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
              <img
                :src="`/coins-icons/${selectedToken.type === TokenType.Card ? TokenType.Card.description : selectedToken.symbol}.svg`"
                :alt="`${selectedToken.type === TokenType.Card ? TokenType.Card.description : selectedToken.symbol} logo`"
                width="22" height="22" class="me-2" />
              <span class="text-truncate">{{ selectedToken.name }}</span>
            </button>

            <ul class="dropdown-menu">
              <li v-for="token in tokenList" :key="token.id">
                <button class="dropdown-item d-flex align-items-center ps-2"
                  :class="token.id === selectedToken.id ? 'active' : ''" :aria-current="token.id === selectedToken.id"
                  type="button" @click="() => (selectedToken = token)">
                  <img
                    :src="`/coins-icons/${token.type === TokenType.Card ? TokenType.Card.description : token.symbol}.svg`"
                    :alt="`${token.type === TokenType.Card ? TokenType.Card.description : token.symbol} logo`"
                    loading="lazy" width="22" height="22" class="me-2" />
                  {{ token.name }}
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div class="d-flex w-100 align-items-center justify-content-center gap-2 mt-2">
          <div class="col-6">
            <button type="button" class="btn btn-primary btn-sm" @click="buyWithCard">
              <img src="@/assets/icons/card.svg" alt="Card icon" width="22" class="me-1" />
              {{ $t('general.buyWithCard') }}
            </button>
          </div>

          <div class="d-flex col-6 align-items-center justify-content-center">
            <p class="font-13 mb-0 text-center">
              <a href="" class="text-decoration-underline fw-semibold" data-bs-toggle="modal"
                data-bs-target="#affiliateModal">
                {{ $t('general.becomeAnAffiliate', { token: selectedToken.symbol }) }}
              </a>
            </p>
          </div>
        </div>

        <SwapComponent />
      </div>

      <DevelopedByComponent />
    </div>
  </div>
</template>

