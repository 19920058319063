<script setup>
import Widget from '@/components/presale-widget/Widget.vue';
import { advertisersTechnology, advertisersCrypto, coinLaunchItems, coinsMobile, paymentsDetails, vestingDetails } from '../assets/data/index';
import AdvertisersMarquee from '../components/AdvertisersMarquee.vue';
import DetailsCard from '../components/base/DetailsCard.vue';
import OutlinedBtn from '../components/base/OutlinedBtn.vue';
import SecondaryBtn from '../components/base/SecondaryBtn.vue';
import IconDetailCard from '../components/base/IconDetailCard.vue';
import Title from '../components/base/Title.vue';
import { useChangelly } from '@/composables/changelly';
import { useAccount } from '@/composables/account';
import { copyToClipboard, formatCurrencyUSD, openModal, timestampToDate } from '@/utils/helpers'
import Cookies from 'js-cookie';
import { onMounted, ref } from 'vue';
import { Checkbox } from 'vue-recaptcha';
import { Toast } from 'bootstrap';
import { useUserStore } from '@/stores/user';
import { usePagination } from '@/composables/pagination';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

import CoinsMarquee from '../components/CoinsMarquee.vue';
import { Vue3Lottie } from 'vue3-lottie'
import Hero2 from '@/assets/hero-2.json'

const { changellyUrl, setChangellyUrl } = useChangelly();
const { account } = useAccount();
const userStore = useUserStore();
const { user, loading, error } = storeToRefs(userStore);
const { paginatedData, page, pages, totalPages, nextPage, backPage, goToPage } = usePagination();

const affiliateBaseUrl = import.meta.env.VITE_APP_URL

const affiliateId = ref('');
const parentAffiliateId = ref(Cookies.get('afl') ?? '');
const recaptchaResponse = ref();

async function createAffiliate() {
    if (recaptchaResponse !== undefined) {
        await userStore.createUser(
            affiliateId.value,
            parentAffiliateId.value,
            account.value.address,
            recaptchaResponse.value
        ).then(() => {
            if (!error) {
                const successToastEl = document.getElementById('successToast');
                const successToast = new Toast(successToastEl);
                successToast.show();
            } else {
                recaptchaResponse.value = '';
            }
        })
    }
}

onMounted(() => {
    const modalEl = document.getElementById('affiliateModal');
    modalEl.addEventListener('show.bs.modal', async () => {
        if (account.value.isConnected) {
            await userStore.findUser(account.value.address)
        }
    });
})

function clearState() {
    affiliateId.value = '';
    parentAffiliateId.value = Cookies.get('afl') ?? '';
    recaptchaResponse.value = '';
}

// Handle input event to enforce the mask (only alphanumeric, max 20 characters)
const handleAffiliateId = () => {
    // Regex to allow only alphanumeric characters
    const regex = /^[A-Za-z0-9]*$/;

    // Remove any non-alphanumeric characters
    if (!regex.test(affiliateId.value)) {
        affiliateId.value = affiliateId.value.replace(/[^A-Za-z0-9]/g, '');
    }

    // Convert the string to lowercase
    affiliateId.value = affiliateId.value.toLowerCase();

    // Ensure the input value is no more than 20 characters
    if (affiliateId.value.length > 20) {
        affiliateId.value = affiliateId.value.slice(0, 20);
    }
};

// Handle input event to enforce the mask (only alphanumeric, max 20 characters)
const handleParentAffiliateId = () => {
    // Regex to allow only alphanumeric characters
    const regex = /^[A-Za-z0-9]*$/;

    // Remove any non-alphanumeric characters
    if (!regex.test(parentAffiliateId.value)) {
        parentAffiliateId.value = parentAffiliateId.value.replace(/[^A-Za-z0-9]/g, '');
    }

    // Convert the string to lowercase
    parentAffiliateId.value = parentAffiliateId.value.toLowerCase();

    // Ensure the input value is no more than 20 characters
    if (parentAffiliateId.value.length > 20) {
        parentAffiliateId.value = parentAffiliateId.value.slice(0, 20);
    }
};

</script>

<template>
    <main>
        <section>
            <div class="radial-background-left-mobile"></div>
            <div class="radial-background-right-mobile"></div>
            <CoinsMarquee class="image-mobile" :data="coinsMobile" :direction="'reverse'" />

            <div class="hero-2">
                <vue3-lottie class="lottie-image" :animation-data="Hero2" :loop="true" :autoplay="true"
                    renderer="canvas" :rendererSettings="{
                        preserveAspectRatio: 'none',
                        clearCanvas: true,
                        progressiveLoad: true,
                        hideOnTransparent: true,
                        imageSmoothing: false,
                        dpr: 1,
                    }" />
                <div class="hero-2-content ">
                    <div class="min-vh-100 d-flex" style="flex-direction: column;justify-content: center; gap: 70px;"
                        data-aos="fade-up">
                        <div class="container">
                            <div class="row  align-items-center justify-content-center text-center">
                                <div class="col-md-10">
                                    <OutlinedBtn>
                                        <template #title>
                                            <div class="d-flex align-items-center gap-1">
                                                {{ $t('general.headerBtn01') }}
                                                <img src="/images/right-arrow.svg" alt="Right arrow" width="16"
                                                    height="16" />
                                            </div>
                                        </template>
                                    </OutlinedBtn>
                                    <h1 class="display-2 text-white fw-bold my-4">
                                        <Title>
                                            <span class="text-white">{{ $t('general.web30') }}</span>
                                            <br />
                                            <template #after>{{ $t('viewPayments.headerTitle') }}</template>
                                        </Title>
                                    </h1>
                                    <p class="text-secondary-light mx-auto" style="max-width: 70%;">
                                        {{ $t('viewPayments.headerDescription') }}
                                    </p>
                                    <div class="mt-4">
                                        <SecondaryBtn :title="$t('general.headerBtn02')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CoinsMarquee class="image-mobile" :data="coinsMobile" />
        </section>

        <AdvertisersMarquee :dark="true" :borders="true" :data="advertisersTechnology" id="next-section"/>

        <!-- ============================== -->
        <!-- WEB3 POWERED PAYMENTS          -->
        <!-- ============================== -->

        <div>
            <DetailsCard :gradient="true">
                <template #header>
                    <h1 class="display-5 fw-bold ">
                        <Title>
                            {{ $t('viewPayments.paymentsTitle01') }}<br /><template #after>{{
                                $t('viewPayments.paymentsTitle02') }}</template>
                        </Title>
                    </h1>
                </template>

                <template #body>
                    <div class="fs-6 text-secondary-dark mt-4">
                        <p>
                            {{ $t('viewPayments.paymentsDescription01') }}
                        </p>
                        <p>
                            {{ $t('viewPayments.paymentsDescription02') }}
                        </p>
                        <p>
                            {{ $t('viewPayments.paymentsDescription03') }}
                        </p>
                        <p>
                            {{ $t('viewPayments.paymentsDescription04') }}
                        </p>

                    </div>
                </template>

                <template #image>
                    <Widget />
                </template>
            </DetailsCard>
        </div>

        <!-- ============================== -->
        <!-- MULTI WALLET                   -->
        <!-- ============================== -->

        <div class="position-relative" style="z-index: -99;">
            <div class="payments-background-left"> </div>
            <div class="payments-overlay"></div>
            <div class="star" style="left: 20%;top: 20px;" data-aos="fade-up">
                <img src="/images/star.svg" alt="Star" width="24" height="24" />
            </div>
            <div class="star" style="right: 50%;top: 20%;" data-aos="fade-up">
                <img src="/images/star.svg" alt="Star" width="40" height="40" />
            </div>
            <div class="star" style="left: 7%;bottom: 60%;" data-aos="fade-up">
                <img src="/images/star.svg" alt="Star" width="40" height="40" />
            </div>
            <div class="container py-container">
                <div class="row gx-lg-5 gy-5 align-items-center justify-content-center">

                    <!-- Right Section: Spanning 2 Columns -->
                    <div class="col-12 col-md-5">
                        <div class="text-center">
                            <img style="width: 100%;" src="/images/payments-main.webp" alt="Payments" />
                        </div>
                    </div>

                    <div class="col-12 col-md-2"></div>


                    <!-- Left Section: Spanning 3 Columns -->
                    <div class="col-12 col-md-5 d-flex flex-column gap-1" data-aos="fade-up">

                        <h1 class="display-5 fw-bold text-white">
                            <Title>
                                <!-- Multi-Wallet <br><template #after>Integration</template> -->
                                <template #before>{{ $t('viewPayments.multiWalletTittle01') }}</template><br>
                                {{ $t('viewPayments.multiWalletTittle02') }}
                            </Title>
                        </h1>

                        <div class="fs-6 text-secondary-light mt-4">
                            {{ $t('viewPayments.multiWalletDescription') }}
                        </div>

                        <div class="fw-bold fs-4 mt-3 text-white">
                            {{ $t('viewPayments.multiWalletBenefitsTitle') }}
                        </div>

                        <div v-for="(item, idx) in paymentsDetails(t)" :key="idx"
                            class="d-flex align-items-center gap-2 mt-2 ">
                            <img src="/images/bullet.svg" alt="Bullet" width="14" height="14" />
                            <div class="text-secondary-light fs-6">{{ item }}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- ============================== -->
        <!-- COIN LAUNCH TOOKIT             -->
        <!-- ============================== -->

        <div>
            <DetailsCard :gradient="true">
                <template #header>
                    <h1 class="display-5 fw-bold ">
                        <Title>
                            {{ $t('viewPayments.coinLaunchTittle01') }}<br><template #after>
                                {{ $t('viewPayments.coinLaunchTittle02') }}</template>
                        </Title>
                    </h1>
                </template>

                <template #body>
                    <div class="fs-6 text-secondary-dark mt-4">
                        <p>
                            {{ $t('viewPayments.coinLaunchDescription01') }}
                        </p>
                        <p>
                            {{ $t('viewPayments.coinLaunchDescription02') }}
                        </p>
                    </div>
                </template>

                <template #image>
                    <div class="row align-items-center g-4">
                        <div class="col-6" v-for="(item, idx) in coinLaunchItems(t)" :key="idx">
                            <IconDetailCard :data="item" />
                        </div>
                    </div>
                </template>
            </DetailsCard>
        </div>

        <!-- ============================== -->
        <!-- VESTING                        -->
        <!-- ============================== -->

        <div class="position-relative">
            <div class="payments-background-left"> </div>
            <div class="payments-overlay"></div>
            <div class="star" style="left: 20%;top: 20px;" data-aos="fade-up">
                <img src="/images/star.svg" alt="Star" width="24" height="24" />
            </div>
            <div class="star" style="right: 50%;top: 20%;" data-aos="fade-up">
                <img src="/images/star.svg" alt="Star" width="40" height="40" />
            </div>
            <div class="star" style="left: 7%;bottom: 70%;" data-aos="fade-up">
                <img src="/images/star.svg" alt="Star" width="40" height="40" />
            </div>
            <div class="container py-container">
                <div class="row gx-lg-5 gy-5 align-items-center justify-content-center">

                    <!-- Right Section: Spanning 2 Columns -->
                    <div class="col-12 col-md-5">
                        <div class="text-center">
                            <img style="width: 100%;" src="/images/vesting.webp" alt="Vesting" />
                        </div>
                    </div>

                    <div class="col-12 col-md-2"></div>


                    <!-- Left Section: Spanning 3 Columns -->
                    <div class="col-12 col-md-5 d-flex flex-column gap-1" data-aos="fade-up">

                        <h1 class="display-5 fw-bold text-white">
                            <Title>
                                <!-- Multi-Wallet <br><template #after>Integration</template> -->
                                <template #before>{{ $t('viewPayments.vestingTittle01') }}</template><br>
                                {{ $t('viewPayments.vestingTittle02') }}
                            </Title>
                        </h1>

                        <div class="fs-6 text-secondary-light mt-4">
                            {{ $t('viewPayments.vestingDescription') }}
                        </div>

                        <div class="fw-bold fs-4 mt-3 text-white">
                            {{ $t('viewPayments.vestingBenefitsTitle') }}
                        </div>

                        <div v-for="(item, idx) in vestingDetails(t)" :key="idx"
                            class="d-flex align-items-center gap-2 mt-2 ">
                            <img src="/images/bullet.svg" alt="Bullet" width="14" height="14" />
                            <div class="text-secondary-light fs-6">{{ item }}</div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <!-- ============================== -->
        <!-- SALES BOT                      -->
        <!-- ============================== -->

        <div>
            <DetailsCard :gradient="true">
                <template #header>
                    <h1 class="display-5 fw-bold ">
                        <Title>
                            {{ $t('viewPayments.salesBotTittle01') }}<br><template #after>
                                {{ $t('viewPayments.salesBotTittle02') }}</template>
                        </Title>
                    </h1>
                </template>

                <template #body>
                    <div class="fs-6 text-secondary-dark mt-4">
                        <p>
                            {{ $t('viewPayments.salesBotDescription01') }}
                        </p>
                        <p>
                            {{ $t('viewPayments.salesBotDescription02') }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-center my-5">
					<a href="https://x.com/mintweb3" target="_blank" rel="noopener noreferrer"
						class="text-white align-center me-2">
						<img src="/images/x.svg" alt="Telegram logo" width="48" height="48" />

					</a>
                    <a href="https://t.me/mintweb3community" target="_blank" rel="noopener noreferrer"
						class="text-white align-center me-2">
						<img src="/images/telegram.svg" alt="Telegram logo" width="48" height="48" />
				
					</a>
                    <a href="https://discord.gg/7sUAx7wTMM" target="_blank" rel="noopener noreferrer"
						class="text-white align-center">
						<img src="/images/discord.svg" alt="Telegram logo" width="48" height="48" />
				
					</a>
				</div>
                
                </template>

                <template #image>
                    <div class="row align-items-center g-4">
                        <div class="text-center">
                            <img style="width: 100%;" src="/images/sales-bot.webp" alt="Sales Bot" />
                        </div>
                    </div>
                </template>
            </DetailsCard>
        </div>

        <div>
            <AdvertisersMarquee :dark="true" :borders="true" :title="$t('general.multiNetwork')"
                :data="advertisersCrypto" />
        </div>

        <!-- ============================== -->
        <!-- AFFILIATE MODAL                -->
        <!-- ============================== -->

        <div class="modal fade" id="exchangeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="exchangeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="d-flex w-100 justify-content-between mb-3">
                            <div></div>
                            <h1 class="modal-title fs-5" id="exchangeModalLabel">
                                {{ $t('swap.buyOrSwapTokens') }}
                            </h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                @click="setChangellyUrl('')"></button>
                        </div>

                        <iframe width="100%" height="400px" frameborder="none" allow="camera" :src="changellyUrl">
                            Can't load widget
                        </iframe>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="affiliateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="affiliateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="d-flex w-100 justify-content-between mb-3">
                            <div></div>
                            <h1 class="modal-title fs-5" id="affiliateModalLabel">
                                {{ !user ? $t('general.becomeAnAffiliate') : $t('affiliate.affiliateInformation') }}
                            </h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                @click="clearState"></button>
                        </div>

                        <div v-if="!user">
                            <div v-if="error && error !== ''" class="alert alert-danger" role="alert">
                                {{ error }}
                            </div>

                            <form @submit.prevent="createAffiliate">
                                <div class="mb-3">
                                    <label for="affiliateName" class="form-label">
                                        {{ $t('affiliate.affiliateName') }}
                                    </label>
                                    <input v-model="affiliateId" type="text" class="form-control" id="affiliateName"
                                        aria-describedby="affiliateNameDescription" @input="handleAffiliateId">
                                    <div id="affiliateNameDescription" class="form-text">
                                        {{ $t('affiliate.affiliateNameDescription') }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="parentAffiliateId" class="form-label">
                                        {{ $t('affiliate.parentAffiliateName') }}
                                    </label>
                                    <input v-model="parentAffiliateId" type="text" class="form-control"
                                        id="parentAffiliateId" :disabled="Cookies.get('afl')"
                                        @input="handleParentAffiliateId">
                                </div>
                                <Checkbox v-model="recaptchaResponse" class="mb-3" />

                                <button v-if="loading" type="button" class="btn btn-primary" disabled>
                                    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    <span role="status" class="ms-2">{{ $t('general.loading') }}</span>
                                </button>
                                <button v-else-if="account.isConnected" type="submit" class="btn btn-primary"
                                    :disabled="!recaptchaResponse || affiliateId.trim() === ''">
                                    {{ $t('general.submit') }}
                                </button>
                                <button v-else type="button" class="btn btn-primary" @click="openModal">
                                    {{ $t('general.connect') }}
                                </button>
                            </form>
                        </div>

                        <div v-else>
                            <div class="mb-4">
                                <label for="affiliateLink" class="form-label">
                                    {{ $t('affiliate.affiliateLink') }}
                                </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" aria-describedby="affiliateLinkDescription"
                                        :value="affiliateBaseUrl + '?afl=' + user.user_affiliate_id" disabled>
                                    <button class="btn btn-primary" type="button" id="copyAffiliateLink">
                                        <img src="@/assets/icons/copy.svg" width="16" height="16" alt="Copy"
                                            class="cursor-pointer"
                                            @click="copyToClipboard(affiliateBaseUrl + '?afl=' + user.user_affiliate_id)" />
                                    </button>
                                </div>
                                <div id="affiliateLinkDescription" class="form-text">
                                    {{ $t('affiliate.affiliateLinkDescription') }}
                                </div>
                            </div>

                            <div class="mb-4 text-center">
                                <h5>{{ $t('affiliate.balance') }}</h5>

                                <div class="text-start">
                                    <div class="font-15">
                                        <span>{{ $t('affiliate.available') }}: </span>
                                        <span>{{ user.user_balance.amount_available }}</span>
                                    </div>

                                    <div class="font-15">
                                        <span>{{ $t('affiliate.withdrawn') }}: </span>
                                        <span>{{ user.user_balance.amount_withdrawn }}</span>
                                    </div>

                                    <div class="font-15">
                                        <span>{{ $t('affiliate.total') }}: </span>
                                        <span>{{ user.user_balance.amount_total }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3 text-center">
                                <h5>{{ $t('affiliate.transactions') }}</h5>

                                <p v-if="user.user_transactions.length === 0">{{ $t('affiliate.noTransactions') }}</p>

                                <div v-else>
                                    <div v-for="transaction in paginatedData" :key="transaction.transaction_id"
                                        class="d-flex align-items-center justify-content-between mb-1 py-2 border-bottom">
                                        <div class="me-2">
                                            <img v-if="transaction.transaction_type === 'CREDIT'"
                                                src="@/assets/icons/plus.svg" alt="Credit icon" height="24px"
                                                width="24px">
                                            <img v-else-if="transaction.transaction_type === 'DEBIT'"
                                                src="@/assets/icons/minus.svg" alt="Debit icon" height="24px"
                                                width="24px">
                                        </div>
                                        <div class="text-start px-2 row">
                                            <span class="font-13 text-break">
                                                Tx:&nbsp;
                                                <a :href="transaction.transaction_url" target="_blank">
                                                    {{ transaction.transaction_hash }}
                                                </a>
                                            </span>
                                            <span class="font-13">
                                                {{ $t('affiliate.commission') }}: {{
                                                    transaction.transaction_commission }}%
                                            </span>
                                            <span class="font-13">
                                                {{ $t('affiliate.network') }}: {{
                                                    transaction.transaction_network }}
                                            </span>
                                            <span class="font-13">
                                                {{ $t('affiliate.totalTx') }}: {{
                                                    formatCurrencyUSD(transaction.transaction_amount_usd, 2, 2) }}
                                            </span>
                                            <span class="font-13">
                                                {{ $t('affiliate.date') }}: {{
                                                    timestampToDate(transaction.transaction_date_create) }}
                                            </span>
                                        </div>
                                        <span class="font-15">
                                            {{ formatCurrencyUSD(transaction.transaction_total_sum_usd, 2, 2) }}
                                        </span>
                                    </div>

                                    <nav aria-label="Page navigation" class="mt-3">
                                        <ul class="pagination justify-content-end">
                                            <!-- <li v-if="totalPages > pages.length" class="page-item" :class="page === 1 ? 'disabled' : ''"><button
                                                    class="page-link" @click="goToPage(1)">First</button>
                                            </li> -->
                                            <li class="page-item" :class="page === 1 ? 'disabled' : ''">
                                                <button class="page-link" @click="backPage">
                                                    {{ $t('general.previous') }}
                                                </button>
                                            </li>
                                            <li v-for="item in pages" :key="item" class="page-item"
                                                :class="item === page ? 'active' : ''">
                                                <button class="page-link " @click="goToPage(item)">
                                                    {{ item }}
                                                </button>
                                            </li>
                                            <li class="page-item" :class="page === totalPages ? 'disabled' : ''">
                                                <button class="page-link" @click="nextPage">
                                                    {{ $t('general.next') }}
                                                </button>
                                            </li>
                                            <!-- <li v-if="totalPages > pages.length" class="page-item" :class="page === totalPages ? 'disabled' : ''">
                                                <button class="page-link" @click="goToPage(totalPages)">Last</button>
                                            </li> -->
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="toast-container position-fixed bottom-0 end-0 p-3">
            <div class="toast align-items-center text-bg-primary border-0" id="successToast" role="alert"
                aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">
                        {{ $t('affiliate.affiliateCreated') }}
                    </div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                        aria-label="Close"></button>
                </div>
            </div>
        </div>
    </main>
</template>


<style scoped>
.payments-background-left {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 100%;
    height: 100%;
    background: radial-gradient(40% 50% at 0% 5%, #25DA4A 0%, rgba(255, 255, 255, 0) 100%);
    transform: translateY(-50%);
    z-index: -1;
    pointer-events: none;
}

.payments-overlay {
    position: absolute;
    top: 0;
    right: 0%;
    width: 30%;
    margin-left: auto;
    height: 100%;
    background: url('/images/footer-overlay.webp') center/cover no-repeat;
    opacity: 0.1;
    /* Overlay opacity */
    z-index: -1;
}

.star {
    position: absolute;
    z-index: 0;
}
</style>