<script setup>
const testnet = import.meta.env.VITE_TESTNET
</script>

<template>
  <div>
    <div v-if="testnet === 'true'"
      class="px-3 mt-2 w-100 d-flex flex-column align-items-center justify-content-center text-center">
      <p class="border rounded border-danger px-2 text-danger mb-0 font-13 fw-regular"
        v-tooltip="$t('general.testnetDescription')">
        <span>&#9642; {{ $t('general.testnet') }}</span>
      </p>
    </div>

    <div class="px-3 mt-2 w-100 d-flex flex-column align-items-center justify-content-center text-center">
      <p class="mb-0 font-13 fw-regular">
        <a href="https://mintweb3.io" target="_blank"
          class="d-flex align-items-center justify-content-center gap-1 text-decoration-none text-secondary">
          <span>{{ $t('general.developedBy') }}</span>
          <img src="@/assets/logo.svg" alt="Developer logo" width="110" height="16"
            class="text-tertiary ml-2 cursor-pointer" />
        </a>
      </p>
    </div>
  </div>
</template>
